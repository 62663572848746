import { Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const ModulesBaseStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto' as const,
    boxShadow: theme.shadows[5],
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen * 4,
    }),
  },
  dataTablePaper: {
    paddingTop: 15,
  },
  icon: {
    marginRight: theme.spacing() * 2,
  },
  iconSpacer: {
    marginLeft: theme.spacing() * 2,
    marginRight: theme.spacing() * 2,
  },
  link: {
    color: theme.palette.text.primary,
  },
  verticalAlignMiddle: {
    verticalAlign: 'middle',
  },
  expansion: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  expansionExpanded: {
    minHeight: theme.spacing() * 7,
  },
  boldFont: {
    fontWeight: 700,
    fontSize: 14,
  },
  formControl: {
    width: 320,
  },
  menuItemUpdated: {
    width: theme.spacing() * 2,
    textAlign: 'center',
  },
  dialogTitleSpan: {
    fontWeight: 100,
    fontStyle: 'italic',
  },
});
