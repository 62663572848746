import { EslManagerPrivateRoute, HttpMethod, PresetSavePayload, Template } from '@ekkogmbh/apisdk';
import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React from 'react';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { FormPanelButtons } from '../../Common/Components/FormPanelButtons';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { PresetStore } from '../Stores/PresetStore';
import { PresetForm } from './PresetForm';
import { PresetPanelSorting } from './PresetPanelSorting';

const stores = ['api', 'presetStore'];

interface PresetPanelStores {
  api: ApiStore;
  presetStore: PresetStore;
}

export interface PresetPanelState {
  finished: boolean;
  loading: boolean;
  templates: Template[];
}

export interface PresetPanelProps extends InjectedNotistackProps {
  closeHandler: () => void;
  saveHandler: (preset: PresetSavePayload) => Promise<void>;
  closeCallback?: () => void;
}

@inject(...stores)
@observer
class PresetPanelComponent extends React.Component<PresetPanelProps, PresetPanelState> {
  public state: PresetPanelState = { finished: false, loading: false, templates: [] };
  private closeTimeout?: number;
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): PresetPanelStores {
    return this.props as PresetPanelProps & PresetPanelStores;
  }

  public async componentDidMount(): Promise<void> {
    const { presetStore } = this.stores;

    presetStore.resetStore(presetStore.editablePreset);

    this.setState(
      {
        loading: true,
        templates: await this.fetchAvailableTemplates(),
      },
      async () => {
        this.setState({ loading: false });
      },
    );
  }

  public componentWillUnmount(): void {
    this.stores.presetStore.resetStore();
  }

  public fetchAvailableTemplates = async (): Promise<Template[]> => {
    const { api } = this.stores;
    const { enqueueSnackbar } = this.props;

    try {
      return await request<Template[]>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.getAllTemplates(),
        EslManagerPrivateRoute.TEMPLATES,
        HttpMethod.GET,
      );
    } catch (e) {
      enqueueSnackbar('Could not fetch Templates.', { variant: 'error' });
    }

    return undefined as never;
  };

  public handleReset = async () => {
    const { presetStore } = this.stores;
    presetStore.resetStore(presetStore.editablePreset);
    this.setState({ finished: false });
  };

  public handleSave = async () => {
    const { saveHandler } = this.props;
    const { presetStore } = this.stores;
    const { name, coordinate, keys, allFilled } = presetStore.state;

    if (!allFilled) {
      return;
    }

    saveHandler({ name, coordinate, keys } as PresetSavePayload);
    this.closeTimeout = window.setTimeout(this.closeMountAware, 1250);
  };

  public closeMountAware = () => {
    const { closeHandler, closeCallback } = this.props;

    const close =
      closeCallback !== undefined
        ? () => {
            closeHandler();
            closeCallback();
          }
        : closeHandler;

    window.clearTimeout(this.closeTimeout);
    close();
  };

  public render() {
    const { presetStore } = this.stores;
    const { finished, templates } = this.state;
    const { changed } = presetStore;
    const { allFilled } = presetStore.state;

    const close = this.closeMountAware;

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={6}>
          <PresetForm templates={templates} />
        </Grid>

        <Grid item xs={6}>
          <PresetPanelSorting loading={false} sortableItems={presetStore.state.keys} />
        </Grid>

        <FormPanelButtons
          cancelHandler={close}
          finished={finished}
          saveHandler={this.handleSave}
          resetHandler={this.handleReset}
          isDeleteDisabled={true}
          isDeleteHidden={true}
          isSaveDisabled={!changed || !allFilled}
          isResetDisabled={!changed}
        />
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<PresetPanelProps>(PresetPanelComponent);

export const PresetPanel = SnackbarWrapped;
