import { TableRow, Theme, WithStyles, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import TableFooter from '@material-ui/core/TableFooter';
import { MaterialDatatableOptions } from 'material-datatable';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { PaginationStore } from '../Stores/PaginationStore';

const styles = (theme: Theme) => ({
  row: {
    height: theme.spacing() * 7,
  },
});

const stores = ['paginationStore'];

interface DataTableFooterStores {
  paginationStore: PaginationStore;
}

interface DataTableFooterState {}

interface DataTableFooterProps extends WithStyles<typeof styles> {
  classes: ClassNameMap;
  page: number;
  rowCount: number;
  rowsPerPage: number;
  changeRowsPerPage: (value: number) => void;
  changePage: (page: number) => void;
  options: MaterialDatatableOptions;
  tableRef: React.RefObject<HTMLTableElement>;
  height?: number | string;
}

@inject(...stores)
@observer
class DataTableFooterComponent extends React.Component<DataTableFooterProps, DataTableFooterState> {
  get stores(): DataTableFooterStores {
    return this.props as DataTableFooterStores & DataTableFooterProps;
  }

  public componentDidMount(): void {
    const { page, rowCount, rowsPerPage, changeRowsPerPage, changePage, options } = this.props;

    const { paginationStore } = this.stores;

    paginationStore.setPaginationControls({
      page,
      rowCount,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      options,
    });
  }

  // @TODO snapshot?
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public componentDidUpdate(_: Readonly<DataTableFooterProps>, __: Readonly<DataTableFooterState>, ___?: any): void {
    const { page, rowCount, rowsPerPage, changeRowsPerPage, changePage, options } = this.props;

    const { paginationStore } = this.stores;

    paginationStore.setPaginationControls({
      page,
      rowCount,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      options,
    });
  }

  public render() {
    const { classes, height } = this.props;
    return (
      <TableFooter>
        <TableRow className={classes.row} style={height !== undefined ? { height } : {}}>
          <td>{/**/}</td>
        </TableRow>
      </TableFooter>
    );
  }
}

export const DataTableFooter = withStyles(styles)(DataTableFooterComponent);
