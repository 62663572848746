import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { TitleProps } from '../Routes';
import { TemplateManagementContent } from './Components/TemplateManagementContent';
import { TemplateStore } from './Stores/TemplateStore';

const templateStore = new TemplateStore();

const stores = {
  templateStore,
};

export const TemplateManagement = ({ title }: TitleProps): JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <TemplateManagementContent />
    </Page>
  </Provider>
);
