import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { TitleProps } from '../Routes';
import { PresetManagementContent } from './Components/PresetManagementContent';
import { PresetStore } from './Stores/PresetStore';

const presetStore = new PresetStore();

const stores = {
  presetStore,
};

export const PresetManagement = ({ title }: TitleProps): JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <PresetManagementContent />
    </Page>
  </Provider>
);
