import { KeyboardEvent } from 'react';

export const onKeyPressCallback = (callback: () => void, condition: boolean, key: string) => async ({
  key: pressedKey,
}: KeyboardEvent<HTMLElement>): Promise<void> => {
  if (key === pressedKey && condition) {
    callback();
  }
};

export const isArrayValue = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  value?: Array<string | number | boolean | object> | string | number | boolean | object,
): value is string[] => (value as string[]).length !== undefined;
