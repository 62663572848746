import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

interface LoadingProps {
  width?: number;
  height?: number;
  topPercent?: number;
  leftPercent?: number;
}

class LoadingComponent extends React.Component<LoadingProps> {
  public render(): JSX.Element {
    let { width, height, topPercent, leftPercent } = this.props;

    if (!width) {
      width = 150;
    }

    if (!height) {
      height = 150;
    }

    if (!topPercent) {
      topPercent = 50;
    }

    if (!leftPercent) {
      leftPercent = 50;
    }

    return (
      <CircularProgress
        style={{
          position: 'absolute',
          width,
          height,
          marginTop: (height / 2) * -1,
          marginLeft: (width / 2) * -1,
          top: topPercent + '%',
          left: leftPercent + '%',
        }}
      />
    );
  }
}

export const Loading = LoadingComponent;
