import { Theme, withStyles, WithStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

const styles = (_: Theme) => ({});

interface NotificationRowProps extends WithStyles<typeof styles> {
  removeHandler: (notification: string) => void;
  notification: string;
}

class NotificationRowComponent extends React.Component<NotificationRowProps> {
  public onClick = () => {
    const { notification, removeHandler } = this.props;
    removeHandler(notification);
  };

  public render() {
    const { notification } = this.props;
    return <MenuItem onClick={this.onClick}>{notification}</MenuItem>;
  }
}

export const NotificationRow = withStyles(styles)(NotificationRowComponent);
