import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { AreaManagementContent } from './Components/AreaManagementContent';

export const AreaManagement = (): JSX.Element => (
  <Provider>
    <Page title="Areas" isSearchable={false}>
      <AreaManagementContent />
    </Page>
  </Provider>
);
