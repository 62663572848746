import { Role } from '@ekkogmbh/apisdk';
import { Delete, Edit, Group } from '@material-ui/icons';
import { MaterialDatatableColumnDef } from 'material-datatable';
import React, { Fragment } from 'react';
import { DatatableColumnDefinitionFn, TableMeta } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { customDivNullRender } from '../../Common/Components/ReactHelpers';
import { Permissions } from '../../Common/Stores/ApiStore';
import {
  RoleManagementContentActionHandlers,
  RoleManagementContentHelpers,
  RoleManagementContentPropsWithStores,
  RoleManagementContentState,
} from './RoleManagementContent';
import { RolePermissionSelect } from './RolePermissionSelect';

class RoleDataTableActions extends DataTableActionsComponent<Role> {}

const RoleActions = (
  _: RoleManagementContentState,
  propsWithStores: RoleManagementContentPropsWithStores,
  actions: RoleManagementContentActionHandlers,
): MaterialDatatableColumnDef => ({
  name: '',
  field: '',
  options: {
    width: 48 * 3,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (role: Role, tableMeta: TableMeta): React.ReactNode => {
      const { api } = propsWithStores;

      const hasWritePermission = api.userHasPermissionOnAnyNode(Permissions.ROLES_WRITE);
      const hasUsersReadPermission = api.userHasPermissionOnAnyNode(Permissions.USERS_READ);

      const actionButtons: ActionButton<Role>[] = [
        {
          title: 'Users',
          onClick: actions.users,
          icon: Group,
          disabled: !hasUsersReadPermission,
        },
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
          disabled: !hasWritePermission,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return (
        <RoleDataTableActions
          dataset={role}
          width={tableMeta.columnData.width}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

const RoleBodyRenderName = (
  propsWithStores: RoleManagementContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (value: Role, tableMeta: TableMeta) => {
  if (!value[tableMeta.columnData.field]) {
    return;
  }

  const { classes } = propsWithStores;
  const actualValue = value[tableMeta.columnData.field];

  return (
    <Fragment>
      <span className={classes.boldFont}>{actualValue}</span>
    </Fragment>
  );
};

const RoleBodyRenderPermissions = (
  state: RoleManagementContentState,
  propsWithStores: RoleManagementContentPropsWithStores,
  _: RoleManagementContentActionHandlers,
  helpers: RoleManagementContentHelpers,
  // eslint-disable-next-line react/display-name
) => (role: Role) => {
  if (!role) {
    return;
  }

  const { api, enqueueSnackbar, searchContentStore } = propsWithStores;
  const { allPermissions } = state;

  const hasWritePermission = api.userHasPermissionOnAnyNode(Permissions.ROLES_WRITE);

  const handleNoPermission = () => {
    enqueueSnackbar('Missing write permission.', { variant: 'error' });
    searchContentStore.emitRefresh();
  };

  return (
    <RolePermissionSelect
      role={role}
      allPermissions={allPermissions}
      saveHandler={hasWritePermission ? helpers.updateRolePermissions : handleNoPermission}
      noDialog={!hasWritePermission}
    />
  );
};

const RoleName = (
  _: RoleManagementContentState,
  propsWithStores: RoleManagementContentPropsWithStores,
): MaterialDatatableColumnDef => ({
  name: 'Role',
  field: 'name',
  options: {
    width: 240,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: RoleBodyRenderName(propsWithStores),
  },
});

const RolePermissions = (
  state: RoleManagementContentState,
  propsWithStores: RoleManagementContentPropsWithStores,
  actions: RoleManagementContentActionHandlers,
  helpers?: RoleManagementContentHelpers,
): MaterialDatatableColumnDef => ({
  name: 'Permissions',
  field: 'permissions',
  options: {
    width: 500,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: RoleBodyRenderPermissions(state, propsWithStores, actions, helpers!),
  },
});

const RoleTableSpacer = (): MaterialDatatableColumnDef => ({
  name: '',
  field: '',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: customDivNullRender,
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  RoleManagementContentPropsWithStores,
  RoleManagementContentState,
  RoleManagementContentActionHandlers,
  RoleManagementContentHelpers
>> = [RoleName, RolePermissions, RoleTableSpacer, RoleActions];

export { RoleName, RolePermissions, RoleTableSpacer };
