import { Box, Card, CardContent, Chip, Grid, withStyles, WithStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import { InsertDriveFile } from '@material-ui/icons';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { CSVImportStepperStyles } from '../../Common/Styles/CSVImportStepperStyles';
import { TemplateStore } from '../Stores/TemplateStore';

interface TemplateFileStepProps extends WithStyles<typeof CSVImportStepperStyles> {
  templateStore?: TemplateStore;
}

@inject('templateStore')
@observer
class TemplateFileStepComponent extends React.Component<TemplateFileStepProps> {
  public render() {
    const { classes, templateStore } = this.props;
    const { templateFile, loading } = templateStore!;

    return (
      <Card elevation={3}>
        <CardContent>
          {loading && <LoadingMask />}
          <Grid container xs={12} spacing={2} direction="row" alignItems="center" justifyContent="center">
            <Box mr=".4rem">
              <InsertDriveFile className={classes.fileIcon} />
            </Box>

            <Box mr=".8rem">
              <Typography variant={'caption'} style={{ fontSize: '1.2rem' }}>
                {templateFile!.name}
              </Typography>
            </Box>

            <Chip label={Number(templateFile!.size / 1024).toFixed(2) + 'kB'} />
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export const TemplateFileStep = withStyles(CSVImportStepperStyles)(TemplateFileStepComponent);
