import { Pagination } from '@ekkogmbh/apisdk';
import { EventEmitter } from 'eventemitter3';
import { MaterialDatatableOptions } from 'material-datatable';
import { action, observable } from 'mobx';

export interface PaginationControls {
  page: number | null;
  rowCount: number;
  rowsPerPage: number;
  changeRowsPerPage?: (value: number) => void;
  changePage?: (page: number) => void;
  options?: MaterialDatatableOptions;
}

export class PaginationStore extends EventEmitter {
  @observable
  public pagination: Pagination = {
    page: 0,
  };

  private paginationControls: PaginationControls = {
    page: null,
    rowCount: 0,
    rowsPerPage: 0,
  };

  @action
  public setPagination(pagination: Pagination): void {
    this.pagination = pagination;
  }

  public getPaginationControls(): PaginationControls {
    return this.paginationControls;
  }

  public setPaginationControls(paginationControls: PaginationControls): void {
    this.paginationControls = paginationControls;
    this.emit('pagination-controls');
  }

  public pageReceived(): void {
    this.emit('page-received');
  }
}
