import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';

export interface LoadingWrapperProps {
  loading: number;
  children?: unknown;
}

class LoadingWrapperComponent extends Component<LoadingWrapperProps> {
  public render(): JSX.Element {
    const { loading, children } = this.props;

    return (
      <div {...this.props}>
        {loading === 1 && (
          <LinearProgress
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              height: '100%',
            }}
          />
        )}
        {children}
      </div>
    );
  }
}

export const LoadingWrapper = LoadingWrapperComponent;
