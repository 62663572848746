import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { TitleProps } from '../Routes';
import { PickingSidesContent } from './Components/PickingSidesContent';
import { PickingSideStore } from './Stores/PickingSideStore';

const pickingSideStore = new PickingSideStore();

const stores = {
  pickingSideStore,
};

export const PickingSides = ({ title }: TitleProps): JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <PickingSidesContent />
    </Page>
  </Provider>
);
