import { Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const CSVDropzoneStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  dropZone: {
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    border: 'dashed 0.3rem',
    borderColor: theme.palette.text.hint,
    cursor: 'pointer',
    boxSizing: 'border-box',
    outline: 'none',
    overflow: 'hidden',
    transition: theme.transitions.create(['border', 'background-color', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
  },
  uploadIcon: {
    fontSize: '8em',
    color: theme.palette.text.hint,
    display: 'block',
    margin: '0 auto',
    padding: theme.spacing() * 2,
  },
  text: {
    textAlign: 'center',
    fontSize: '1.2em',
    marginBottom: theme.spacing() * 2,
    fontWeight: 100,
  },
});
