import { withStyles, WithStyles } from '@material-ui/core';
import * as classNames from 'classnames';
import React, { ClipboardEvent, Component } from 'react';
import Select, { CommonProps } from 'react-select';
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select/src/types';
import { NodeSeparator } from '../Helper/Nodes';
import { ReactSelectComponents } from './ReactSelect/Components';
import { ReactSelectStyles } from './ReactSelect/Styles';

const styles = ReactSelectStyles;

export interface ReactSelectMultiValue {
  label: string;
  value: number | string;
  fullValue?: number | string | number[] | string[];
  parentValue?: number | string | null;
}

interface ReactSelectMultiProps extends WithStyles<typeof styles> {
  loading: boolean;
  handleChange: (value: ValueType<OptionTypeBase>, actionMeta: ActionMeta<OptionTypeBase>) => void;
  value: ReactSelectMultiValue[];
  placeholder: string;
  suggestions: ReactSelectMultiValue[];
  label?: string;
  onPaste?: (event: ClipboardEvent<HTMLSelectElement>) => void;
  fullValueLabel?: string;
  isDisabled?: boolean;
}

class ReactSelectMultiComponent extends Component<ReactSelectMultiProps> {
  // @TODO cant find correct interface
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public valueContainer = (props: CommonProps<OptionTypeBase> & Readonly<{ children?: any }>) => {
    const { classes, fullValueLabel, value } = this.props;
    const children = [...props.children];

    let fullValueText = '';

    if (children[0] && children[0].length !== undefined) {
      children[0] = [...children[0]]
        .map((e, i) => (i < children[0].length - 1 ? [e, ' / '] : [e]))
        .reduce((a, b) => a.concat(b));

      if (value.length !== undefined && value.length > 0) {
        const rsv = value[value.length - 1];
        const fullValue: number[] | string[] = rsv.fullValue as number[] | string[];

        if (fullValue !== undefined && fullValue.join !== undefined) {
          fullValueText = fullValue.join(' ' + NodeSeparator + ' ');
        }
      }
    }

    return (
      <div className={props.selectProps.classes.valueContainer}>
        {fullValueLabel !== undefined && children[0] && children[0].length !== undefined && (
          <div
            className={classNames(
              classes.valueContainerFullValue,
              children[0].length > 0 && fullValueText !== '' && classes.valueContainerFullValueExpanded,
            )}
          >
            {fullValueLabel}: {fullValueText}
          </div>
        )}

        {children}
      </div>
    );
  };

  public render() {
    const { classes, label, handleChange, value, placeholder, suggestions, loading, onPaste, isDisabled } = this.props;

    // https://react-select.com/components
    const components = {
      Control: ReactSelectComponents.Control,
      LoadingMessage: ReactSelectComponents.LoadingMessage,
      Menu: ReactSelectComponents.Menu,
      MenuList: ReactSelectComponents.MenuList,
      MultiValue: ReactSelectComponents.MultiValue,
      NoOptionsMessage: ReactSelectComponents.NoOptionsMessage,
      Option: ReactSelectComponents.Option,
      Placeholder: ReactSelectComponents.Placeholder,
      SingleValue: ReactSelectComponents.SingleValue,
      ValueContainer: this.valueContainer,
    };

    return (
      <Select
        classes={classes}
        isLoading={loading}
        textFieldProps={{
          label,
          InputLabelProps: {
            shrink: true,
          },
          onPaste,
        }}
        options={suggestions}
        components={components}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isMulti
      />
    );
  }
}

const StyleWrapped = withStyles(styles)(ReactSelectMultiComponent);

export const ReactSelectMulti = StyleWrapped;
