import { LinkView } from '@ekkogmbh/apisdk';
import { Chip, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import React, { Component, CSSProperties } from 'react';
import Highlighter from 'react-highlight-words';
import ChipWrapper from './ChipWrapper';
import { LoadingWrapper } from './LoadingWrapper';

const styles = (_: Theme) => ({});

interface LinkChipState {}

interface LinkChipProps extends WithStyles<typeof styles> {
  link: LinkView;
  isProcessing: boolean;
  dataIndex: number;
  clickHandler: (event: React.MouseEvent<HTMLElement>) => void;
  deleteHandlerWrapper: (link: LinkView) => () => Promise<void>;
  highlighterOptions: {
    searchText: string;
    highlightStyle: CSSProperties;
    unhighlightStyle: CSSProperties;
  };
}

class LinkChipComponent extends Component<LinkChipProps, LinkChipState> {
  // @TODO snapshot?
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public shouldComponentUpdate(nextProps: Readonly<LinkChipProps>, _: Readonly<LinkChipState>, __: any): boolean {
    const { link, isProcessing } = this.props;
    return (
      JSON.stringify(link) !== JSON.stringify(nextProps.link) ||
      nextProps.isProcessing ||
      (isProcessing && !nextProps.isProcessing)
    );
  }

  public renderLabel = () => {
    const { link, highlighterOptions } = this.props;

    return (
      <span style={{ position: 'relative' }}>
        <Typography
          variant={'overline'}
          align={'center'}
          color={'textPrimary'}
          display={'block'}
          style={{
            lineHeight: 'normal',
            fontWeight: 700,
            userSelect: 'text',
          }}
        >
          <Highlighter
            searchWords={[highlighterOptions.searchText.replace(/\\/g, '\\\\')]}
            textToHighlight={link.labelId}
            highlightStyle={highlighterOptions.highlightStyle}
            unhighlightStyle={highlighterOptions.unhighlightStyle}
          />
        </Typography>

        <Typography
          variant={'overline'}
          color={'textSecondary'}
          display={'block'}
          style={{
            lineHeight: 'normal',
            fontSize: 9,
            userSelect: 'text',
            textAlign: 'center',
          }}
        >
          <Highlighter
            searchWords={[highlighterOptions.searchText.replace(/\\/g, '\\\\')]}
            textToHighlight={link.template.name}
            highlightStyle={highlighterOptions.highlightStyle}
            unhighlightStyle={highlighterOptions.unhighlightStyle}
          />
        </Typography>
        <Typography
          variant={'overline'}
          color={'textSecondary'}
          display={'block'}
          style={{
            lineHeight: 'normal',
            fontSize: 9,
            userSelect: 'text',
            textAlign: 'center',
          }}
        >
          Page: {link.pageNumber}
        </Typography>
      </span>
    );
  };

  public render() {
    const { link, isProcessing, dataIndex, clickHandler, deleteHandlerWrapper } = this.props;

    const onClick = !isProcessing ? clickHandler : undefined;
    const onDelete = !isProcessing ? deleteHandlerWrapper(link) : () => undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chipWrapper = ChipWrapper(LoadingWrapper, isProcessing ? 1 : 0) as any;

    return (
      <Chip
        component={chipWrapper}
        clickable={!isProcessing}
        data-index={dataIndex}
        variant={'outlined'}
        onClick={onClick as never}
        onDelete={onDelete as never}
        deleteIcon={
          <Cancel
            style={{
              zIndex: 1,
              cursor: isProcessing ? 'not-allowed' : 'pointer',
            }}
          />
        }
        label={this.renderLabel()}
        style={{
          position: 'relative',
          margin: 8,
          height: 48,
          overflow: 'hidden',
          cursor: isProcessing ? 'not-allowed' : 'pointer',
        }}
      />
    );
  }
}

export const LinkChip = withStyles(styles)(LinkChipComponent);
