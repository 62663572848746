import { IconButton, InputAdornment, WithStyles, withStyles } from '@material-ui/core';
import Slide from '@material-ui/core/Slide/Slide';
import TextField from '@material-ui/core/TextField/TextField';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { Close, Search } from '@material-ui/icons';
import * as classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { ChangeEvent, GetDerivedStateFromProps, KeyboardEvent } from 'react';
import { SearchBarStore } from '../Stores/SearchBarStore';
import { SearchContentStore } from '../Stores/SearchContentStore';
import { NavigationStyles } from '../Styles/NavigationStyles';

const stores = ['searchBarStore', 'searchContentStore'];

interface NavigationSearchBarStores {
  searchBarStore: SearchBarStore;
  searchContentStore: SearchContentStore;
}

interface NavigationSearchBarProps extends WithStyles<typeof NavigationStyles> {
  sticky: boolean;
}

interface NavigationSearchBarState {
  changed: boolean;
  open: boolean;
  searchText: string;
  sticky: boolean;
}

@inject(...stores)
@observer
class NavigationSearchBarComponent extends React.Component<NavigationSearchBarProps, NavigationSearchBarState> {
  public state: NavigationSearchBarState;

  constructor(props: NavigationSearchBarProps) {
    super(props);

    const { sticky } = props;

    this.state = {
      changed: true,
      open: !sticky,
      searchText: '',
      sticky,
    };
  }

  get stores(): NavigationSearchBarStores {
    return this.props as NavigationSearchBarProps & NavigationSearchBarStores;
  }

  // using 'any' because of bad material-ui interface in mui < v4
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getDerivedStateFromProps: GetDerivedStateFromProps<any, NavigationSearchBarState> = (
    nextProps: Readonly<NavigationSearchBarProps>,
    prevState: NavigationSearchBarState,
  ): Partial<NavigationSearchBarState> | null => {
    if (nextProps.sticky !== prevState.sticky) {
      const newState: Partial<NavigationSearchBarState> = {
        sticky: nextProps.sticky,
      };

      newState.open = nextProps.sticky ? prevState.searchText !== '' : true;

      return newState;
    }

    return null;
  };

  public componentDidMount = (): void => {
    const { searchContentStore } = this.stores;

    searchContentStore.on('searchText', this.setSearchState);
  };

  public componentWillUnmount = (): void => {
    const { searchContentStore } = this.stores;

    searchContentStore.off('searchText', this.setSearchState);
  };

  public setSearchState = (): void => {
    const { searchContentStore } = this.stores;

    this.setState({
      changed: searchContentStore.searchText === '',
      searchText: searchContentStore.searchText,
    });
  };

  public clickSearch = () => {
    const { open, searchText } = this.state;
    const { searchContentStore } = this.stores;

    if (open && searchText !== '') {
      searchContentStore.setSearchText(searchText);
      this.setState({ changed: false });
    } else if (open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
    }
  };

  public clickClear = () => {
    const { searchContentStore } = this.stores;
    const { sticky } = this.props;

    searchContentStore.setSearchText('');

    this.setState({
      open: !sticky,
      searchText: '',
      changed: true,
    });
  };

  public onChange = ({ target: { value: searchText } }: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchText,
      changed: true,
    });
  };

  public onBlur = ({ target: { value: searchText } }: ChangeEvent<HTMLInputElement>) => {
    const { searchContentStore } = this.stores;
    const { sticky } = this.props;

    if (searchText === '' && searchContentStore.searchText !== searchText) {
      searchContentStore.setSearchText('');
      this.setState({
        open: !sticky,
        searchText: '',
        changed: true,
      });
    }
  };

  public handleKeyPress = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      this.clickSearch();
    }
  };

  public handleKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Escape') {
      this.clickClear();
    }
  };

  public render() {
    const { searchBarStore, searchContentStore } = this.stores;
    const { classes, sticky } = this.props;
    const { changed, open, searchText } = this.state;
    const isSearchable = searchBarStore.isSearchableContent;
    const wasRendered = searchBarStore.isRendered;

    if (isSearchable) {
      searchBarStore.setRendered();
    }

    const iconButtonClassNames = classNames(
      sticky && !open && classes.searchBarAdornmentButton,
      sticky && !open && classes.appBarButton,
      sticky && !open && classes.appBarButtonSticky,
    );

    const searchBarClassNames = classNames(
      classes.searchBar,
      !sticky && classes.searchBarMargin,
      !isSearchable && classes.searchBarDisabled,
      sticky && !open && classes.searchBarSticky,
      sticky && open && classes.appBarButtonSticky,
    );

    const iconClassNames = classNames(classes.searchBarIcon, !isSearchable && classes.searchBarIconDisabled);

    const Icon = changed ? Search : Close;

    return (
      <Slide
        direction="down"
        in={isSearchable}
        mountOnEnter
        unmountOnExit
        {...(!wasRendered ? { timeout: 500 } : { timeout: 0 })}
      >
        <TextField
          id="filled-adornment-searchbar"
          style={!isSearchable ? { display: 'none' } : undefined}
          disabled={searchContentStore.searchBarDisabled}
          className={searchBarClassNames}
          variant="filled"
          type={'text'}
          label="Search"
          InputLabelProps={{
            classes: { root: sticky && !open ? classes.searchBarStickyLabel : classes.searchBarLabel },
          }}
          onChange={this.onChange}
          onKeyPress={this.handleKeyPress}
          onKeyDown={this.handleKeyDown}
          // after update the onBlur type is not clearly defined
          onBlur={this.onBlur as never}
          value={searchText}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                className={classes.searchBarAdornment}
                style={{ paddingRight: sticky ? 36 : 0 }}
              >
                <Tooltip title={changed ? 'Search' : 'Clear'} placement="bottom" enterDelay={500} leaveDelay={200}>
                  <IconButton
                    color="primary"
                    className={iconButtonClassNames}
                    onClick={changed ? this.clickSearch : this.clickClear}
                  >
                    <Icon className={iconClassNames} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            classes: {
              root: sticky && !open ? classes.searchBarStickyInputRoot : classes.searchBarInputRoot,
              input: classes.searchBarInput,
              underline: classes.searchBarInputUnderline,
            },
          }}
        />
      </Slide>
    );
  }
}

export const NavigationSearchBar = withStyles(NavigationStyles)(NavigationSearchBarComponent);
