import { UserRoleNodeMapping } from '@ekkogmbh/apisdk';
import { IconButton, Input, InputAdornment } from '@material-ui/core';
import { orange, red } from '@material-ui/core/colors';
import { Delete, Update } from '@material-ui/icons';
import { MaterialDatatableColumnDef } from 'material-datatable';
import moment from 'moment';
import React, { Fragment } from 'react';
import { DatatableColumnDefinitionFn, TableMeta } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { NodeSeparator } from '../../Common/Helper/Nodes';
import { Permissions } from '../../Common/Stores/ApiStore';
import {
  UserMappingsContentActionHandlers,
  UserMappingsContentHelpers,
  UserMappingsContentPropsWithStores,
  UserMappingsContentState,
} from './UserMappingsContent';

class UserMappingDataTableActions extends DataTableActionsComponent<UserRoleNodeMapping> {}

const UserMappingActions = (
  _: UserMappingsContentState,
  propsWithStores: UserMappingsContentPropsWithStores,
  actions: UserMappingsContentActionHandlers,
): MaterialDatatableColumnDef => ({
  name: '',
  field: '',
  options: {
    width: 48 * 3,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (mapping: UserRoleNodeMapping, tableMeta: TableMeta): React.ReactNode => {
      const { api } = propsWithStores;

      const hasWritePermission =
        api.userHasPermissionOnAnyNode(Permissions.MAPPINGS_WRITE) ||
        api.userHasPermissionOnAnyNode(Permissions.MAPPINGS_WRITE_RESTRICTED);

      const actionButtons: ActionButton<UserRoleNodeMapping>[] = [
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return (
        <UserMappingDataTableActions
          dataset={mapping}
          width={tableMeta.columnData.width}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

const UserMappingBodyRenderRole = (
  propsWithStores: UserMappingsContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => ({ role }: UserRoleNodeMapping) => {
  const { classes } = propsWithStores;

  return (
    <Fragment>
      <span className={classes.boldFont}>{role.name}</span>
    </Fragment>
  );
};

const UserMappingRole = (
  _: UserMappingsContentState,
  propsWithStores: UserMappingsContentPropsWithStores,
): MaterialDatatableColumnDef => ({
  name: 'Role',
  field: 'role',
  options: {
    width: 280,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: UserMappingBodyRenderRole(propsWithStores),
  },
});

const UserMappingBodyRenderArea = (
  propsWithStores: UserMappingsContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => ({ node }: UserRoleNodeMapping) => {
  const { classes } = propsWithStores;

  return (
    <Fragment>
      <span className={classes.boldFont}>{node.identifier.join(' ' + NodeSeparator + ' ')}</span>
    </Fragment>
  );
};

const UserMappingArea = (
  _: UserMappingsContentState,
  propsWithStores: UserMappingsContentPropsWithStores,
): MaterialDatatableColumnDef => ({
  name: 'Area',
  field: 'node',
  options: {
    width: 320,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: UserMappingBodyRenderArea(propsWithStores),
  },
});

const UserMappingBodyRenderValidUntil = (
  helpers: UserMappingsContentHelpers,
  // eslint-disable-next-line react/display-name
) => (userRoleNodeMapping: UserRoleNodeMapping) => (
  <Input
    type={'text'}
    inputProps={{ style: { textAlign: 'center' } }}
    value={new Date(userRoleNodeMapping.validUntil).toLocaleDateString()}
    readOnly={true}
    onClick={() => helpers.onClickValidUntil(userRoleNodeMapping)}
    endAdornment={
      <InputAdornment position="end">
        <IconButton aria-label="Edit" onClick={() => helpers.onClickValidUntil(userRoleNodeMapping)}>
          <Update fontSize={'small'} />
        </IconButton>
      </InputAdornment>
    }
  />
);

const UserMappingValidUntil = (
  _: UserMappingsContentState,
  __: UserMappingsContentPropsWithStores,
  ___: UserMappingsContentActionHandlers,
  helpers?: UserMappingsContentHelpers,
): MaterialDatatableColumnDef => ({
  name: 'Valid-Until',
  field: 'validUntil',
  options: {
    width: 160,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: UserMappingBodyRenderValidUntil(helpers!),
  },
});

const UserMappingBodyRenderExpiring = () =>
  // eslint-disable-next-line react/display-name
  ({ validUntil }: UserRoleNodeMapping) => {
    const momentDate = moment(validUntil);

    const hoursDiff = momentDate.diff(moment(), 'hours');

    let style = {};

    const saturation = 'A400';

    switch (true) {
      case hoursDiff <= 0:
        style = {
          color: red[saturation],
          fontWeight: 700,
        };
        break;

      case hoursDiff < 48:
        style = {
          color: orange[saturation],
          fontWeight: 700,
        };
        break;
    }

    return (
      <Fragment>
        <span style={style}>
          {hoursDiff <= 0 && 'expired '}
          {momentDate.fromNow()}
        </span>
      </Fragment>
    );
  };

const UserMappingExpiring = (): MaterialDatatableColumnDef => ({
  name: 'Expiring',
  field: 'validUntil',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: UserMappingBodyRenderExpiring(),
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  UserMappingsContentPropsWithStores,
  UserMappingsContentState,
  UserMappingsContentActionHandlers,
  UserMappingsContentHelpers
>> = [UserMappingRole, UserMappingArea, UserMappingValidUntil, UserMappingExpiring, UserMappingActions];

export { UserMappingRole, UserMappingArea, UserMappingValidUntil, UserMappingExpiring };
