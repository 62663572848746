import { Grid, Omit, Stepper, Theme, withStyles, WithStyles } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

const styles = (_: Theme) => ({
  root: {},
});

export interface DynamicStepperStep {
  title: string;
  elementCallback: () => JSX.Element;
}

interface DynamicStepperState {}

interface DynamicStepperProps extends WithStyles<typeof styles>, RouteComponentProps, InjectedNotistackProps {
  activeStep: number;
  steps: DynamicStepperStep[];
}

@observer
class DynamicStepperComponent extends Component<DynamicStepperProps, DynamicStepperState> {
  public render() {
    const { activeStep, steps } = this.props;

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={12} style={{ minHeight: activeStep >= steps.length ? 0 : 200 }}>
          {steps[activeStep] !== undefined && steps[activeStep].elementCallback()}
        </Grid>

        <Grid item xs={12}>
          <Stepper activeStep={activeStep}>
            {steps.map(({ title }) => (
              <Step key={title}>
                <StepLabel>{title}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<DynamicStepperProps, typeof DynamicStepperComponent>(DynamicStepperComponent);
const SnackbarWrapped = withSnackbar<Omit<DynamicStepperProps, keyof RouteComponentProps>>(RouterWrapped);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const DynamicStepper = StyleWrapped;
