import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Omit,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React from 'react';
import { CheckmarkSpinner } from './CheckmarkSpinner';

const defaultTimeoutSeconds = 2;

const styles = (theme: Theme) => ({
  button: {
    margin: theme.spacing(),
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

interface ConfirmationDialogState {
  okButtonText: string;
  okButtonTimeoutSecondsLeft: number;
  okButtonTimeout: boolean;
  okButtonTimeoutDone: boolean;
  autoConfirm: boolean;
  autoConfirmDone: boolean;
  autoConfirmSecondsLeft: number;
}

interface ConfirmationDialogProps extends WithStyles<typeof styles>, Omit<DialogProps, 'classes' | 'title'> {
  title: string | JSX.Element;
  text: string | JSX.Element;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  okButtonDisabled?: boolean;
  centered?: boolean;
  timedOkButton?: boolean;
  checkmarkSpinner?: boolean;
  checkmarkSpinnerDone?: boolean;
  minHeight?: number;
  minWidth?: number;
  autoConfirm?: boolean;
  autoConfirmTime?: number;
  autoConfirmTextFn?: (autoConfirmSecondsLeft: number) => string | JSX.Element;
}

class ConfirmationDialogComponent extends React.Component<ConfirmationDialogProps, ConfirmationDialogState> {
  public state: ConfirmationDialogState = {
    okButtonText: 'OK',
    okButtonTimeoutSecondsLeft: defaultTimeoutSeconds,
    okButtonTimeout: false,
    okButtonTimeoutDone: false,
    autoConfirm: false,
    autoConfirmDone: false,
    autoConfirmSecondsLeft: 0,
  };

  private okButtonTimeout?: number;
  private autoConfirmTimeout?: number;

  public static getDerivedStateFromProps(props: ConfirmationDialogProps, state: ConfirmationDialogState) {
    let newState: Partial<ConfirmationDialogState> | null = null;

    if (props.timedOkButton && !state.okButtonTimeout && !state.okButtonTimeoutDone) {
      newState = {
        okButtonTimeout: true,
        okButtonTimeoutSecondsLeft: defaultTimeoutSeconds,
        okButtonText: String(defaultTimeoutSeconds),
      };
    }

    if (props.autoConfirm && !state.autoConfirm && !state.autoConfirmDone) {
      if (newState === null) {
        newState = {};
      }

      newState.autoConfirm = true;
      newState.autoConfirmSecondsLeft = props.autoConfirmTime;
    }

    return newState;
  }

  public componentWillUnmount() {
    window.clearTimeout(this.okButtonTimeout);
    window.clearTimeout(this.autoConfirmTimeout);
  }

  public timeoutOkButton = () => {
    const { okButtonTimeoutSecondsLeft } = this.state;

    this.okButtonTimeout = window.setTimeout(
      () => {
        if (okButtonTimeoutSecondsLeft > 0) {
          this.setState({
            okButtonText: String(okButtonTimeoutSecondsLeft),
            okButtonTimeoutSecondsLeft: okButtonTimeoutSecondsLeft - 1,
          });
        } else {
          this.setState({
            okButtonText: 'OK',
            okButtonTimeout: false,
            okButtonTimeoutDone: true,
          });
        }
      },
      defaultTimeoutSeconds - okButtonTimeoutSecondsLeft > 0 ? 1000 : 0,
    );
  };

  public autoConfirm = () => {
    const { autoConfirmDone, autoConfirmSecondsLeft } = this.state;
    const { autoConfirm, autoConfirmTime, onConfirm, onClose } = this.props;

    if (!autoConfirm || !autoConfirmTime || autoConfirmDone) {
      return;
    }

    this.autoConfirmTimeout = window.setTimeout(
      () => {
        if (autoConfirmSecondsLeft > 0) {
          this.setState({
            autoConfirmSecondsLeft: autoConfirmSecondsLeft - 1,
          });
        } else {
          this.setState({
            autoConfirmDone: true,
          });

          onConfirm();
          onClose();
        }
      },
      autoConfirmTime - autoConfirmSecondsLeft > 0 ? 1000 : 0,
    );
  };

  public render() {
    const {
      classes,
      title,
      text,
      onClose,
      onConfirm,
      centered,
      checkmarkSpinner,
      checkmarkSpinnerDone,
      minHeight,
      minWidth,
      autoConfirmTextFn,
      okButtonDisabled,
      ...rest
    } = this.props;

    // props that don't have to be set for <Dialog/>
    delete rest.timedOkButton;
    delete rest.autoConfirm;
    delete rest.autoConfirmTime;

    const { okButtonText, okButtonTimeout, autoConfirm, autoConfirmSecondsLeft } = this.state;

    if (okButtonTimeout) {
      this.timeoutOkButton();
    }

    this.autoConfirm();

    return (
      <Dialog onClose={onClose} {...rest}>
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent
          style={{
            minWidth,
            minHeight,
          }}
        >
          <DialogContentText
            align={centered ? 'center' : 'left'}
            id="confirmation-dialog-description"
            component={'div'}
          >
            {checkmarkSpinner && (
              <Fade in={true} timeout={2000}>
                <CheckmarkSpinner complete={!!checkmarkSpinnerDone} failure={false} />
              </Fade>
            )}
            {!checkmarkSpinner && text}
            <div
              style={{
                paddingTop: 18,
                textAlign: 'center',
              }}
            >
              {autoConfirm && autoConfirmTextFn && autoConfirmTextFn(autoConfirmSecondsLeft)}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={onClose}
            disabled={checkmarkSpinner}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            disabled={okButtonDisabled || checkmarkSpinner || okButtonTimeout}
            className={classes.button}
            onClick={onConfirm}
          >
            {okButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const ConfirmationDialog = withStyles(styles)(ConfirmationDialogComponent);
