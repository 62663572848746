import { HttpApiSdk, JWTAuthMechanism } from '@ekkogmbh/apisdk';
import { Button, MuiThemeProvider } from '@material-ui/core';
import 'es6-symbol/implement';
import * as EventEmitter from 'eventemitter3';
import { Provider } from 'mobx-react';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import * as ReactDOM from 'react-dom';
import * as smoothscroll from 'smoothscroll-polyfill';
import { AuthMechanisms } from './Common/Helper/AuthMechanisms';
import { OAuth2AuthMechanism } from './Common/Helper/OAuth2AuthMechanism';
import { ApiStore } from './Common/Stores/ApiStore';
import { ConfigStore } from './Common/Stores/ConfigStore';
import { ContentTitleStore } from './Common/Stores/ContentTitleStore';
import { DataTableStore } from './Common/Stores/DataTableStore';
import { NavigationStore } from './Common/Stores/NavigationStore';
import { NotificationStore } from './Common/Stores/NotificationStore';
import { PaginationStore } from './Common/Stores/PaginationStore';
import { RowSelectStore } from './Common/Stores/RowSelectStore';
import { SearchBarStore } from './Common/Stores/SearchBarStore';
import { SearchContentStore } from './Common/Stores/SearchContentStore';
import { TaskCollectionStore } from './Common/Stores/TaskCollectionStore';
import { Theme } from './Common/Theme/Default';
import fetchConfig from './config';
import { EslManager } from './EslManager';
// ext-library
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { e2e } from './ext/e2e';
import registerServiceWorker from './registerServiceWorker';

(async () => {
  smoothscroll.polyfill();
  const config = await fetchConfig();

  const authMechanism =
    config.api.authMechanism === AuthMechanisms.OAUTH2
      ? new OAuth2AuthMechanism(e2e, config.e2eSettings)
      : new JWTAuthMechanism(config.api.url);

  const apiSdk = new HttpApiSdk(config.api.url, authMechanism, config.api.autoRefresh);

  const configStore = new ConfigStore(config);

  const api = new ApiStore(
    apiSdk as HttpApiSdk & EventEmitter,
    authMechanism,
    config.api.authMechanism === AuthMechanisms.JWT,
  );
  const contentTitleStore = new ContentTitleStore();
  const dataTableStore = new DataTableStore();
  const navigationStore = new NavigationStore();
  const notificationStore = new NotificationStore();
  const paginationStore = new PaginationStore();
  const searchBarStore = new SearchBarStore();
  const searchContentStore = new SearchContentStore();
  const rowSelectStore = new RowSelectStore();
  const taskCollectionStore = new TaskCollectionStore(apiSdk as HttpApiSdk & EventEmitter);

  const stores = {
    configStore,
    api,
    contentTitleStore,
    dataTableStore,
    navigationStore,
    notificationStore,
    paginationStore,
    searchBarStore,
    searchContentStore,
    rowSelectStore,
    taskCollectionStore,
  };

  const snackbarButtons = [
    <Button color="primary" size="small" key={'dismiss-btn'}>
      {'Dismiss'}
    </Button>,
  ];

  ReactDOM.render(
    <Provider {...stores}>
      <MuiThemeProvider theme={Theme}>
        <SnackbarProvider maxSnack={4} autoHideDuration={10000} action={snackbarButtons}>
          <EslManager />
        </SnackbarProvider>
      </MuiThemeProvider>
    </Provider>,
    document.getElementById('root') as HTMLElement,
  );

  registerServiceWorker();
})();
