import {
  Avatar,
  Button,
  CssBaseline,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Typography,
  WithStyles,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/LockOutlined';
import { extendObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { ChangeEvent, FormEvent } from 'react';
import { Redirect, RouteComponentProps, StaticContext } from 'react-router';
import { Loading } from '../../Common/Components/Loading';
import { AuthMechanisms } from '../../Common/Helper/AuthMechanisms';
import { noop } from '../../Common/Helper/PromiseHelper';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { ConfigStore } from '../../Common/Stores/ConfigStore';
import { LoginStyles } from '../Styles/LoginStyles';

const styles = LoginStyles;

const stores = ['api', 'configStore'];

type LoginPropsWithStores = LoginProps & LoginStores;

interface LoginStores {
  api: ApiStore;
  configStore: ConfigStore;
}

interface LoginState {
  redirectToReferrer: boolean;
  devLogin: boolean;
  redirectToMain: boolean;
  loading: boolean;
  snackBarOpen: boolean;
}

interface LoginProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<Record<string, string | undefined>, StaticContext, { from: string }> {
  username: string;
  password: string;
}

@inject(...stores)
@observer
class LoginFormComponent extends React.Component<LoginProps, LoginState> {
  public state: LoginState = {
    redirectToReferrer: false,
    devLogin: false,
    redirectToMain: false,
    loading: false,
    snackBarOpen: false,
  };
  private submitSuccessfulCallback: () => void;
  private username: string = '';
  private password: string = '';

  constructor(props: LoginProps) {
    super(props);

    const { config } = this.stores.configStore;

    this.state = {
      ...this.state,
      devLogin: config.api.authMechanism === AuthMechanisms.JWT,
    };

    this.submitSuccessfulCallback = noop;

    extendObservable(this, {
      username: '',
      password: '',
    });
  }

  get stores(): LoginStores {
    return this.props as LoginPropsWithStores;
  }

  public componentWillUnmount(): void {
    this.submitSuccessfulCallback = noop;
  }

  public onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const { devLogin } = this.state;
    const { api } = this.stores;

    if (!devLogin) {
      await api.authenticate();
      this.setState({ redirectToMain: true });
      return;
    }

    const { username, password } = this;

    this.setState({ loading: true });

    const authPromise = api.authenticate({
      username,
      password,
    });

    this.submitSuccessfulCallback = () => {
      this.setState({ loading: false });
    };

    authPromise
      .then(() => {
        this.submitSuccessfulCallback();
      })
      .catch(() => {
        this.setState({
          loading: false,
          snackBarOpen: true,
        });
      });
  };

  public onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this[name] = value;
  };

  public handleClose = () => {
    this.setState({ snackBarOpen: false });
  };

  public render() {
    const { classes } = this.props;
    const { api } = this.stores;
    const { devLogin, redirectToMain, loading, snackBarOpen } = this.state;
    let from;

    if (this.props.location && this.props.location.state && this.props.location.state.from) {
      from = this.props.location.state.from;
    } else {
      from = { pathname: '/' };
    }

    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <div
        style={{
          backgroundSize: 'cover',
          height: '100%',
          position: 'absolute',
          width: '100%',
        }}
      >
        <div className={classes.main} style={{ position: 'relative' }}>
          {api.isAuthenticated || redirectToMain ? <Redirect to="/" /> : undefined}
          <CssBaseline />
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: 'rgba(255,255,255,0.6)',
              zIndex: 1000,
              display: loading ? 'block' : 'none',
            }}
          >
            <Loading />
          </div>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {devLogin ? 'Sign in' : 'Authentication required'}
            </Typography>
            <form className={classes.form}>
              {devLogin && (
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email">Username</InputLabel>
                  <Input id="username" name="username" autoFocus onChange={this.onChange} />
                </FormControl>
              )}
              {devLogin && (
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input name="password" type="password" id="password" onChange={this.onChange} />
                </FormControl>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.onSubmit}
              >
                Sign in
              </Button>
            </form>
          </Paper>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={snackBarOpen}
            autoHideDuration={6000}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Bad credentials.</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </div>
      </div>
    );
  }
}

export const Login = withStyles(styles)(LoginFormComponent);
