import { Endpoint, EslManagerPrivateRoute, HttpMethod, Node } from '@ekkogmbh/apisdk';
import { inject } from 'mobx-react';
import React, { ClipboardEvent, Component } from 'react';
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select';
import { createRequestWrapper } from '../Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises, createCancelableFetchPromise } from '../Helper/PromiseHelper';
import { ApiStore } from '../Stores/ApiStore';
import { ReactSelectMulti, ReactSelectMultiValue } from './ReactSelectMulti';

interface NodeCache {
  [key: number]: {
    ttl: number;
    nodes: Node[];
  };
}

const stores = ['api'];

interface AreaSelectStores {
  api: ApiStore;
}

interface AreaSelectState {
  loading: boolean;
  value: ReactSelectMultiValue[];
  suggestions: ReactSelectMultiValue[];
  currentSuggestionId: number;
}

interface AreaSelectProps {
  handleValueChange: (value: ReactSelectMultiValue[]) => void;
  value: ReactSelectMultiValue[];
  isDisabled?: boolean;
  overrideRootNodes?: Node[];
}

@inject(...stores)
class AreaSelectComponent extends Component<AreaSelectProps, AreaSelectState> {
  public state: AreaSelectState = {
    loading: false,
    value: [],
    suggestions: [],
    currentSuggestionId: 0,
  };
  private fetchPromises: CancelableFetchPromises = {};
  private nodeCache: NodeCache = {};

  get stores(): AreaSelectStores {
    return this.props as AreaSelectProps & AreaSelectStores;
  }

  public static getDerivedStateFromProps(
    props: Readonly<AreaSelectProps>,
    state: AreaSelectState,
  ): Partial<AreaSelectState> | null {
    if (props.value !== state.value) {
      const value = AreaSelectComponent.filterAreaSelection(props.value);
      return {
        value,
      };
    }

    return null;
  }

  public static filterAreaSelection(selected: ReactSelectMultiValue[]): ReactSelectMultiValue[] {
    if (!selected) {
      return [];
    }

    const filter = (value: ReactSelectMultiValue, index: number): boolean => {
      if (index === 0 && (value.parentValue === undefined || value.parentValue === null)) {
        return true;
      }

      if (!selected[index - 1]) {
        return false;
      }

      return value.parentValue === selected[index - 1].value;
    };

    let filtered = selected.filter(filter);
    let count = selected.length;

    while (filtered.length !== count) {
      count = filtered.length;
      filtered = filtered.filter(filter);
    }

    return filtered;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public async componentDidMount(): Promise<void> {
    this.setState({ loading: true }, async () => {
      this.setState({
        loading: false,
        suggestions: await this.fetchSuggestionAreas(),
      });
    });
  }

  public async componentDidUpdate(): Promise<void> {
    const { value, currentSuggestionId } = this.state;

    if (value.length > 0) {
      const nodeId = value[value.length - 1].value as number;

      if (nodeId !== currentSuggestionId) {
        this.setState(
          {
            loading: true,
            currentSuggestionId: nodeId,
          },
          async () => {
            this.setState({
              loading: false,
              suggestions: await this.fetchSuggestionAreas(nodeId),
            });
          },
        );
      }
    } else if (value.length === 0 && currentSuggestionId !== 0) {
      this.setState(
        {
          loading: true,
          currentSuggestionId: 0,
        },
        async () => {
          this.setState({
            loading: false,
            suggestions: await this.fetchSuggestionAreas(),
          });
        },
      );
    }
  }

  public fetchSuggestionAreas = async (nodeId?: number): Promise<ReactSelectMultiValue[]> => {
    const { api } = this.stores;
    const { overrideRootNodes } = this.props;

    const nodeMapper = (isRoot: boolean) => (node: Node) => ({
      value: node.id,
      label: node.value,
      fullValue: node.identifier,
      parentValue: isRoot ? null : node.parentId,
    });

    const cacheId = nodeId !== undefined ? nodeId : 0;

    if (this.nodeCache[cacheId] && this.nodeCache[cacheId].ttl < Date.now()) {
      delete this.nodeCache[cacheId];
    } else if (this.nodeCache[cacheId] !== undefined) {
      return this.nodeCache[cacheId].nodes.map(nodeMapper(cacheId === 0));
    } else if (overrideRootNodes !== undefined && cacheId === 0) {
      return overrideRootNodes.map(nodeMapper(true));
    }

    const endpoint: Endpoint = { path: EslManagerPrivateRoute.NODES };
    const apiPromise = !nodeId ? api.getNodes() : api.getNodeChildren(nodeId);

    const requestWrapper = createRequestWrapper<Node[]>(api, apiPromise);

    const { promise } = createCancelableFetchPromise<Node[]>(
      this.fetchPromises,
      EslManagerPrivateRoute.NODES,
      HttpMethod.GET,
      requestWrapper(endpoint, HttpMethod.GET),
    );

    const data = await promise;

    if (!data) {
      return [];
    }

    this.nodeCache[cacheId] = {
      ttl: Date.now() + 5 * 60 * 1000, // 5 minutes
      nodes: [...data],
    };

    return data.map(nodeMapper(cacheId === 0));
  };

  public handleChangeArea = (selected: ValueType<OptionTypeBase>, _?: ActionMeta<OptionTypeBase>): void => {
    const value = AreaSelectComponent.filterAreaSelection(selected as ReactSelectMultiValue[]);

    const nodeId = value.length === 0 ? undefined : parseInt(value[value.length - 1].value as string, 10);

    this.setState(
      {
        loading: true,
      },
      async () => {
        const { handleValueChange } = this.props;

        this.setState(
          {
            loading: false,
            suggestions: await this.fetchSuggestionAreas(nodeId),
            currentSuggestionId: nodeId ? nodeId : 0,
          },
          () => handleValueChange(value),
        );
      },
    );
  };

  public onPaste = async (event: ClipboardEvent<HTMLSelectElement>): Promise<void> => {
    event.persist();
    event.preventDefault();

    this.setState({ loading: true });

    const clipboardText = event.clipboardData.getData('text');
    const untrimmedParts = clipboardText.split(/[/_.]/);

    if (untrimmedParts.length === 0) {
      return;
    }

    const parts = untrimmedParts.map((part: string) => part.trim());

    const ReactSelectMultiValues: ReactSelectMultiValue[] = [];
    const rootNodes = await this.fetchSuggestionAreas();

    const reducer = (nodes: ReactSelectMultiValue[]) => (acc: ReactSelectMultiValue, curr: string) => {
      const index = nodes.map((rsv: ReactSelectMultiValue) => rsv.label).indexOf(curr);

      if (index !== -1) {
        acc.label = nodes[index].label;
        acc.fullValue = nodes[index].fullValue;
        acc.value = parseInt(nodes[index].value as string, 10);
        acc.parentValue = nodes[index].parentValue !== null ? parseInt(nodes[index].parentValue as string, 10) : null;
      }
      return acc;
    };

    let reduced = parts.reduce(reducer(rootNodes), {} as ReactSelectMultiValue);

    while (reduced.label !== undefined && reduced.value !== undefined) {
      const { label, value, parentValue, fullValue } = reduced;

      ReactSelectMultiValues.push({
        label,
        value,
        parentValue,
        fullValue,
      });

      const childNodes = await this.fetchSuggestionAreas(parseInt(String(reduced.value), 10));

      reduced = parts.reduce(reducer(childNodes), {} as ReactSelectMultiValue);
    }

    this.setState({ loading: false });

    setTimeout(() => this.handleChangeArea(ReactSelectMultiValues), 250);
  };

  public render(): JSX.Element {
    const { loading, suggestions, value } = this.state;

    const { isDisabled } = this.props;

    return (
      <ReactSelectMulti
        label={'Area'}
        loading={loading}
        handleChange={this.handleChangeArea}
        value={value}
        placeholder={'Select Area'}
        suggestions={suggestions}
        onPaste={this.onPaste}
        fullValueLabel={'Path'}
        isDisabled={isDisabled}
      />
    );
  }
}

export const AreaSelect = AreaSelectComponent;
