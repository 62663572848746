import { Grid, IconButton, WithStyles, withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import * as classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { RefObject } from 'react';
import React from 'react';
import { ReactNode } from 'react';
import { NavigationStore } from '../Stores/NavigationStore';
import { NavigationStyles } from '../Styles/NavigationStyles';
import { Header } from './Header';

interface BodyProps extends WithStyles<typeof NavigationStyles> {
  children?: ReactNode;
  title: string;
  navigationStore?: NavigationStore;
}

@inject('navigationStore')
@observer
class BodyComponent extends React.Component<BodyProps> {
  private readonly ref: RefObject<HTMLDivElement>;
  private stickyHeader: boolean = false;

  constructor(props: BodyProps) {
    super(props);

    this.ref = React.createRef();
  }

  public componentDidMount(): void {
    const { navigationStore } = this.props;
    navigationStore!.setScrollComponent(this.ref);
  }

  public componentWillUnmount(): void {
    const { navigationStore } = this.props;
    navigationStore!.unsetScrollComponent();
  }

  public getDrawerOpenState = () => {
    const { navigationStore } = this.props;
    return navigationStore!.open;
  };

  public onScroll = () => {
    const scrollTop = this.ref.current!.scrollTop;
    const before = this.stickyHeader;

    this.stickyHeader = scrollTop > 172;

    if (before !== this.stickyHeader) {
      this.forceUpdate();
    }
  };

  public scrollTop = () => {
    const { navigationStore } = this.props;
    navigationStore!.scrollTop();
  };

  public render() {
    const { children, classes, title } = this.props;

    const iconButtonClassNames = classNames(
      classes.appBarButton,
      classes.drawerButton,
      classes.appBarButtonSticky,
      !this.getDrawerOpenState() && classes.drawerButtonDrawerClosed,
      this.stickyHeader && classes.drawerButtonSticky,
    );

    return (
      <div className={classes.content} ref={this.ref} onScroll={this.onScroll}>
        <Header key={`header-${title}`} title={title} sticky={this.stickyHeader} />
        <Grid container className={classes.contentGrid}>
          {children}
        </Grid>
        <Tooltip title={'Scroll to Top'} placement="top" enterDelay={500} leaveDelay={200}>
          <IconButton color="primary" onClick={this.scrollTop} className={iconButtonClassNames}>
            <ArrowUpward />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export const Body = withStyles(NavigationStyles)(BodyComponent);
