import { SortedSide, SortedSideSortingStrategy, Template } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';
import { ReactSelectMultiValue } from '../../Common/Components/ReactSelectMulti';

export interface SortableSpatialEntity {
  id: string | number;
  identifierValue: string;
  keyValue: string;
  key: string;
}

export interface PickingSideState {
  name?: string;
  key: string;
  templates: Template[];
  allFilled: boolean;
  nodeSelectValue: ReactSelectMultiValue[];
  nodeValue: string;
  needRefresh: boolean;
}

export class PickingSideStore {
  @observable
  public editableSortedSide?: SortedSide;

  @observable
  public state: PickingSideState = {
    allFilled: false,
    templates: [],
    needRefresh: false,
    nodeSelectValue: [],
    nodeValue: '',
    key: '',
    name: '',
  };

  @observable
  public sortableItems: SortableSpatialEntity[] = [];

  @observable
  public sortingStrategy: SortedSideSortingStrategy = 'alphanumeric-identifier';

  @observable
  public loading: boolean = false;

  @observable
  public changed: boolean = false;

  @action
  public setState = (newState: Partial<PickingSideState>): void => {
    this.state = {
      ...this.state,
      ...newState,
    };
  };

  @action
  public resetState = (): void => {
    const { editableSortedSide } = this;

    this.state = {
      allFilled: editableSortedSide !== undefined,
      needRefresh: false,
      nodeSelectValue: [],
      nodeValue: '',
      name: editableSortedSide ? editableSortedSide.name : '',
      key: editableSortedSide ? editableSortedSide.key : '',
      templates: editableSortedSide ? editableSortedSide.templates : [],
    };

    this.sortingStrategy = editableSortedSide ? editableSortedSide.sortingStrategy : 'alphanumeric-identifier';
    this.changed = false;
    this.loading = false;

    if (editableSortedSide === undefined) {
      this.sortableItems = [];
    }
  };

  @action
  public resetStore = (): void => {
    this.editableSortedSide = undefined;
    this.sortableItems = [];
    this.loading = false;
    this.changed = false;
    this.resetState();
  };
}
