import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { YourProfileContent } from './Components/YourProfileContent';

export const YourProfile = (): JSX.Element => (
  <Provider>
    <Page title="Your Profile" isSearchable={false}>
      <YourProfileContent />
    </Page>
  </Provider>
);
