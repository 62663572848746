import {
  CompartmentSavePayload,
  CompartmentView,
  EslManagerPrivateRoute,
  EslManagerPublicRouteV1,
  Filtering,
  HttpMethod,
  LinkSavePayload,
  LinkView,
  Pagination,
  PaginationResponse,
  UserPermission,
} from '@ekkogmbh/apisdk';
import { Grid, Omit, Paper, WithStyles, withStyles } from '@material-ui/core';
import { AllInbox, CloudUpload, SaveAlt } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';
import * as classNames from 'classnames';
import { MaterialDatatableColumnDef } from 'material-datatable';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ContentPanelDefinition, ContentPanels } from 'src/Common/Components/ContentPanels';
import { ConfirmationDialog } from '../../Common/Components/ConfirmationDialog';
import { ImportCallbacks } from '../../Common/Components/CSVImportStepper';
import { DataTable } from '../../Common/Components/DataTable';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { createBlobDownload } from '../../Common/Helper/BlobDownload';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises } from '../../Common/Helper/PromiseHelper';
import { SuccessHandlerStatusMessages } from '../../Common/Helper/ResponseHandler';
import { ConfigStore } from '../../Common/Stores/ConfigStore';
import { TaskCollectionProgressCallback, TaskCollectionStore } from '../../Common/Stores/TaskCollectionStore';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { DataTableStore } from '../../Common/Stores/DataTableStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { PaginationStore } from '../../Common/Stores/PaginationStore';
import { RowSelectStore } from '../../Common/Stores/RowSelectStore';
import { SearchContentStore, ST_PLACEHOLDER } from '../../Common/Stores/SearchContentStore';
import { LinkPanel, LinkPanelProps } from '../../LabelManagement/Components/LinkPanel';
import { CompartmentStyles } from '../Styles/CompartmentStyles';
import { materialDatatableColumnDefinitions } from './CompartmentDatatableColumnDefinitions';
import { CompartmentPanel, CompartmentPanelProps } from './CompartmentPanel';
import { ExportPanel, ExportPanelProps } from './ExportPanel';
import { ImportPanel, ImportPanelProps } from './ImportPanel';

const styles = CompartmentStyles;

export enum ProcessingType {
  LINK = 'link',
  COMPARTMENT = 'compartment',
}

interface ProcessingEntity {
  id?: number | string;
  type: ProcessingType;
}

const stores = [
  'api',
  'navigationStore',
  'paginationStore',
  'searchContentStore',
  'rowSelectStore',
  'dataTableStore',
  'taskCollectionStore',
  'configStore',
];

interface CompartmentManagementContentActions {
  updateCompartment?: (compartment: CompartmentView) => void;
  updateLink?: (compartment: CompartmentView, link?: LinkView) => void;
  blinkByCoordinate?: (coordinate: CompartmentView['coordinate']) => void;
  closeContentPanels?: () => void;
}

export interface CompartmentManagementContentHelpers {
  updateLink: (compartment: CompartmentView, linkIndex?: number) => void;
  isProcessing: (entity: ProcessingEntity) => boolean;
  deleteLinkDialog: (link: LinkView, compartment: CompartmentView) => Promise<void>;
}

export interface CompartmentManagementContentActionHandlers {
  edit: (compartment: CompartmentView) => void;
  blink: (compartment: CompartmentView) => void;
  link: (compartment: CompartmentView) => void;
  delete: (compartment: CompartmentView) => void;
  details: (compartment: CompartmentView) => void;
}

export interface CompartmentManagementContentState {
  blinkDialogOpen: boolean;
  deleteDialogOpen: boolean;
  deleteLinkDialogOpen: boolean;
  deleteSelectedDialogOpen: boolean;
  deletableCompartmentCoordinates: string[];
  deleteSelectedDialogCheckmarkSpinner: boolean;
  deleteSelectedDialogCheckmarkSpinnerDone: boolean;
  editableCompartment?: CompartmentView;
  editableLink?: LinkView;
  processing: ProcessingEntity[];
  loading: boolean;
}

interface CompartmentManagementContentStores {
  api: ApiStore;
  navigationStore: NavigationStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  rowSelectStore: RowSelectStore;
  dataTableStore: DataTableStore;
  taskCollectionStore: TaskCollectionStore;
  configStore: ConfigStore;
}

interface CompartmentManagementContentProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    InjectedNotistackProps {}

export type CompartmentManagementContentPropsWithStores = CompartmentManagementContentProps &
  CompartmentManagementContentStores;

@inject(...stores)
@observer
class CompartmentManagementContentComponent extends Component<
  CompartmentManagementContentProps,
  CompartmentManagementContentState
> {
  public state: CompartmentManagementContentState = {
    blinkDialogOpen: false,
    deleteDialogOpen: false,
    deleteLinkDialogOpen: false,
    deleteSelectedDialogOpen: false,
    deletableCompartmentCoordinates: [],
    deleteSelectedDialogCheckmarkSpinner: false,
    deleteSelectedDialogCheckmarkSpinnerDone: false,
    processing: [],
    loading: false,
  };

  private readonly actions: CompartmentManagementContentActions = {};
  private fetchPromises: CancelableFetchPromises = {};
  private readonly successTaskCollectionStatusCodes: SuccessHandlerStatusMessages = {
    200: 'TaskCollection created.',
  };
  private readonly successBlinkStatusCodes: SuccessHandlerStatusMessages = {
    200: 'Blink by Coordinate successful.',
  };

  get stores(): CompartmentManagementContentStores {
    return this.props as CompartmentManagementContentPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    const { searchContentStore } = this.stores;

    searchContentStore.setSearchBarDisabled();

    searchContentStore.setSearchTextFieldOverride({
      field: 'fields',
      pattern: ST_PLACEHOLDER,
    });

    searchContentStore.setSearchBarDisabled(false);
  }

  public isProcessing = (entity: ProcessingEntity) => {
    const { processing } = this.state;

    if (processing.length < 1) {
      return false;
    }

    const filtered = processing.filter(
      (processingEntity: ProcessingEntity) =>
        processingEntity.id === entity.id && processingEntity.type === entity.type,
    );

    return filtered.length > 0;
  };

  public onDeleteSelectedClick = () => {
    const { rowSelectStore } = this.stores;

    const coordinates = Object.keys(rowSelectStore.selectedRows).reduce((acc: string[], curr: string) => {
      if (rowSelectStore.selectedRows[curr]) {
        acc.push(curr);
      }

      return acc;
    }, []);

    this.setState({
      deleteSelectedDialogOpen: true,
      deletableCompartmentCoordinates: coordinates,
    });
  };

  public onDeleteSelectedDismiss = () => {
    this.setState({
      deleteSelectedDialogOpen: false,
      deletableCompartmentCoordinates: [],
    });
  };

  public onDeleteSelectedOk = async () => {
    this.setState(
      {
        loading: true,
        deleteSelectedDialogCheckmarkSpinner: true,
      },
      this.bulkDeleteCompartments,
    );
  };

  public bulkDeleteCompartments = async (): Promise<void> => {
    const { deletableCompartmentCoordinates } = this.state;
    const { api, searchContentStore, rowSelectStore } = this.stores;
    const { enqueueSnackbar } = this.props;

    const progressCallback = (_: string, __: string, progress?: number) => {
      if (progress === 100) {
        this.setState({
          loading: false,
          deleteSelectedDialogCheckmarkSpinnerDone: true,
          deletableCompartmentCoordinates: [],
        });

        setTimeout(() => {
          this.setState(
            {
              deleteSelectedDialogOpen: false,
              deleteSelectedDialogCheckmarkSpinner: false,
              deleteSelectedDialogCheckmarkSpinnerDone: false,
            },
            () => {
              searchContentStore.emitRefresh();
              rowSelectStore.reset();
              rowSelectStore.clear();
            },
          );
        }, 1500);
      }
    };

    const taskCollectionHandler = (taskCollectionUri: string | null) => {
      this.stores.taskCollectionStore.processTaskCollection(taskCollectionUri, progressCallback);
    };

    return await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.bulkDeleteCompartments(deletableCompartmentCoordinates, taskCollectionHandler),
      EslManagerPrivateRoute.BULK_COMPARTMENT_DELETE,
      HttpMethod.POST,
      this.successTaskCollectionStatusCodes,
    );
  };

  public deleteSelectedText = () => {
    const { deletableCompartmentCoordinates } = this.state;

    const identMax = 10;
    const identCount = deletableCompartmentCoordinates.length;
    const single = deletableCompartmentCoordinates.length === 1;

    let identifiersString = deletableCompartmentCoordinates.slice(0, identMax).join(', ');

    if (identCount > identMax) {
      identifiersString += '... (' + (identCount - identMax) + ' more)';
    }

    if (single) {
      return `Delete Compartment with Coordinate ${identifiersString}?`;
    }

    return (
      <div>
        <div>
          Delete <span style={{ fontWeight: 700 }}>{identCount}</span> Compartments?
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 18,
          }}
        >
          <span style={{ fontWeight: 700 }}>Coordinates:</span> {identifiersString}
        </div>
      </div>
    );
  };

  public exportCompartments = (contentType: string) => async () => {
    const { api, paginationStore } = this.stores;
    const { enqueueSnackbar } = this.props;

    this.setState({ loading: true });

    const pagination = paginationStore!.pagination;

    const { sort, direction, filter } = pagination;

    const filtering = {
      sort,
      direction,
      filter,
    } as Filtering;

    const fileExtMap = {
      'application/json': 'json',
      'text/plain': 'csv',
      'text/csv': 'csv',
    };

    try {
      const blob = await request<Blob>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.exportCompartments(filtering, contentType),
        EslManagerPrivateRoute.EXPORTER_COMPARTMENTS,
        HttpMethod.GET,
      );
      const timestamp = Date.now();
      const filename = `compartments_${timestamp}.${fileExtMap[contentType]}`;

      this.setState({ loading: false }, () => {
        createBlobDownload(blob, filename);
      });
    } catch (e) {
      enqueueSnackbar(e.toString(), { variant: 'error' });
      this.setState({ loading: false });
    }
  };

  public exportLinks = (contentType: string) => async () => {
    const { api, paginationStore } = this.stores;
    const { enqueueSnackbar } = this.props;

    this.setState({ loading: true });

    const pagination = paginationStore!.pagination;

    const { sort, direction, filter } = pagination;

    const filtering = {
      sort,
      direction,
      filter,
    } as Filtering;

    const fileExtMap = {
      'application/json': 'json',
      'text/plain': 'csv',
      'text/csv': 'csv',
    };

    try {
      const blob = await request<Blob>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.exportLinks(filtering, contentType),
        EslManagerPrivateRoute.EXPORTER_LINKS,
        HttpMethod.GET,
      );
      const timestamp = Date.now();
      const filename = `links_${timestamp}.${fileExtMap[contentType]}`;

      this.setState({ loading: false }, () => {
        createBlobDownload(blob, filename);
      });
    } catch (e) {
      enqueueSnackbar(e.toString(), { variant: 'error' });
      this.setState({ loading: false });
    }
  };

  // TODO: associated task collection should be handled.
  public saveLinkHandler = async (link: LinkSavePayload): Promise<void> => {
    const { enqueueSnackbar } = this.props;
    const { api, searchContentStore } = this.stores;

    await request<LinkView>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveLink(link),
      EslManagerPublicRouteV1.LINK,
      HttpMethod.PUT,
      { 200: 'Link saved.' },
    );

    searchContentStore!.emitRefresh();
  };

  // TODO: associated task collection should be handled.
  public saveCompartmentHandler = async (compartment: CompartmentSavePayload): Promise<void> => {
    const { enqueueSnackbar } = this.props;
    const { api, searchContentStore } = this.stores;

    await request<CompartmentView>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveCompartment(compartment),
      EslManagerPublicRouteV1.COMPARTMENT,
      HttpMethod.PUT,
      { 200: 'Compartment saved.' },
    );

    searchContentStore!.emitRefresh();
  };

  // TODO: associated task collection should be handled.
  public deleteCompartmentHandler = async (
    compartment: CompartmentView,
    _?: TaskCollectionProgressCallback,
  ): Promise<void> => {
    const { enqueueSnackbar } = this.props;
    const { api, searchContentStore } = this.stores;

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteCompartment(compartment),
      EslManagerPublicRouteV1.COMPARTMENT,
      HttpMethod.DELETE,
      this.successTaskCollectionStatusCodes,
    );

    searchContentStore!.emitRefresh();
  };

  // TODO: associated task collection should be handled.
  public deleteLinkHandler = async (link: LinkView, _?: TaskCollectionProgressCallback): Promise<void> => {
    const { enqueueSnackbar } = this.props;
    const { api, searchContentStore } = this.stores;

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteLink(link),
      EslManagerPrivateRoute.LINKS,
      HttpMethod.DELETE,
      this.successTaskCollectionStatusCodes,
    );

    searchContentStore!.emitRefresh();
  };

  // TODO: task collections via location header don't seem to work properly in apisdk v19.
  public createRefreshCallback = (
    processingEntity?: ProcessingEntity,
    progressCallback?: TaskCollectionProgressCallback,
  ) => {
    const { searchContentStore } = this.stores;

    if (processingEntity && processingEntity.id !== undefined) {
      const { processing } = this.state;
      processing.push(processingEntity);
      this.setState({ processing });
    }

    return (taskCollectionId: string, state: string, progress?: number) => {
      if (progressCallback) {
        progressCallback(taskCollectionId, state, progress);
      }

      if (progress === 100) {
        if (processingEntity && processingEntity.id !== undefined) {
          const { processing } = this.state;
          const index = processing.indexOf(processingEntity);

          if (index !== -1) {
            processing.splice(index, 1);
            this.setState({ processing });
          }
        }

        searchContentStore!.emitRefresh();
      }
    };
  };

  public onBlinkOk = async () => {
    const { editableCompartment } = this.state;

    this.blinkByCoordinateHandler(editableCompartment!.coordinate);

    this.setState({
      editableCompartment: undefined,
      blinkDialogOpen: false,
    });
  };

  public onBlinkDismiss = async () => {
    this.setState({
      editableCompartment: undefined,
      blinkDialogOpen: false,
    });
  };

  public onDeleteOk = async () => {
    const { editableCompartment } = this.state;

    await this.deleteCompartmentHandler(editableCompartment as CompartmentView);

    this.setState({
      editableCompartment: undefined,
      deleteDialogOpen: false,
    });
  };

  public onDeleteDismiss = () => {
    this.setState({
      editableCompartment: undefined,
      deleteDialogOpen: false,
    });
  };

  public onDeleteLinkOk = async () => {
    const { editableLink } = this.state;

    await this.deleteLinkHandler(editableLink as LinkView);

    this.setState({
      editableCompartment: undefined,
      editableLink: undefined,
      deleteLinkDialogOpen: false,
    });
  };

  public onDeleteLinkDismiss = () => {
    this.setState({
      editableCompartment: undefined,
      editableLink: undefined,
      deleteLinkDialogOpen: false,
    });
  };

  public importHandler = async (
    callbacks: ImportCallbacks,
    csv: File,
    progressCallback?: TaskCollectionProgressCallback,
  ): Promise<void> => {
    const { enqueueSnackbar } = this.props;
    const { api } = this.stores;

    const taskCollectionHandler = (taskCollectionUri: string | null) => {
      this.stores.taskCollectionStore.processTaskCollection(taskCollectionUri, progressCallback);
    };

    try {
      await request<void>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.importCompartments(csv, taskCollectionHandler),
        EslManagerPrivateRoute.IMPORTER_COMPARTMENTS,
        HttpMethod.POST,
        this.successTaskCollectionStatusCodes,
        callbacks.success,
        callbacks.error,
      );
    } catch (e) {
      console.error(e);
    }
  };

  public importHandlerLinks = async (
    callbacks: ImportCallbacks,
    csv: File,
    progressCallback?: TaskCollectionProgressCallback,
  ): Promise<void> => {
    const { enqueueSnackbar } = this.props;
    const { api } = this.stores;

    const taskCollectionHandler = (taskCollectionUri: string | null) => {
      this.stores.taskCollectionStore.processTaskCollection(taskCollectionUri, progressCallback);
    };

    try {
      await request<void>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.importLinks(csv, taskCollectionHandler),
        EslManagerPrivateRoute.IMPORTER_LINKS,
        HttpMethod.POST,
        this.successTaskCollectionStatusCodes,
        callbacks.success,
        callbacks.error,
      );
    } catch (e) {
      console.error(e);
    }
  };

  public blinkByCoordinateHandler = async (coordinate: CompartmentView['coordinate']): Promise<void> => {
    const { enqueueSnackbar } = this.props;
    const { api } = this.stores;

    await request<LinkView[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.blinkByCoordinate(coordinate),
      EslManagerPublicRouteV1.BLINK_BY_COORDINATE,
      HttpMethod.POST,
      this.successBlinkStatusCodes,
    );
  };

  public importFinishedHandler = () => {
    const { searchContentStore } = this.stores;

    searchContentStore.emitRefresh();
  };

  public fetchItems = (pagination: Pagination): Promise<PaginationResponse<CompartmentView>> => {
    const { api } = this.stores;

    return api.getCompartments(pagination);
  };

  public fetchPermissions = (): string[] => {
    const { api } = this.stores;

    const permissions = api.getUserPermissions();

    return permissions.map((permission: UserPermission): string => permission.permissionName);
  };

  public actionHandlerDetails = async (compartment: CompartmentView) => {
    const { history } = this.props;
    history.push('/compartments/' + compartment.coordinate);
  };

  public actionHandlerLink = async (compartment: CompartmentView, linkIndex?: number) => {
    const { navigationStore } = this.stores;

    const editableLink = linkIndex !== undefined ? compartment.links[linkIndex] : undefined;

    if (this.actions.updateLink) {
      this.actions.updateLink(compartment, editableLink);
      navigationStore!.scrollTop();
    }
  };

  public actionHandlerEdit = async (compartment: CompartmentView) => {
    const { navigationStore } = this.stores;

    if (this.actions.updateCompartment) {
      this.actions.updateCompartment(compartment);
      navigationStore!.scrollTop();
    }
  };

  public actionHandlerDelete = async (compartment: CompartmentView) => {
    const { closeContentPanels } = this.actions;
    if (closeContentPanels !== undefined) {
      closeContentPanels();
    }
    this.setState({
      deleteDialogOpen: true,
      editableCompartment: compartment,
    });
  };

  public actionHandlerBlink = async (compartment: CompartmentView) => {
    const { closeContentPanels } = this.actions;
    if (closeContentPanels !== undefined) {
      closeContentPanels();
    }
    this.setState({
      blinkDialogOpen: true,
      editableCompartment: compartment,
    });
  };

  public actionHandler = (clicked: string, compartment: CompartmentView, linkIndex?: number) => {
    const { navigationStore } = this.stores;

    switch (clicked) {
      case 'details':
        const { history } = this.props;
        history.push('/compartments/' + compartment.coordinate);
        break;

      case 'link':
        const editableLink = linkIndex !== undefined ? compartment.links[linkIndex] : undefined;

        if (this.actions.updateLink) {
          this.actions.updateLink(compartment, editableLink);
          navigationStore!.scrollTop();
        }

        break;

      case 'edit':
        if (this.actions.updateCompartment) {
          this.actions.updateCompartment(compartment);
          navigationStore!.scrollTop();
        }

        break;

      case 'delete':
        this.setState({
          deleteDialogOpen: true,
          editableCompartment: compartment,
        });

        break;
    }
  };

  public deleteLinkDialog = async (link: LinkView, compartment: CompartmentView) => {
    const { closeContentPanels } = this.actions;
    if (closeContentPanels !== undefined) {
      closeContentPanels();
    }
    this.setState({
      deleteLinkDialogOpen: true,
      editableCompartment: compartment,
      editableLink: link,
    });
  };

  public closeContentPanels = async (closePanelsCallback: () => void) => {
    const { closeContentPanels } = this.actions;
    if (closeContentPanels === undefined) {
      this.actions.closeContentPanels = () => {
        closePanelsCallback();
      };
    }
  };

  public createContentPanels = (): ContentPanelDefinition[] => {
    const { editableCompartment, editableLink } = this.state;

    const closeCallback = () => {
      this.setState({
        editableCompartment: undefined,
        editableLink: undefined,
      });
    };

    const addCompartmentPanelDefinition: ContentPanelDefinition<CompartmentPanelProps> = {
      name: 'Add',
      icon: AddIcon,
      isHidden: false,
      panelComponent: CompartmentPanel,
      panelProps: {
        closeHandler: closeCallback,
        saveHandler: this.saveCompartmentHandler,
        deleteHandler: this.deleteCompartmentHandler,
      },
      permission: Permissions.SHELFLABELING_WRITE,
      toggleOffCallback: closeCallback,
    };

    /*
     * TODO: this is a workaround for the fields in the addPanel net clearing "onCancel"
     * I suspect it needs a proper CompartmentStore (analogous to other Add/Edit panel implementation)
     * for a clean fix?
     *
     * Changes in behaviour:
     * - With AddPanel open, clicking Edit will cause an expand animation
     * - With EditPanel open, clicking Add will open the AddPanel (previously would toggle off the EditPanel)
     */
    const editCompartmentPanelDefinition: ContentPanelDefinition<CompartmentPanelProps> = {
      name: 'Edit',
      icon: AddIcon,
      isHidden: true,
      panelComponent: CompartmentPanel,
      expansionStyle: { padding: 0 },
      panelProps: {
        closeHandler: closeCallback,
        saveHandler: this.saveCompartmentHandler,
        deleteHandler: this.deleteCompartmentHandler,
        compartment: editableCompartment,
      },
      permission: Permissions.SHELFLABELING_WRITE,
      toggleOffCallback: closeCallback,
      expandHandler: (expandCallback: () => void) => {
        const { updateCompartment } = this.actions;
        if (updateCompartment === undefined) {
          this.actions.updateCompartment = (compartment: CompartmentView) => {
            this.setState({ editableCompartment: compartment });
            expandCallback();
          };
        }
      },
    };

    const linkPanelDefinition: ContentPanelDefinition<LinkPanelProps> = {
      name: 'Link',
      icon: LinkIcon,
      isHidden: true,
      panelComponent: LinkPanel,
      panelProps: {
        closeHandler: closeCallback,
        saveHandler: this.saveLinkHandler,
        deleteHandler: this.deleteLinkHandler,
        link: editableLink,
        compartment: editableCompartment,
      },
      permission: Permissions.SHELFLABELING_WRITE,
      toggleOffCallback: closeCallback,
      expandHandler: (expandCallback: () => void) => {
        const { updateLink } = this.actions;
        if (updateLink === undefined) {
          this.actions.updateLink = (compartment: CompartmentView, link?: LinkView) => {
            this.setState({
              editableCompartment: compartment,
              editableLink: link,
            });
            expandCallback();
          };
        }
      },
    };

    const importPanelDefinition: ContentPanelDefinition<ImportPanelProps> = {
      name: 'Import',
      icon: CloudUpload,
      isHidden: false,
      panelComponent: ImportPanel,
      panelProps: {
        tabs: [
          {
            description: 'Import Compartments',
            icon: AllInbox,
            importHandler: this.importHandler,
          },
          {
            description: 'Import Links',
            icon: LinkIcon,
            importHandler: this.importHandlerLinks,
            disableDiffModalOverride: true,
          },
        ],
        importFinishedHandler: this.importFinishedHandler,
      },
      permission: Permissions.SHELFLABELING_WRITE,
      expansionStyle: { display: 'block' },
      toggleOffCallback: closeCallback,
    };

    const exportPanelDefinition: ContentPanelDefinition<ExportPanelProps> = {
      name: 'Export',
      icon: SaveAlt,
      isHidden: false,
      panelComponent: ExportPanel,
      panelProps: {
        tabs: [
          {
            description: 'Compartments',
            icon: AllInbox,
            exportHandler: this.exportCompartments,
          },
          {
            description: 'Links',
            icon: LinkIcon,
            exportHandler: this.exportLinks,
          },
        ],
        formatTypes: this.stores.configStore.config?.exportFormatTypes,
      },
      toggleOffCallback: closeCallback,
      permission: Permissions.SHELFLABELING_READ,
      expansionStyle: { display: 'block' },
    };

    return [
      addCompartmentPanelDefinition,
      editCompartmentPanelDefinition,
      linkPanelDefinition,
      importPanelDefinition,
      exportPanelDefinition,
    ];
  };

  public render() {
    const { classes } = this.props;
    const {
      blinkDialogOpen,
      deleteDialogOpen,
      deleteLinkDialogOpen,
      deleteSelectedDialogOpen,
      deletableCompartmentCoordinates,
      deleteSelectedDialogCheckmarkSpinner,
      deleteSelectedDialogCheckmarkSpinnerDone,
      editableCompartment,
      editableLink,
      loading,
    } = this.state;

    const blinkDialogText = editableCompartment
      ? `Blink all Label linked to Coordinate ${editableCompartment!.coordinate}?`
      : '';

    const deleteDialogText = editableCompartment
      ? `Delete Compartment with Coordinate ${editableCompartment!.coordinate}?`
      : '';

    const deleteLinkDialogText =
      editableCompartment && editableLink ? (
        <React.Fragment>
          Coordinate: <span style={{ fontWeight: 'bold' }}>{editableCompartment!.coordinate}</span>
          <br />
          LabelId: <span style={{ fontWeight: 'bold' }}>{editableLink!.labelId}</span>
          <br />
          Template: <span style={{ fontWeight: 'bold' }}>{editableLink!.template.name}</span>
          <br />
          Page: <span style={{ fontWeight: 'bold' }}>{editableLink!.pageNumber}</span>
        </React.Fragment>
      ) : (
        ''
      );

    const deleteSelectedDialogText = deletableCompartmentCoordinates.length > 0 ? this.deleteSelectedText() : '';

    const contentPanels = this.createContentPanels();

    const columnDefinitions: MaterialDatatableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(
        this.state,
        this.props as CompartmentManagementContentPropsWithStores,
        {
          details: this.actionHandlerDetails,
          link: this.actionHandlerLink,
          edit: this.actionHandlerEdit,
          blink: this.actionHandlerBlink,
          delete: this.actionHandlerDelete,
        },
        {
          updateLink: this.actionHandlerLink,
          isProcessing: this.isProcessing,
          deleteLinkDialog: this.deleteLinkDialog,
        },
      ),
    );

    return (
      <Grid item xs={12}>
        {deleteDialogOpen && (
          <ConfirmationDialog
            open={deleteDialogOpen}
            title={'Delete Compartment'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
            timedOkButton={true}
          />
        )}

        {blinkDialogOpen && (
          <ConfirmationDialog
            open={blinkDialogOpen}
            title={'Blink by Coordinate'}
            text={blinkDialogText}
            onClose={this.onBlinkDismiss}
            onConfirm={this.onBlinkOk}
            timedOkButton={false}
          />
        )}

        {deleteLinkDialogOpen && (
          <ConfirmationDialog
            open={deleteLinkDialogOpen}
            title={'Delete Link'}
            text={deleteLinkDialogText}
            onClose={this.onDeleteLinkDismiss}
            onConfirm={this.onDeleteLinkOk}
            timedOkButton={true}
          />
        )}

        {deleteSelectedDialogOpen && (
          <ConfirmationDialog
            open={deleteSelectedDialogOpen}
            title={'Delete selected Compartments'}
            text={deleteSelectedDialogText}
            onClose={this.onDeleteSelectedDismiss}
            onConfirm={this.onDeleteSelectedOk}
            checkmarkSpinner={deleteSelectedDialogCheckmarkSpinner}
            checkmarkSpinnerDone={deleteSelectedDialogCheckmarkSpinnerDone}
            timedOkButton={true}
            minHeight={180}
            minWidth={564}
          />
        )}

        <ContentPanels
          panels={contentPanels}
          onDeleteAction={this.onDeleteSelectedClick}
          closePanelsHandler={this.closeContentPanels}
        />

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          {loading && <LoadingMask width={75} height={75} topPercent={10} />}
          <DataTable
            fetchItems={this.fetchItems}
            columns={columnDefinitions}
            filterFields={['identifier', 'label', 'template', 'fields']}
            sortFieldMap={{ coordinate: 'Node.materializedPath' }}
            options={{ sortColumnIndex: 1 }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<CompartmentManagementContentProps, typeof CompartmentManagementContentComponent>(
  CompartmentManagementContentComponent,
);
const SnackbarWrapped = withSnackbar<Omit<CompartmentManagementContentProps, keyof RouteComponentProps>>(RouterWrapped);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const CompartmentManagementContent = StyleWrapped;
