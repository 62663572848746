import { Preset } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export interface PresetState {
  name: string;
  coordinate: string;
  keys: string[];
  allFilled: boolean;
}

export class PresetStore {
  @observable
  public editablePreset?: Preset;

  @observable
  public state: PresetState = {
    name: '',
    coordinate: '',
    keys: [],
    allFilled: false,
  };

  @observable
  public stagedKeys: string[] = [];

  @observable
  public changed: boolean = false;

  @action
  public setState = (newState: Partial<PresetState>, setAllFilled: boolean = true): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    if (setAllFilled) {
      const allFilled = this.state.name !== '' && this.state.coordinate !== '' && this.state.keys.length > 0;

      this.state = {
        ...this.state,
        allFilled,
      };
    }
  };

  @action
  public setEditablePreset = (preset: Preset): void => {
    this.editablePreset = preset;

    this.setState(
      {
        name: preset.name,
        coordinate: preset.coordinatePrefix.replace(/[_|.|\/]+$/, ''),
        keys: preset.keys ?? [],
      },
      true,
    );
  };

  @action
  public resetStore = (editablePreset?: Preset): void => {
    if (editablePreset !== undefined) {
      this.setEditablePreset(editablePreset);
    } else {
      this.editablePreset = undefined;
      this.stagedKeys = [];
      this.setState({
        name: '',
        coordinate: '',
        keys: [],
      });
    }
  };
}
