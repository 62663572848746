import { Tooltip, withStyles, WithStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { ChangeEvent, Component } from 'react';
import { FormStyles } from '../../Styles/FormStyles';

const styles = FormStyles;

interface StyledTextFieldProps extends WithStyles<typeof styles> {
  type: 'text' | 'number';
  label: string;
  value: string | number | boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  tooltip?: string;
}

class StyledTextFieldComponent extends Component<StyledTextFieldProps> {
  public textField = (): JSX.Element => {
    const { classes, type, label, value, disabled, onChange } = this.props;

    return (
      <TextField
        type={type}
        label={label}
        value={value}
        disabled={disabled}
        onChange={onChange}
        variant="outlined"
        className={classes.margin}
        InputLabelProps={{
          classes: {
            root: classes.label,
            focused: classes.focused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.outlinedInput,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
            disabled: classes.disabled,
          },
        }}
      />
    );
  };

  public render() {
    const { tooltip } = this.props;

    return (
      <div>
        {tooltip && tooltip !== '' && (
          <Tooltip title={tooltip} arrow={true}>
            {this.textField()}
          </Tooltip>
        )}
        {!tooltip && this.textField()}
      </div>
    );
  }
}

const StyleWrapped = withStyles(styles)(StyledTextFieldComponent);

export const StyledTextField = StyleWrapped;
