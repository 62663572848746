import { Grid, TextField, withStyles, WithStyles } from '@material-ui/core';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { ChangeEvent } from 'react';
import { LoadingMask } from 'src/Common/Components/LoadingMask';
import { FormStyles } from '../../Common/Styles/FormStyles';

const styles = FormStyles;

interface TemplateFieldFormState {
  loading: boolean;
  fields: Record<string, string>;
}

interface TemplateFieldFormProps extends WithStyles<typeof styles>, InjectedNotistackProps {
  fields: Record<string, string>;
  fieldChangeCallback: (fields: Record<string, string>) => void;
}

class TemplateFieldFormComponent extends React.Component<TemplateFieldFormProps, TemplateFieldFormState> {
  public state: TemplateFieldFormState = {
    loading: false,
    fields: {},
  };

  public componentDidMount(): void {
    const { fields } = this.props;
    this.setState({ fields });
  }

  public onChangeField = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const { fields } = this.state;
    const { fieldChangeCallback } = this.props;

    fields[name] = value;

    this.setState({ fields }, () => {
      fieldChangeCallback(fields);
    });
  };

  public fieldTextField(key: string): JSX.Element {
    const { classes } = this.props;
    const { fields } = this.state;

    return (
      <Grid item xs={'auto'} key={'field-' + key}>
        <TextField
          className={classes.margin}
          variant="outlined"
          id={`meta-panel-field-${key}`}
          name={key}
          label={key}
          value={fields[key] ?? ''}
          placeholder={key}
          onChange={this.onChangeField}
          autoFocus={false}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.outlinedInput,
              focused: classes.focused,
              notchedOutline: classes.notchedOutline,
            },
          }}
        />
      </Grid>
    );
  }

  public renderMetadataForm = (): JSX.Element => {
    const { fields } = this.props;

    const keys = Object.keys(fields);

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {keys.length > 0 && keys.map((key: string) => this.fieldTextField(key))}
      </Grid>
    );
  };

  public render() {
    const { loading } = this.state;

    return (
      <Grid container spacing={2} alignItems={'stretch'} justifyContent={'center'}>
        {loading && <LoadingMask />}

        <Grid item xs={12}>
          <Grid container spacing={2} alignItems={'stretch'}>
            <Grid item xs={12}>
              {this.renderMetadataForm()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<TemplateFieldFormProps>(TemplateFieldFormComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const TemplateFieldForm = StyleWrapped;
