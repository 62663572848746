import { EslManagerPrivateRoute, HttpMethod } from '@ekkogmbh/apisdk';
import { Button, Grid, TextField, withStyles, WithStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { ChangeEvent } from 'react';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises } from '../../Common/Helper/PromiseHelper';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { FormStyles } from '../../Common/Styles/FormStyles';

const styles = FormStyles;

const stores = ['api'];

interface ChangePasswordFormStores {
  api: ApiStore;
}

interface ChangePasswordFormState {
  newPassword: string;
  confirmNewPassword: string;
  allFilled: boolean;
}

interface ChangePasswordFormProps extends WithStyles<typeof styles>, InjectedNotistackProps {}

@inject(...stores)
@observer
class ChangePasswordFormComponent extends React.Component<ChangePasswordFormProps, ChangePasswordFormState> {
  public state: ChangePasswordFormState = {
    newPassword: '',
    confirmNewPassword: '',
    allFilled: false,
  };

  get stores(): ChangePasswordFormStores {
    return this.props as ChangePasswordFormProps & ChangePasswordFormStores;
  }

  private fetchPromises: CancelableFetchPromises = {};

  public handleChange = (fieldName: string) => async ({
    target: { value },
  }: ChangeEvent<{ name?: string; value: unknown }>) => {
    const stringValue = value as string;

    const setAllFilled = () => {
      const allFilled = this.state.newPassword !== '' && this.state.confirmNewPassword === this.state.newPassword;
      this.setState({ allFilled });
    };

    switch (fieldName) {
      case 'newPassword':
        this.setState({ newPassword: stringValue }, setAllFilled);
        break;
      case 'confirmNewPassword':
        this.setState({ confirmNewPassword: stringValue }, setAllFilled);
        break;
    }
  };

  public onReset = (): void => {
    this.setState({
      newPassword: '',
      confirmNewPassword: '',
      allFilled: false,
    });
  };

  public onSave = async (): Promise<void> => {
    const { api } = this.stores;
    const { enqueueSnackbar } = this.props;
    const { newPassword } = this.state;

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.updateCurrentUserPassword(newPassword),
      EslManagerPrivateRoute.LOGGEDIN_USERS_PASSWORD,
      HttpMethod.PUT,
      { 204: 'Password saved.' },
      this.onReset,
    );
  };

  public render() {
    const { classes } = this.props;
    const { newPassword, confirmNewPassword, allFilled } = this.state;

    const inputLabelProps = {
      classes: {
        root: classes.label,
        focused: classes.focused,
      },
    };

    const inputProps = {
      classes: {
        root: classes.outlinedInput,
        focused: classes.focused,
        notchedOutline: classes.notchedOutline,
        disabled: classes.disabled,
      },
    };

    const helperText = newPassword !== '' && !allFilled ? 'Does not match' : '';

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={6}>
          <Grid container direction={'column'} spacing={2} alignItems={'stretch'}>
            <Grid item xs={6}>
              <TextField
                label={'New Password'}
                value={newPassword}
                name={'newPassword'}
                onChange={this.handleChange('newPassword')}
                variant={'outlined'}
                type={'password'}
                className={classes.margin}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={'Confirm Password'}
                value={confirmNewPassword}
                name={'confirmNewPassword'}
                onChange={this.handleChange('confirmNewPassword')}
                variant={'outlined'}
                type={'password'}
                className={classes.margin}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
                helperText={helperText}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button variant={'contained'} color={'secondary'} onClick={this.onReset}>
                    Reset
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant={'outlined'} disabled={!allFilled} onClick={this.onSave}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<ChangePasswordFormProps>(ChangePasswordFormComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const ChangePasswordForm = StyleWrapped;
