import { Operation, OperationPayloadCompartmentUpdate, OperationTypeCompartmentUpdate } from '@ekkogmbh/apisdk';
import { Typography } from '@material-ui/core';
import { MaterialDatatableColumnDef } from 'material-datatable';
import React from 'react';
import { DatatableColumnDefinitionFn } from '../../Common/Components/DataTable';
import {
  OperationGroupDetailsContentPropsWithStores,
  OperationGroupDetailsContentState,
} from './OperationGroupDetailsContent';

const PayloadCompartmentImport = (payload: OperationPayloadCompartmentUpdate): React.ReactNode => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      overflowY: 'hidden',
      overflowX: 'auto',
      flexWrap: 'nowrap',
    }}
  >
    {Object.keys(payload.fields).map((key: string) => (
      <div
        key={key}
        style={{
          display: 'inline-block',
          width: 155,
          whiteSpace: 'normal',
          wordBreak: 'break-all',
        }}
      >
        <div style={{ margin: 8 }}>
          <Typography
            variant={'subtitle2'}
            color={'textSecondary'}
            style={{
              fontWeight: 700,
              wordBreak: 'keep-all',
            }}
          >
            {key}
          </Typography>
        </div>
        <div style={{ margin: 8 }}>
          <span>{payload.fields[key]}</span>
        </div>
      </div>
    ))}
  </div>
);

const OperationIdentifier = (): MaterialDatatableColumnDef => ({
  name: 'Coordinate',
  field: 'identifier',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: Operation): React.ReactNode => <div style={{ fontWeight: 700 }}>{value.identifier}</div>,
  },
});

const OperationType = (): MaterialDatatableColumnDef => ({
  name: 'Type',
  field: 'type',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: Operation): React.ReactNode => <div>{value.type}</div>,
  },
});

const OperationPayloadColumn = (): MaterialDatatableColumnDef => ({
  name: 'Payload',
  field: 'payload',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: Operation): React.ReactNode => {
      switch (value.type) {
        case OperationTypeCompartmentUpdate:
          return PayloadCompartmentImport(value.payload as OperationPayloadCompartmentUpdate);
        default:
          return <div />;
      }
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  OperationGroupDetailsContentPropsWithStores,
  OperationGroupDetailsContentState,
  null
>> = [OperationIdentifier, OperationType, OperationPayloadColumn];

export { PayloadCompartmentImport, OperationIdentifier, OperationType, OperationPayloadColumn };
