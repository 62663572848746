import { ChipProps } from '@material-ui/core/Chip';
import React, { Component, ComponentType } from 'react';

const ChipWrapper = (WrappedComponent: React.ComponentType<{ loading: number }>, loading: number): ComponentType =>
  class ChipWrapperComponent extends Component<ChipProps> {
    public render() {
      return <WrappedComponent loading={loading} {...this.props} />;
    }
  };

export default ChipWrapper;
