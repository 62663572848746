import { Checkbox, Theme, withStyles, WithStyles } from '@material-ui/core';
import { get, set } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { ChangeEvent, Component } from 'react';
import { RowSelectStore } from '../Stores/RowSelectStore';

const styles = (_: Theme) => ({
  root: {},
});

const stores = ['rowSelectStore'];

interface RowSelectState {
  checked: boolean;
}

interface RowSelectStores {
  rowSelectStore: RowSelectStore;
}

interface RowSelectProps extends WithStyles<typeof styles> {
  identifier: string;
}

@inject(...stores)
@observer
class RowSelectComponent extends Component<RowSelectProps, RowSelectState> {
  public state = {
    checked: false,
  };

  get stores(): RowSelectStores {
    return this.props as RowSelectStores & RowSelectProps;
  }

  public static getDerivedStateFromProps(
    // @TODO problems with mobx
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: any,
    state: RowSelectState,
  ): Partial<RowSelectState> | null {
    const checked: boolean | undefined = get(props.rowSelectStore.selectedRows, props.identifier);

    if (checked !== undefined && checked !== state.checked) {
      return { checked };
    }

    if (state.checked && checked === undefined) {
      return { checked: false };
    }

    return null;
  }

  public onChangeSelectedRow = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    const { identifier } = this.props;
    const { rowSelectStore } = this.stores;

    const entry = {};

    entry[identifier] = checked;

    set(rowSelectStore.selectedRows, entry);

    rowSelectStore.refreshRowsSelected();

    this.setState({ checked: get(rowSelectStore.selectedRows, identifier) });
  };

  public render() {
    const { checked } = this.state;
    const { identifier } = this.props;
    const { rowSelectStore } = this.stores;

    return (
      <Checkbox
        data-checked-in-store={get(rowSelectStore.selectedRows, identifier)}
        checked={checked}
        onChange={this.onChangeSelectedRow}
        value={identifier}
      />
    );
  }
}

const StyleWrapped = withStyles(styles)(RowSelectComponent);

export const RowSelect = StyleWrapped;
