import { Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import * as classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RowSelectStore } from '../Stores/RowSelectStore';

const fabWidth = 56;

const styles = (theme: Theme): Record<string, CSSProperties> => ({
  button: {
    flexGrow: 1,
    width: '100%',
  },
  buttonGrid: {
    position: 'absolute',
    right: (fabWidth + theme.spacing()) / 2,
    marginTop: theme.spacing() * -5,
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  } as CSSProperties,
  buttonGridWithDelete: {
    right: (fabWidth + theme.spacing()) / 2 + (fabWidth + theme.spacing()),
  },
  fab: {
    margin: theme.spacing(),
  },
  fabDelete: {
    position: 'absolute',
    right: (fabWidth + theme.spacing()) * -2,
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&:hover $flipBack': {
      backgroundColor: theme.palette.secondary.dark,
    },
  } as CSSProperties,
  fabDeleteIn: {
    right: (fabWidth + theme.spacing() * 2) * -1,
  } as CSSProperties,

  // flip effect
  flip: {
    width: 48,
    height: 24,
    perspective: 1000,
    '&:hover $flipInner': {
      transform: 'rotateY(180deg)',
    },
  } as CSSProperties,

  flipInner: {
    position: 'relative',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    transition: 'transform 0.6s',
    transformStyle: 'preserve-3d',
  } as CSSProperties,

  flipMain: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
  } as CSSProperties,

  flipBack: {
    fontSize: 14,
    fontWeight: 700,
    transform: 'rotateY(180deg)',
    backgroundColor: theme.palette.secondary.light,
  } as CSSProperties,
});

const stores = ['rowSelectStore'];

export interface ContentActionDefinition {
  name: string;
  icon: React.ComponentType<SvgIconProps>;
  hasPermission: boolean;
  toggleHandler: () => void;
  closeHandler: () => void;
  isHidden: boolean;
}

interface ContentActionsStores {
  rowSelectStore: RowSelectStore;
}

interface ContentActionsProps extends WithStyles<typeof styles> {
  onClick?: () => void; // BC
  onClickDrop?: () => void; // BC
  onClickExport?: () => void; // BC
  contentActions?: ContentActionDefinition[];
  onDelete?: () => void;
}

@inject(...stores)
@observer
class ContentActionsBase extends Component<ContentActionsProps> {
  get stores(): ContentActionsStores {
    return this.props as ContentActionsStores & ContentActionsProps;
  }

  public renderActions = () => {
    const { classes, contentActions } = this.props;

    if (contentActions === undefined) {
      return;
    }

    return contentActions.map(
      ({ name, icon: Icon, hasPermission, toggleHandler, isHidden }: ContentActionDefinition, index: number) => (
        <React.Fragment key={index}>
          {!isHidden && hasPermission && (
            <Tooltip key={index} title={name} aria-label={name} placement={'bottom'} enterDelay={500} leaveDelay={200}>
              <Fab color="primary" className={classes.fab} onClick={toggleHandler}>
                <Icon />
              </Fab>
            </Tooltip>
          )}
        </React.Fragment>
      ),
    );
  };

  public render() {
    const { classes, onClick, onClickDrop, onClickExport, onDelete, contentActions } = this.props;
    const {
      rowSelectStore: { selectedCount },
    } = this.stores;

    return (
      <Grid
        item
        xs={12}
        className={classNames(classes.buttonGrid, selectedCount && onDelete && classes.buttonGridWithDelete)}
        style={{ zIndex: 999 }}
      >
        {contentActions && this.renderActions()}

        {onClick && (
          <Tooltip title="Add" aria-label="Add" placement={'bottom'} enterDelay={500} leaveDelay={200}>
            <Fab color="primary" className={classes.fab} onClick={onClick}>
              <AddIcon />
            </Fab>
          </Tooltip>
        )}

        {onClickDrop && (
          <Tooltip title="Import" aria-label="Import" placement={'bottom'} enterDelay={500} leaveDelay={200}>
            <Fab color="primary" className={classes.fab} onClick={onClickDrop}>
              <CloudUploadIcon />
            </Fab>
          </Tooltip>
        )}

        {onClickExport && (
          <Tooltip title="Export" aria-label="Export" placement={'bottom'} enterDelay={500} leaveDelay={200}>
            <Fab color="primary" className={classes.fab} onClick={onClickExport}>
              <SaveAltIcon />
            </Fab>
          </Tooltip>
        )}

        {onDelete && (
          <Tooltip
            title="Delete selected Items"
            aria-label="Delete selected Items"
            placement={'bottom'}
            enterDelay={500}
            leaveDelay={200}
          >
            <Fab
              color="secondary"
              className={classNames(classes.fab, classes.fabDelete, selectedCount && classes.fabDeleteIn)}
              onClick={onDelete}
            >
              <div className={classes.flip}>
                <div className={classes.flipInner}>
                  <div className={classes.flipMain}>
                    <DeleteIcon />
                  </div>
                  <div className={classNames(classes.flipMain, classes.flipBack)}>{selectedCount}</div>
                </div>
              </div>
            </Fab>
          </Tooltip>
        )}
      </Grid>
    );
  }
}

export const ContentActions = withStyles(styles)(ContentActionsBase);
