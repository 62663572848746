import MomentUtils from '@date-io/moment';

export class OverrideMomentUtils extends MomentUtils {
  // public yearFormat = 'YYYY';
  // public yearMonthFormat = 'YYYY MMMM';
  // public dateTime12hFormat = 'YYYY-MM-DD hh:mm a';
  // public dateTime24hFormat = 'YYYY-MM-DD HH:mm';
  // public time12hFormat = 'hh:mm A';
  // public time24hFormat = 'HH:mm';
  // public dateFormat = 'YYYY-MM-DD';
  public yearFormat = 'YYYY';
  public yearMonthFormat = 'MMMM YYYY';
  public dateTime12hFormat = 'YYYY MMMM Do hh:mm a';
  public dateTime24hFormat = 'YYYY MMMM Do HH:mm';
  public time12hFormat = 'hh:mm A';
  public time24hFormat = 'HH:mm';
  public dateFormat = 'YYYY MMMM Do';
}
