import { Template } from '@ekkogmbh/apisdk';
import {
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { ChangeEvent, MouseEvent } from 'react';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { PresetStore } from '../Stores/PresetStore';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['api', 'presetStore'];

interface PresetFormStores {
  presetStore: PresetStore;
}

interface PresetFormState {
  loading: boolean;
  keyInput: string;
  templateSelect?: Template;
}

interface PresetFormProps extends WithStyles<typeof styles>, InjectedNotistackProps {
  templates: Template[];
}

@inject(...stores)
@observer
class PresetFormComponent extends React.Component<PresetFormProps, PresetFormState> {
  public state: PresetFormState = {
    loading: false,
    keyInput: '',
  };

  get stores(): PresetFormStores {
    return this.props as PresetFormProps & PresetFormStores;
  }

  public handleChange = (fieldName: string) => async ({
    target: { value },
  }: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { presetStore } = this.stores;

    switch (fieldName) {
      case 'coordinate':
        presetStore.setState({ coordinate: (value as string).trim() });
        break;
      case 'name':
        presetStore.setState({ name: (value as string).trim() });
        break;
      case 'key':
        this.setState({ keyInput: (value as string).trim() });
        break;
    }

    presetStore.changed = true;
  };

  public onSelectTemplate = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { templates } = this.props;
    const { presetStore } = this.stores;

    const templateId = event.target.value as number;
    const template = templates.find((candidate: Template) => candidate.id === templateId);

    this.setState({ templateSelect: template }, () => {
      if (template) {
        const { keys } = presetStore.state;
        const filteredKeys = template.metadata.keys.filter((candidate: string) => !keys.includes(candidate));
        presetStore.stagedKeys = filteredKeys;
      } else {
        presetStore.stagedKeys = [];
      }
    });
  };

  public onAddKey = (_: MouseEvent<HTMLDivElement>) => {
    const { keyInput } = this.state;
    const { presetStore } = this.stores;
    const { keys } = presetStore.state;

    if (keyInput.length > 0 && !keys.includes(keyInput)) {
      this.setState({ keyInput: '' }, () => {
        const newKeys = Array.from(keys);
        newKeys.push(keyInput);
        presetStore.setState({ keys: newKeys });
        presetStore.changed = true;
      });
    }
  };

  public onAddTemplateKeys = (_: MouseEvent<HTMLDivElement>) => {
    const { templateSelect } = this.state;
    const { presetStore } = this.stores;
    const { keys } = presetStore.state;
    const { stagedKeys } = presetStore;

    if (templateSelect) {
      this.setState({ templateSelect: undefined }, () => {
        const newKeys = keys.concat(stagedKeys);
        presetStore.setState({ keys: newKeys });
        presetStore.changed = true;
        presetStore.stagedKeys = [];
      });
    }
  };

  public onDeleteKey = (event: MouseEvent<HTMLDivElement>) => {
    const { presetStore } = this.stores;
    const { keys } = presetStore.state;
    const { fieldName } = event.currentTarget.dataset;

    const newKeys = Array.from(keys);
    newKeys.splice(parseInt(fieldName!), 1);
    presetStore.setState({ keys: newKeys });
    presetStore.changed = true;
  };

  public render() {
    const { loading, keyInput, templateSelect } = this.state;
    const { classes, templates } = this.props;
    const { presetStore } = this.stores;
    const { editablePreset } = presetStore;
    const { name, coordinate } = presetStore.state;

    const inputLabelProps = {
      classes: {
        root: classes.label,
        focused: classes.focused,
      },
    };

    const inputProps = {
      classes: {
        root: classes.outlinedInput,
        focused: classes.focused,
        notchedOutline: classes.notchedOutline,
        disabled: classes.disabled,
      },
    };

    return (
      <Fade in={true} timeout={fadeTimeout}>
        <Grid container item xs={6} spacing={2} alignItems={'stretch'}>
          {loading && <LoadingMask />}
          <Grid item xs={12}>
            <TextField
              label={'Name'}
              value={name}
              name={'name'}
              onChange={this.handleChange('name')}
              variant={'outlined'}
              className={classes.margin}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
              disabled={!!editablePreset}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Coordinate'}
              value={coordinate}
              name={'coordinate'}
              onChange={this.handleChange('coordinate')}
              variant="outlined"
              className={classes.margin}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
              disabled={!!editablePreset}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant={'overline'} color={'primary'} style={{ fontWeight: 700 }}>
              Add Keys
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={'Key'}
              value={keyInput}
              name={'key'}
              onChange={this.handleChange('key')}
              variant="outlined"
              className={classes.margin}
              InputLabelProps={inputLabelProps}
              InputProps={
                inputProps && {
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <IconButton onClick={this.onAddKey as never}>
                        <ArrowRight />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              }
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl variant={'outlined'} className={classes.formControlSelectAutoWidth}>
              <InputLabel id={'template-select-label'}>Template</InputLabel>
              <Select
                labelId={'template-select-label'}
                value={templateSelect ? templateSelect.id : ''}
                onChange={this.onSelectTemplate}
                input={<OutlinedInput label={'Template'} />}
                endAdornment={
                  <IconButton onClick={this.onAddTemplateKeys as never}>
                    <ArrowRight />
                  </IconButton>
                }
              >
                <MenuItem key={-1}>
                  <ListItemText primary={'None'} />
                </MenuItem>
                {templates.map((template: Template) => (
                  <MenuItem key={template.id} value={template.id}>
                    <ListItemText primary={template.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Fade>
    );
  }
}

const SnackbarWrapped = withSnackbar<PresetFormProps>(PresetFormComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const PresetForm = StyleWrapped;
