import { EslManagerPrivateRoute, HttpMethod, ImportCompartmentsDiff } from '@ekkogmbh/apisdk';
import { LinearProgress, Omit, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow/Grow';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import * as classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { ReactText } from 'react';
import { RouteComponentProps } from 'react-router';
import { request } from '../Helper/FetchHandler';
import { CancelableFetchPromises } from '../Helper/PromiseHelper';
import { TaskCollectionProgressCallback } from '../Stores/TaskCollectionStore';
import { ApiStore } from '../Stores/ApiStore';
import { ConfigStore } from '../Stores/ConfigStore';
import { CsvDropzoneStore } from '../Stores/CsvDropzoneStore';
import { CSVImportStepperStyles } from '../Styles/CSVImportStepperStyles';
import { CheckmarkSpinner } from './CheckmarkSpinner';
import { ConfirmationDialog } from './ConfirmationDialog';
import { DropzoneStep } from './CSVImportStepper/DropzoneStep';
import { ProcessingStep } from './CSVImportStepper/ProcessingStep';
import { UploadStep } from './CSVImportStepper/UploadStep';
import { Loading } from './Loading';

const styles = CSVImportStepperStyles;

export type ImportCallbacks = {
  success: () => void;
  error?: () => void;
};
export type ImportHandler = (
  callbacks: ImportCallbacks,
  csv: File,
  progressCallback?: TaskCollectionProgressCallback,
) => Promise<void>;

interface ImportStep {
  title: string;
  content: string;
}

interface CSVImportStepperProps extends WithStyles<typeof styles>, InjectedNotistackProps {
  closeHandler: () => void;
  importHandler: ImportHandler;
  steps: ImportStep[];
  api?: ApiStore;
  csvDropzoneStore?: CsvDropzoneStore;
  importFinishedHandler?: TaskCollectionProgressCallback;
  disableDiffModalOverride?: boolean;
}

interface CSVImportStepperState {
  activeStep: number;
  buttonsDisabled: boolean;
  error: boolean;
  loading: boolean;
  showDiffModal: boolean;
  compartmentUpdateCount: number | null;
}

interface CSVImportStepperStores {
  api: ApiStore;
  csvDropzoneStore: CsvDropzoneStore;
  configStore: ConfigStore;
}

const stores = ['api', 'csvDropzoneStore', 'configStore'];

@inject(...stores)
@observer
class CSVImportStepperComponent extends React.Component<CSVImportStepperProps, CSVImportStepperState> {
  public state = {
    activeStep: 0,
    buttonsDisabled: false,
    error: false,
    loading: false,
    showDiffModal: false,
    compartmentUpdateCount: null,
  };

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): CSVImportStepperStores {
    return this.props as CSVImportStepperProps & CSVImportStepperStores;
  }

  public componentWillUnmount() {
    const { csvDropzoneStore } = this.stores;

    csvDropzoneStore.reset();
  }

  public componentDidUpdate(prevProps: CSVImportStepperProps) {
    const { importHandler } = this.props;

    if (prevProps.importHandler !== importHandler) {
      this.handleReset();
    }
  }

  public getSteps() {
    const { steps } = this.props;

    return steps.map((step) => step.title);
  }

  public getStepContent(step: number) {
    const { steps } = this.props;

    if (steps[step] === undefined) {
      return 'Unknown step';
    }

    return steps[step].content;
  }

  public handleNext = () => {
    let { activeStep } = this.state;
    activeStep++;
    this.setState({ activeStep });
  };

  public handleReset = () => {
    const { csvDropzoneStore } = this.stores;
    const buttonsDisabled = false;

    csvDropzoneStore.reset();
    this.setState({
      activeStep: 0,
      buttonsDisabled,
      loading: false,
      showDiffModal: false,
      compartmentUpdateCount: null,
    });
  };

  public startImport = async () => {
    const { disableDiffModalOverride } = this.props;
    const { configStore } = this.stores;
    const importDiffModal = configStore.config?.importDiffModal;

    if (!disableDiffModalOverride && importDiffModal !== false && importDiffModal !== 0) {
      await this.showDiffModal();
    } else {
      await this.doImport();
    }
  };

  public showDiffModal = async () => {
    const { api, csvDropzoneStore } = this.stores;

    const file = csvDropzoneStore.getFile();

    if (!file) {
      return;
    }

    this.setState({ showDiffModal: true });

    const { enqueueSnackbar } = this.props;

    try {
      const importCompartmentsDiff = await request<ImportCompartmentsDiff>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.importCompartmentsDiff(file),
        EslManagerPrivateRoute.IMPORTER_COMPARTMENTS_DIFF,
        HttpMethod.POST,
      );

      const compartmentUpdateCount = importCompartmentsDiff.count;

      this.setState({ compartmentUpdateCount });
    } catch (e) {
      this.setState({
        showDiffModal: false,
        compartmentUpdateCount: null,
      });
    }
  };

  public closeDiffModal = async () => {
    this.setState({
      showDiffModal: false,
      compartmentUpdateCount: null,
    });
  };

  public doImport = async () => {
    const { importHandler } = this.props;
    const { csvDropzoneStore } = this.stores;

    const buttonsDisabled = true;
    const loading = true;

    this.setState({
      buttonsDisabled,
      loading,
      showDiffModal: false,
      compartmentUpdateCount: null,
    });

    await importHandler(
      {
        success: this.handleNext,
        error: this.handleReset,
      },
      csvDropzoneStore.file!,
      this.importProgressCallback,
    );
  };

  public importProgressCallback = (taskCollectionId: string, state: string, progress?: number) => {
    const { csvDropzoneStore } = this.stores;

    csvDropzoneStore.onSuccessfulImport(taskCollectionId, state, progress);

    if (progress === 100) {
      const { importFinishedHandler } = this.props;
      const loading = false;
      const buttonsDisabled = false;
      this.setState({
        buttonsDisabled,
        loading,
      });

      if (importFinishedHandler !== undefined) {
        importFinishedHandler(taskCollectionId, state, progress);
      }
    }
  };

  public handleClose = () => {
    const { csvDropzoneStore } = this.stores;
    const { closeHandler } = this.props;

    csvDropzoneStore.reset();
    closeHandler();
  };

  public getButtonForStep = (classes: ClassNameMap, step?: number): JSX.Element => {
    const { activeStep, buttonsDisabled } = this.state;

    const buttons: { text: string; props: { disabled: boolean; onClick?: () => void } }[] = [
      {
        text: 'Next',
        props: {
          disabled: true,
        },
      },
      {
        text: 'Import',
        props: {
          disabled: buttonsDisabled,
          onClick: this.startImport,
        },
      },
      {
        text: 'Close',
        props: {
          disabled: buttonsDisabled,
          onClick: this.handleClose,
        },
      },
    ];

    const button = buttons[step ? step : activeStep];

    return (
      <Button
        key={'csv-stepper-button-' + button.text}
        variant="contained"
        color="primary"
        {...button.props}
        className={classes.button}
      >
        {button.text}
      </Button>
    );
  };

  public autoConfirmTextFn = (autoConfirmSecondsLeft: number) => (
    <React.Fragment>
      The import starts automatically in <span style={{ fontWeight: 700 }}>{autoConfirmSecondsLeft}</span> seconds.
    </React.Fragment>
  );

  public render() {
    const { activeStep, buttonsDisabled, compartmentUpdateCount, showDiffModal, loading } = this.state;
    const { classes, disableDiffModalOverride } = this.props;
    const { configStore, csvDropzoneStore } = this.stores;
    const steps = this.getSteps();
    const growTimeout = 1500;

    const complete = csvDropzoneStore!.taskCollection && csvDropzoneStore!.taskCollection!.progress === 100;

    const hasFailedTasks =
      csvDropzoneStore!.taskCollection !== undefined && csvDropzoneStore!.taskCollection.state === 'failed';

    const currentStep = complete ? 3 : activeStep;

    const compartmentsString = compartmentUpdateCount === 1 ? 'Compartment' : 'Compartments';
    const zeroDiff = compartmentUpdateCount === 0 ? 'not ' : undefined;
    const actualCount = compartmentUpdateCount === 0 ? 'any' : compartmentUpdateCount;

    const boldSpanFn = (text?: ReactText) => (
      <span
        style={{
          fontSize: 24,
          fontWeight: 700,
          verticalAlign: 'middle',
        }}
      >
        {text}
      </span>
    );

    const importDialogText =
      compartmentUpdateCount !== null && actualCount ? (
        <div>
          This import will {boldSpanFn(zeroDiff)}insert/update {boldSpanFn(actualCount)} {compartmentsString}.
        </div>
      ) : (
        <Loading width={48} height={48} />
      );

    const importDiffModal = configStore.config?.importDiffModal;
    let autoConfirmTime;

    if (importDiffModal && importDiffModal > 0) {
      autoConfirmTime = importDiffModal;
    }

    return (
      <div className={classes.root}>
        {!disableDiffModalOverride && importDiffModal !== false && importDiffModal !== 0 && showDiffModal && (
          <ConfirmationDialog
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={showDiffModal}
            title={'Import Compartments'}
            text={importDialogText}
            onClose={this.closeDiffModal}
            onConfirm={this.doImport}
            okButtonDisabled={compartmentUpdateCount === null}
            autoConfirm={compartmentUpdateCount !== null && importDiffModal !== -1}
            autoConfirmTime={compartmentUpdateCount !== null ? (autoConfirmTime as number) : undefined}
            autoConfirmTextFn={this.autoConfirmTextFn}
          />
        )}
        <div className={classes.wrapper}>
          {currentStep === 0 && csvDropzoneStore!.file === undefined && (
            <Grow in={true} timeout={growTimeout}>
              <div>
                <DropzoneStep handleNext={this.handleNext} />
              </div>
            </Grow>
          )}
          {currentStep === 1 && csvDropzoneStore!.file && (
            <Grow in={true} timeout={growTimeout}>
              <div>
                <UploadStep loading={loading} />
              </div>
            </Grow>
          )}
          {currentStep === 2 && (
            <Grow in={true} timeout={growTimeout}>
              <div>
                <ProcessingStep />
              </div>
            </Grow>
          )}
          {currentStep === 3 && (
            <Grow in={true} timeout={growTimeout}>
              {hasFailedTasks ? (
                <div>
                  <CheckmarkSpinner complete={true} failure={true} />
                  <Typography className={classNames(classes.text)}>Import finished with failures.</Typography>
                  <LinearProgress style={{ height: 10 }} variant="determinate" value={100} />
                </div>
              ) : (
                <div>
                  <CheckmarkSpinner complete={true} failure={false} />
                  <Typography className={classNames(classes.text)}>Import successful.</Typography>
                  <LinearProgress style={{ height: 10 }} variant="determinate" value={100} />
                </div>
              )}
            </Grow>
          )}
        </div>

        <Stepper activeStep={currentStep}>
          {steps.map((label, _) => {
            const props = {};
            const labelProps = {};
            return (
              <Step key={label} {...props}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <div>
          {currentStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>All steps complete.</Typography>
              {this.getButtonForStep(classes, 2)}
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{this.getStepContent(currentStep)}</Typography>
              <div>
                <Button variant="contained" color="secondary" onClick={this.handleClose} className={classes.button}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={currentStep !== 1 || buttonsDisabled}
                  onClick={this.handleReset}
                  className={classes.button}
                >
                  Reset
                </Button>
                {this.getButtonForStep(classes)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const SnackbarWrapped = withSnackbar<Omit<CSVImportStepperProps, keyof RouteComponentProps>>(CSVImportStepperComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const CSVImportStepper = StyleWrapped;
