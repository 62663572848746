import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export interface ActionButton<T> {
  title: string;
  icon: React.ComponentType<SvgIconProps>;
  onClick: (dataset: T) => void;
  disabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface DataTableActionsProps<T = object> {
  dataset: T;
  isProcessing: boolean;
  actionButtons: ActionButton<T>[];
  width?: number | string;
}

export class DataTableActionsComponent<T> extends React.Component<DataTableActionsProps<T>> {
  public render(): JSX.Element {
    const { dataset, actionButtons, isProcessing, width } = this.props;

    const buttons = actionButtons.map((actionButton: ActionButton<T>, index: number) => {
      const { title, icon: Icon, disabled, onClick } = actionButton;

      return (
        <React.Fragment key={index}>
          <Tooltip title={title} placement="top" enterDelay={250} leaveDelay={100}>
            <div style={{ display: 'inline-block' }}>
              <IconButton
                disabled={disabled}
                color="secondary"
                onClick={!isProcessing ? () => onClick(dataset) : undefined}
              >
                {isProcessing ? (
                  <CircularProgress
                    color="secondary"
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                ) : (
                  <Icon />
                )}
              </IconButton>
            </div>
          </Tooltip>
        </React.Fragment>
      );
    });

    return (
      <div
        style={
          {
            width,
            textAlign: 'right',
          } as React.CSSProperties
        }
      >
        {buttons}
      </div>
    );
  }
}
