import { SystemStatusAppliance } from '@ekkogmbh/apisdk';
import { Grid, Omit, Paper, Theme, WithStyles, withStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React from 'react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { CancelablePromise, makePromiseCancelable } from '../../Common/Helper/PromiseHelper';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { SystemStatusBox } from './SystemStatusBox';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing() * 2,
    marginBottom: theme.spacing() * 4,
    paddingBottom: theme.spacing() * 4,
    position: 'relative',
  } as CSSProperties,
  button: {
    flexGrow: 1,
    margin: theme.spacing(),
  },
  dataTablePaper: {
    paddingTop: 15,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansion: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  expansionExpanded: {
    minHeight: theme.spacing() * 7,
  },
  chip: {
    margin: theme.spacing(),
    padding: theme.spacing(),
  },
  exportIndicator: {
    display: 'none',
  },
  exportFlexContainer: {
    display: 'block',
  },
});

interface SystemStatusContentStores {
  api: ApiStore;
}

const stores = ['api'];

interface SystemStatusContentProps extends WithStyles<typeof styles>, RouteComponentProps, InjectedNotistackProps {}

@inject(...stores)
@observer
class SystemStatusContentComponent extends Component<SystemStatusContentProps> {
  public state: {
    loading: boolean;
    statusAppliance?: SystemStatusAppliance[];
    // statusTasks?: SystemStatusTasks;
  } = {
    loading: false,
    // statusTasks: {
    //   createdTasks: 0,
    //   runningTasks: 0,
    //   finishedTasks: 0,
    //   failedTasks: 0,
    // },
    statusAppliance: [
      {
        status: 'OFFLINE',
        technology: {
          name: 'not-found',
          baseUrl: '',
        },
        information: {
          summaryOfLabels: {
            totalTagCount: 0,
            status: {
              successfulTagCount: 0,
              processingTagCount: 0,
              timeoutTagCount: 0,
            },
            batteryStatus: {
              goodBatteryTagCount: 0,
              badBatteryTagCount: 0,
            },
            signalStrength: {
              excellentSignalTagCount: 0,
              goodSignalTagCount: 0,
              badSignalTagCount: 0,
            },
          },
          summaryOfAccessPoints: {
            totalAccessPointCount: 0,
            connectedAccessPointCount: 0,
          },
        },
        tags: {
          factoryId: '-',
        },
      },
    ],
  };
  private fetchPromise?: CancelablePromise;
  private fetchInterval?: number;

  get stores(): SystemStatusContentStores {
    return this.props as SystemStatusContentProps & SystemStatusContentStores;
  }

  public componentDidMount(): void {
    this.setState({ loading: true }, this.fetchStatusInterval);
  }

  public componentWillUnmount(): void {
    if (this.fetchPromise && !this.fetchPromise.isResolved()) {
      this.fetchPromise.cancel();
    }

    if (this.fetchInterval !== undefined) {
      window.clearInterval(this.fetchInterval);
    }
  }

  public fetchStatusInterval = async (): Promise<void> => {
    // initial request
    this.fetchStatus();
    // polling
    this.fetchInterval = window.setInterval(this.fetchStatus, 30 * 1000);
  };

  public fetchStatus = async (): Promise<void> => {
    const { api } = this.stores;

    if (this.fetchPromise && !this.fetchPromise.isResolved()) {
      this.fetchPromise.cancel();
    }

    this.fetchPromise = makePromiseCancelable(api!.getSystemStatus());

    try {
      const status = await this.fetchPromise.promise;

      this.setState({
        loading: false,
        statusAppliance: status.serviceStatus as SystemStatusAppliance[],
        // statusTasks: status.taskStatus as SystemStatusTasks,
      });
    } catch (error) {
      if (error.isCanceled) {
        return;
      }
    }
  };

  public render() {
    const { classes } = this.props;

    const { loading, statusAppliance } = this.state;

    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {statusAppliance &&
            statusAppliance.map((appliance, index: number) => (
              <Grid key={index} item xs={12}>
                <Paper className={classNames(classes.root)}>
                  {loading && <LoadingMask width={75} height={75} />}
                  <SystemStatusBox appliance={appliance} />
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<SystemStatusContentProps, typeof SystemStatusContentComponent>(
  SystemStatusContentComponent,
);
const SnackbarWrapped = withSnackbar<Omit<SystemStatusContentProps, keyof RouteComponentProps>>(RouterWrapped);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const SystemStatusContent = StyleWrapped;
