import React from 'react';
import { Redirect, Route } from 'react-router-dom';

// @TODO
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// tslint:disable
// eslint-disable-next-line react/prop-types,@typescript-eslint/explicit-module-boundary-types
const PrivateRoute = ({ render, authed, hasPermission, ...rest }): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const redirectLogin = (props: any) => (
    <Redirect
      to={{
        pathname: '/login',
        // eslint-disable-next-line react/prop-types
        state: { from: props.location },
      }}
    />
  );

  const redirectRoot = () => (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  );

  const renderFn = authed === true ? (hasPermission ? render : redirectRoot) : redirectLogin;

  return <Route {...rest} render={renderFn} />;
};

export default PrivateRoute;
