import { WithStyles, withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import * as classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { CsvDropzoneStore } from '../../Stores/CsvDropzoneStore';
import { CSVImportStepperStyles } from '../../Styles/CSVImportStepperStyles';
import { CheckmarkSpinner } from '../CheckmarkSpinner';

interface ProcessingStepProps extends WithStyles<typeof CSVImportStepperStyles> {
  csvDropzoneStore?: CsvDropzoneStore;
}

@inject('csvDropzoneStore')
@observer
class ProcessingStepComponent extends React.Component<ProcessingStepProps> {
  public render() {
    const { classes, csvDropzoneStore } = this.props;

    return (
      <div>
        <CheckmarkSpinner
          complete={(csvDropzoneStore!.taskCollection && csvDropzoneStore!.taskCollection!.progress === 100) as boolean}
          failure={
            (csvDropzoneStore!.taskCollection && csvDropzoneStore!.taskCollection!.state === 'failed') as boolean
          }
        />
        <Typography className={classNames(classes.text)}>
          CSV successfully uploaded. Import Tasks created and waiting for processing.
        </Typography>
        {csvDropzoneStore!.taskCollection && (
          <LinearProgress
            style={{ height: 10 }}
            variant="determinate"
            value={csvDropzoneStore!.taskCollection!.progress}
          />
        )}
      </div>
    );
  }
}

export const ProcessingStep = withStyles(CSVImportStepperStyles)(ProcessingStepComponent);
