import {
  Grid,
  Fade,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
  ListItem,
  withStyles,
  IconButton,
} from '@material-ui/core';
import { Add, Delete, ExpandMore } from '@material-ui/icons';
import { WithStyles } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { MouseEvent } from 'react';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import { LoadingMask } from 'src/Common/Components/LoadingMask';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { PresetStore } from '../Stores/PresetStore';

const styles = FormStyles;
const fadeTimeout = 2000;

interface PresetPanelSortingState {
  items: string[];
}

interface PresetPanelSortingStores {
  presetStore: PresetStore;
}

interface PresetPanelSortingProps extends WithStyles<typeof styles>, InjectedNotistackProps {
  loading: boolean;
  sortableItems: string[];
}

@inject('presetStore')
@observer
class PresetPanelSortingComponent extends React.Component<PresetPanelSortingProps, PresetPanelSortingState> {
  public state: PresetPanelSortingState = {
    items: [],
  };

  get stores(): PresetPanelSortingStores {
    return this.props as PresetPanelSortingProps & PresetPanelSortingStores;
  }

  public static getDerivedStateFromProps(props: Readonly<PresetPanelSortingProps>, state: PresetPanelSortingState) {
    if (JSON.stringify(props.sortableItems) !== JSON.stringify(state.items)) {
      return {
        items: props.sortableItems,
      };
    }

    return null;
  }

  public onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const { presetStore } = this.stores;
    const { items } = this.state;

    presetStore.setState({ keys: arrayMove(items, oldIndex, newIndex) });
    presetStore.changed = true;
  };

  public onAddKey = (value: string) => {
    const { presetStore } = this.stores;
    const { keys } = presetStore.state;

    const newKeys = Array.from(keys);
    newKeys.push(value);
    presetStore.setState({ keys: newKeys });
    presetStore.changed = true;
  };

  public onDeleteKey = (event: MouseEvent<HTMLButtonElement>, value: string) => {
    event.stopPropagation();

    const { presetStore } = this.stores;
    const { keys } = presetStore.state;

    const newKeys = Array.from(keys);
    delete newKeys[keys.indexOf(value)];

    presetStore.setState({ keys: newKeys.filter(String) });
    presetStore.changed = true;
    console.log(value);
  };

  public render() {
    const { items } = this.state;
    const { classes, loading } = this.props;
    const { presetStore } = this.stores;
    const { stagedKeys } = presetStore;

    return (
      <Grid container spacing={2} alignItems={'stretch'} style={{ position: 'relative' }}>
        {loading && <LoadingMask width={42} height={42} />}

        <Grid item xs={12}>
          {
            <Fade in={true} timeout={fadeTimeout}>
              <div
                style={{
                  margin: 8,
                  padding: 0,
                  borderStyle: 'solid',
                  borderRadius: 4,
                  borderWidth: 1,
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                }}
              >
                <Accordion
                  style={{
                    padding: 0,
                    minHeight: 54,
                  }}
                  elevation={0}
                  defaultExpanded={true}
                >
                  <AccordionSummary
                    style={{ minHeight: 46 }}
                    classes={{
                      content: classes.AccordionSummaryContent,
                      expandIcon: classes.AccordionExpandIcon,
                    }}
                    expandIcon={<ExpandMore />}
                  >
                    <Typography color={'secondary'}>Key-Order</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0 }}>
                    <SortableList
                      lockAxis={'y'}
                      distance={5}
                      items={items}
                      stagedItems={stagedKeys}
                      onSortEnd={this.onSortEnd}
                      onDelete={this.onDeleteKey as never}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            </Fade>
          }
        </Grid>
      </Grid>
    );
  }
}

export const SortableItem = SortableElement(
  ({
    value,
    sortIndex,
    onDelete,
  }: {
    value: string;
    sortIndex: number;
    onDelete: (event: MouseEvent, value: string) => void;
  }) => (
    <ListItem
      style={{
        borderTopStyle: 'inset',
        borderTopColor: 'rgba(0, 0, 0, 0.23)',
        borderTopWidth: 1,
        backgroundColor: 'white',
        userSelect: 'none',
      }}
    >
      <Grid container justifyContent={'space-between'} alignItems={'baseline'}>
        <Grid item>
          <Typography>
            <span>{sortIndex + 1}.&nbsp;</span>
            <span>{value}</span>
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={(e: MouseEvent) => onDelete(e, value)}>
            <Delete fontSize={'small'} />
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  ),
);

export const SortableList = SortableContainer(
  ({
    items,
    stagedItems,
    onDelete,
  }: {
    items: string[];
    stagedItems: string[];
    onDelete: (event: MouseEvent, value: string) => void;
  }) => (
    <List style={{ padding: 4, width: '100%' }}>
      {items.map((value: string, index: number) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          sortIndex={index}
          value={value}
          onDelete={onDelete as never}
        />
      ))}
      {stagedItems.map((value: string, index: number) => (
        <ListItem
          key={`stagedItem-${index}`}
          style={{
            borderTopStyle: 'inset',
            borderTopColor: 'rgba(0, 0, 0, 0.23)',
            borderTopWidth: 1,
            backgroundColor: 'white',
          }}
        >
          <Add color={'action'} />
          <Typography>
            <span>{value}</span>
          </Typography>
        </ListItem>
      ))}
    </List>
  ),
);

const SnackbarWrapped = withSnackbar<PresetPanelSortingProps>(PresetPanelSortingComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const PresetPanelSorting = StyleWrapped;
