import { Pagination, PaginationResponse } from '@ekkogmbh/apisdk';
import { Theme, WithStyles, withStyles } from '@material-ui/core';
import MaterialDatatable, {
  MaterialDatatableColumn,
  MaterialDatatableColumnDef,
  MaterialDatatableOptions,
  MaterialDatatableState,
} from 'material-datatable';
import { inject } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { ReactNode } from 'react';
import { CancelablePromise, makePromiseCancelable } from '../Helper/PromiseHelper';
import { DataTableData, DataTableRecord, DataTableStore } from '../Stores/DataTableStore';
import { PaginationStore } from '../Stores/PaginationStore';
import { RowSelectStore } from '../Stores/RowSelectStore';
import { SearchContentStore, ST_PLACEHOLDER } from '../Stores/SearchContentStore';
import { DataTableFooter } from './DataTableFooter';
import { DataTableLoadingMask } from './DateTableLoadingMask';

// @TODO magic! any = any component / datatype e.g. Compartment, Link
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataTableFilterFields<T = Pick<any, string>> = Partial<Array<keyof T>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataTableSortFieldMap<T = any> = Partial<Record<keyof T, string>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataTableActionHandler<T> = (subject: T, ...args: any[]) => Promise<void>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DatatableColumnDefinitionFn<P, S, T, H = any> = (
  state: S,
  props: P,
  actions: T,
  helpers?: H,
) => MaterialDatatableColumnDef;

export interface TableMeta {
  rowIndex: number;
  columnIndex: number;
  columnData: { width: number; field: string };
  rowData: DataTableRecord;
  tableData: DataTableData;
  tableState: {
    announceText: null | string;
    page: number;
    rowsPerPage: number;
    filterList: Record<string, string>;
    selectedRows: {
      data: DataTableData;
      // eslint-disable-next-line @typescript-eslint/ban-types
      lookup: object;
    };
    showResponsive: boolean;
    searchText: null | string;
  };
}

interface DataTableState {
  page: number;
  count: number;
  rowsPerPage: number;
  data: DataTableData;
  sortColumnIndex: number;
  sortColumnDirection: string;
}

interface DataTableProps extends WithStyles<typeof styles>, InjectedNotistackProps {
  fetchItems: (pagination: Pagination) => Promise<PaginationResponse<DataTableRecord>>;
  columns: MaterialDatatableColumnDef[];
  filterFields: DataTableFilterFields;
  sortFieldMap: DataTableSortFieldMap;
  options?: MaterialDatatableOptions;
  disableFooter?: boolean;
  footerHeight?: number | string;
}

interface DataTableStores {
  dataTableStore: DataTableStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  rowSelectStore: RowSelectStore;
}

const stores: Array<keyof DataTableStores> = [
  'dataTableStore',
  'paginationStore',
  'searchContentStore',
  'rowSelectStore',
];

const styles = (theme: Theme) => ({
  footerRow: {
    height: theme.spacing() * 7,
  },
});

@inject(...stores)
class DataTableComponent extends React.PureComponent<DataTableProps, DataTableState> {
  public state: DataTableState;
  private readonly ref: React.RefObject<HTMLTableElement>;
  private readonly defaultOptions: MaterialDatatableOptions;
  private fetchPromise?: CancelablePromise;

  constructor(props: DataTableProps) {
    super(props);

    this.ref = React.createRef();

    const { options, disableFooter } = this.props;

    const rowsPerPage = options ? options!.rowsPerPage || 100 : 100;
    const sortColumnIndex = options ? options!.sortColumnIndex || 0 : 0;
    const sortColumnDirection = options ? options!.sortColumnDirection || 'asc' : 'asc';

    this.defaultOptions = {
      rowsPerPage,
      rowsPerPageOptions: [10, 50, 100, 250, 500],
      serverSide: true,
      filter: false,
      viewColumns: false,
      search: false,
      print: false,
      download: false,
      selectableRows: false,
      usePaperPlaceholder: false,
      filterType: 'multiselect',
      responsive: 'scroll',
      componentWillReceiveProps: true,
      sortColumnIndex,
      sortColumnDirection,
      onTableChange: this.onTableChange,
      pagination: true,
      customFooter: disableFooter ? (this.disabledFooter as () => ReactNode) : (this.customFooter as () => ReactNode),
    };

    this.state = {
      page: 0,
      count: 0,
      rowsPerPage,
      data: [],
      sortColumnIndex,
      sortColumnDirection,
    };
  }

  get stores(): DataTableStores {
    return this.props as DataTableProps & DataTableStores;
  }

  public componentDidMount(): void {
    const { searchContentStore } = this.stores;

    searchContentStore.off('searchText');
    searchContentStore.off('refresh');
    searchContentStore.start();
    searchContentStore.on('searchText', this.fetchByState(true));
    searchContentStore.on('refresh', this.fetchByState());

    this.fetchByState(true)();
  }

  public componentDidUpdate(): void {
    const { paginationStore } = this.stores;

    paginationStore.pageReceived();
  }

  public componentWillUnmount(): void {
    const { searchContentStore } = this.stores;

    searchContentStore.off('searchText');
    searchContentStore.off('refresh');
    searchContentStore.end();

    if (this.fetchPromise && !this.fetchPromise.isResolved()) {
      this.fetchPromise.cancel();
    }
  }

  public customFooter = (
    rowCount: number,
    page: number,
    rowsPerPage: number,
    changeRowsPerPage: (value: number) => void,
    changePage: (page: number) => void,
  ): ReactNode => {
    const { options, footerHeight } = this.props;
    const { count, sortColumnIndex, sortColumnDirection } = this.state;

    const mergedOptions = {
      ...this.defaultOptions,
      ...options,
      page,
      count,
      rowsPerPage,
      sortColumnIndex,
      sortColumnDirection,
    };

    return (
      <DataTableFooter
        tableRef={this.ref}
        rowCount={rowCount}
        page={page}
        rowsPerPage={rowsPerPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={changePage}
        options={mergedOptions}
        height={footerHeight}
      />
    );
  };

  public disabledFooter = (): ReactNode => (
    <tfoot>
      <tr
        className={this.props.classes.footerRow}
        style={this.props.footerHeight !== undefined ? { height: this.props.footerHeight } : {}}
      >
        <td>{/**/}</td>
      </tr>
    </tfoot>
  );

  public fetchByState = (resetPage: boolean = false) => async (): Promise<void> => {
    const { page, rowsPerPage, sortColumnIndex, sortColumnDirection } = this.state;
    const { columns, sortFieldMap } = this.props;

    const sortColumn = columns[sortColumnIndex] as MaterialDatatableColumn;
    const sort =
      sortFieldMap && sortFieldMap.hasOwnProperty(sortColumn.field) ? sortFieldMap[sortColumn.field] : sortColumn.field;

    await this.fetchData(resetPage ? 0 : page, rowsPerPage, sort, sortColumnDirection);
  };

  public render() {
    const { columns, options } = this.props;
    const { data, page, count, rowsPerPage, sortColumnIndex, sortColumnDirection } = this.state;

    const viewableData = data.map((value) => value);

    const mergedOptions = {
      ...this.defaultOptions,
      ...options,
      page,
      count,
      rowsPerPage,
      sortColumnIndex,
      sortColumnDirection,
    };

    return (
      <div style={{ position: 'relative' }} ref={this.ref}>
        <DataTableLoadingMask width={75} height={75} topPercent={viewableData.length > 10 ? 5 : 50} />
        <MaterialDatatable title={''} data={viewableData} columns={columns} options={mergedOptions} />
      </div>
    );
  }

  private onTableChange = async (action: string, tableState: MaterialDatatableState) => {
    switch (action) {
      case 'changePage':
      case 'changeRowsPerPage':
        await this.sortAwareFetchData(tableState);
        break;

      case 'sort':
        await this.sortAwareFetchData(tableState, true);
        break;
    }
  };

  private sortAwareFetchData = async (tableState: MaterialDatatableState, sortFromTableState: boolean = false) => {
    const { columns, sortFieldMap } = this.props;
    const { sortColumnIndex, sortColumnDirection } = sortFromTableState ? tableState : this.state;
    const sortColumn = columns[sortColumnIndex] as MaterialDatatableColumn;
    const sort =
      sortFieldMap && sortFieldMap.hasOwnProperty(sortColumn.field) ? sortFieldMap[sortColumn.field] : sortColumn.field;

    if (sortFromTableState) {
      this.setState({
        sortColumnIndex,
        sortColumnDirection,
      });
    }

    await this.fetchData(tableState.page, tableState.rowsPerPage, sort, sortColumnDirection);
  };

  private fetchData = async (page: number, limit: number, sort?: string, direction?: string) => {
    const { dataTableStore, paginationStore, searchContentStore, rowSelectStore } = this.stores;
    const { fetchItems, filterFields } = this.props;

    let { count } = this.state;

    dataTableStore.setLoading(true);

    if (this.fetchPromise && !this.fetchPromise.isResolved()) {
      this.fetchPromise.cancel();
    }

    let filter;

    if (searchContentStore!.searchText) {
      filter = filterFields.reduce((acc, curr): Record<string, string> => {
        if (curr !== undefined) {
          acc[curr] = searchContentStore!.searchText;
        }
        return acc;
      }, {});

      if (searchContentStore!.searchTextFieldOverride !== null) {
        const override = searchContentStore!.searchTextFieldOverride;
        filter[override.field] = override.pattern.split(ST_PLACEHOLDER).join(searchContentStore!.searchText);
      }
    }

    const pagination = {
      page: page + 1,
      limit,
      filter,
      sort,
      direction,
    } as Pagination;

    this.fetchPromise = makePromiseCancelable(fetchItems(pagination));

    try {
      const result = await this.fetchPromise.promise;

      if (result.items) {
        const data = [];

        for (const item of result.items) {
          data.push(item);
        }

        if (result.totalItemCount) {
          count = result.totalItemCount;
        }

        this.setState({
          data,
          page,
          count,
          rowsPerPage: limit,
        });
        dataTableStore.data = data;
        dataTableStore.setLoading(false);
      } else {
        dataTableStore.setLoading(false);
      }

      rowSelectStore.clear();

      if (JSON.stringify(pagination) !== JSON.stringify(paginationStore!.pagination)) {
        paginationStore!.setPagination(pagination);
      }
    } catch (error) {
      if (error.isCanceled) {
        return;
      }

      dataTableStore.setLoading(false);
    }
  };
}

const SnackbarWrapped = withSnackbar<DataTableProps>(DataTableComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const DataTable = StyleWrapped;
