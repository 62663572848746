import { WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { CSVImportStepperStyles } from '../../Styles/CSVImportStepperStyles';
import { CSVDropzone } from '../CSVDropzone';

interface DropzoneStepProps extends WithStyles<typeof CSVImportStepperStyles> {
  handleNext: () => void;
}

class DropzoneStepComponent extends React.Component<DropzoneStepProps> {
  public render() {
    const { handleNext } = this.props;

    return <CSVDropzone onDrop={handleNext} />;
  }
}

export const DropzoneStep = withStyles(CSVImportStepperStyles)(DropzoneStepComponent);
