import { List, ListItem, Typography } from '@material-ui/core';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { SortableSpatialEntity } from '../Stores/PickingSideStore';

export const SortableItem = SortableElement(
  ({ value, sortIndex }: { value: { key: string; keyValue: string; identifierValue: string }; sortIndex: number }) => (
    <ListItem
      style={{
        borderTopStyle: 'inset',
        borderTopColor: 'rgba(0, 0, 0, 0.23)',
        borderTopWidth: 1,
        backgroundColor: 'white',
      }}
    >
      <Typography variant={'subtitle1'} color={'secondary'}>
        <span
          style={{
            display: 'inline-block',
            minWidth: 40,
          }}
        >
          {sortIndex + 1}.&nbsp;
        </span>
        <span
          style={{
            display: 'inline-block',
            minWidth: 160,
          }}
        >
          <span style={{ fontWeight: 700 }}>{value.key}</span>:{value.keyValue}&nbsp;
        </span>
        <span style={{ fontWeight: 700 }}>{value.identifierValue}</span>
      </Typography>
    </ListItem>
  ),
);

export const SortableList = SortableContainer(({ items }: { items: SortableSpatialEntity[] }) => (
  <List
    style={{
      padding: 4,
      width: '100%',
    }}
  >
    {items.map((value: SortableSpatialEntity, index: number) => (
      <SortableItem key={`item-${index}`} index={index} sortIndex={index} value={value} />
    ))}
  </List>
));
