import { action, observable } from 'mobx';

export class ContentTitleStore {
  @observable
  public contentTitle?: string | JSX.Element;

  @action
  public setContentTitle(contentTitle: string | JSX.Element | undefined): void {
    this.contentTitle = contentTitle;
  }
}
