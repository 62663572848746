import { RendererPayload, RendererResult, TemplateWithData } from '@ekkogmbh/apisdk';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import React from 'react';
import { LoadingMask } from 'src/Common/Components/LoadingMask';
import { TemplateFieldForm } from './TemplateFieldForm';
import { TemplatePreview } from './TemplatePreview';

const styles = (theme: Theme) => ({
  button: {
    margin: theme.spacing(),
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

interface TemplatePreviewDialogProps extends WithStyles<typeof styles> {
  template: TemplateWithData;
  onClose: () => void;
  fetchRendererResult: (payload: RendererPayload) => Promise<RendererResult>;
  dialogProps: DialogProps;
}

interface TemplatePreviewDialogState {
  loading: boolean;
  rendering?: RendererResult;
  fields: Record<string, string>;
  fieldsChanged: boolean;
}

class TemplatePreviewDialogComponent extends React.Component<TemplatePreviewDialogProps, TemplatePreviewDialogState> {
  public state: TemplatePreviewDialogState = {
    loading: true,
    rendering: undefined,
    fields: {},
    fieldsChanged: true,
  };

  private renderTimeout?: number;

  public renderTemplateTimeout = () => {
    this.renderTimeout = window.setTimeout(this.onRefresh, 500);
  };

  public componentDidMount() {
    this.setState({ loading: true }, () => {
      this.onRefresh();
    });
  }

  public componentWillUnmount() {
    window.clearTimeout(this.renderTimeout);
  }

  public onFieldChange = (fields: Record<string, string>) => {
    window.clearTimeout(this.renderTimeout);

    this.setState({ fields, fieldsChanged: true }, () => {
      this.renderTemplateTimeout();
    });
  };

  public onRefresh = async () => {
    const { fetchRendererResult, template } = this.props;
    const { data, type } = template;
    const { fields, fieldsChanged } = this.state;

    if (!fieldsChanged) {
      return;
    }

    const rendering = await fetchRendererResult({
      data,
      type,
      fields,
      autoFill: true,
      render: true,
    });

    this.setState({ rendering, loading: false, fieldsChanged: false });
  };

  public render() {
    const { template, onClose, dialogProps } = this.props;
    const { rendering, loading } = this.state;

    return (
      <Dialog onClose={onClose} {...dialogProps}>
        <DialogTitle id="template-preview-title">{template.name}</DialogTitle>
        <DialogContent>
          {loading && <LoadingMask />}
          {rendering && (
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid item xs={'auto'} zeroMinWidth>
                <TemplatePreview rendererResult={rendering} />
              </Grid>
              <Grid item xs={12}>
                <TemplateFieldForm fields={rendering.fields} fieldChangeCallback={this.onFieldChange} />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant={'contained'} color={'primary'} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const TemplatePreviewDialog = withStyles(styles)(TemplatePreviewDialogComponent);
