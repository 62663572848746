import { User, UserCreatePayload, UserUpdatePayload } from '@ekkogmbh/apisdk';
import { Fade, Grid, Hidden, withStyles, WithStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField/TextField';
import { InlineDatePicker } from 'material-ui-pickers';
import moment, { Moment } from 'moment';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { ChangeEvent, ClipboardEvent } from 'react';
import { ConfirmationDialog } from '../../Common/Components/ConfirmationDialog';
import { FormPanelButtons, PanelAction } from '../../Common/Components/FormPanelButtons';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { FormStyles } from '../../Common/Styles/FormStyles';

const styles = FormStyles;
const fadeTimeout = 2000;

interface UserPanelState {
  action: PanelAction;
  username: string;
  email: string;
  validUntil: string;
  password: string;
  passwordConfirm: string;
  emailError: boolean;
  changed: boolean;
  loading: boolean;
  deleteDialogOpen: boolean;
  allFilled: boolean;
}

interface UserPanelProps extends WithStyles<typeof styles>, InjectedNotistackProps {
  user?: User;
  closeHandler: () => void;
  saveHandler: (user: UserUpdatePayload | UserCreatePayload, password?: string) => Promise<void>;
  deleteHandler: (user: User) => Promise<void>;
  passwordEditable?: boolean;
}

class UserPanelComponent extends React.PureComponent<UserPanelProps, UserPanelState> {
  public state: UserPanelState = {
    action: PanelAction.CREATE,
    username: '',
    email: '',
    validUntil: '',
    password: '',
    passwordConfirm: '',
    emailError: false,
    changed: false,
    loading: false,
    deleteDialogOpen: false,
    allFilled: false,
  };

  constructor(props: UserPanelProps) {
    super(props);

    const { user } = this.props;

    if (user !== undefined) {
      const action = PanelAction.EDIT;
      const { username, email } = user;

      const validUntil = moment(user.validUntil).format('YYYY-MM-DD');

      this.state = {
        ...this.state,
        action,
        username,
        email,
        validUntil,
      };
    }
  }

  // @TODO deprecated, maybe refactor to getDerivedStateFromProps
  // eslint-disable-next-line react/no-deprecated
  public componentWillReceiveProps(nextProps: Readonly<UserPanelProps>): void {
    const { user } = nextProps;
    const { user: currentUser } = this.props;

    const nextUser = JSON.stringify(user);
    const currUser = JSON.stringify(currentUser);

    if (nextUser !== currUser) {
      this.setState({
        username: '',
        email: '',
        validUntil: '',
        password: '',
        passwordConfirm: '',
      });

      if (user) {
        const { username, email } = user;

        const validUntil = moment(user.validUntil).format('YYYY-MM-DD');

        this.setState({
          action: PanelAction.EDIT,
          username,
          email,
          validUntil,
          password: '',
          passwordConfirm: '',
        });
      }
    }
  }

  public resetState = () => {
    const { user } = this.props;

    if (user) {
      const { username, email } = user;

      const validUntil = moment(user.validUntil).format('YYYY-MM-DD');

      this.setState({
        username,
        email,
        validUntil,
        password: '',
        passwordConfirm: '',
        changed: false,
      });
    } else {
      this.setState({
        username: '',
        email: '',
        validUntil: '',
        password: '',
        passwordConfirm: '',
        changed: false,
      });
    }
  };

  public onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.persist();
    // @TODO ¯\_(ツ)_/¯
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setTimeout(() => this.onChange(event as any), 500);
  };

  public onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const state = { changed: true };

    state[name] = value;

    let emailError = false;

    if (name === 'email') {
      emailError = !this.validateEmail(value);
    }

    this.setState(
      {
        ...state,
        emailError,
      },
      () => this.setState({ allFilled: this.isAllFilled() }),
    );
  };

  public handleChangeValidUntil = (date: Moment) => {
    const changed = true;
    const validUntil = date.format('YYYY-MM-DD');

    this.setState(
      {
        validUntil,
        changed,
      },
      () => this.setState({ allFilled: this.isAllFilled() }),
    );
  };

  public validateEmail = (email: string): boolean => {
    // @TODO not RFC-822 compliant
    const re = /.+@.+/i;
    return re.test(email);
  };

  public isAllFilled = (): boolean => {
    const { username, email, emailError, validUntil } = this.state;

    return username !== '' && email !== '' && validUntil !== '' && !emailError && this.isPasswordFieldValid();
  };

  public isPasswordFieldValid = (): boolean => {
    const { passwordEditable } = this.props;
    const { action, password, passwordConfirm } = this.state;

    if (!passwordEditable) {
      return true;
    }

    if (action === PanelAction.EDIT && password === '') {
      return true;
    }

    return password !== '' && password === passwordConfirm;
  };

  public onSave = async () => {
    const { user, closeHandler, saveHandler, enqueueSnackbar } = this.props;
    const { username, email, validUntil, password } = this.state;

    this.setState({ loading: true });

    try {
      if (user !== undefined) {
        const { id } = user;
        const saveableUser: UserUpdatePayload = {
          id,
          email,
          validUntil,
        };

        await saveHandler(saveableUser, password !== '' ? password : undefined);
      } else {
        const saveableUser: UserCreatePayload = {
          username,
          email,
          validUntil,
        };

        await saveHandler(saveableUser, password !== '' ? password : undefined);
      }

      this.setState({ loading: false });
      closeHandler();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
      this.setState({ loading: false });
    }
  };

  public onCancel = () => {
    const { closeHandler } = this.props;
    this.resetState();
    closeHandler();
  };

  public onDelete = () => {
    this.setState({ deleteDialogOpen: true });
  };

  public onDeleteDismiss = () => {
    this.setState({ deleteDialogOpen: false });
  };

  public onDeleteOk = async () => {
    const { user, closeHandler, deleteHandler } = this.props;

    this.setState({
      loading: true,
      deleteDialogOpen: false,
    });

    if (!user) {
      this.setState({ loading: false }, () => closeHandler());

      return;
    }

    await deleteHandler(user);

    this.setState({ loading: false }, () => closeHandler());
  };

  public render() {
    const { classes, user, passwordEditable } = this.props;

    const {
      action,
      username,
      email,
      validUntil,
      password,
      passwordConfirm,
      emailError,
      changed,
      loading,
      deleteDialogOpen,
      allFilled,
    } = this.state;

    const deleteDialogText = user ? `Delete User ${user.username}?` : '';

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {action === PanelAction.EDIT && (
          <ConfirmationDialog
            open={deleteDialogOpen}
            title={'Delete User'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}

        {loading && <LoadingMask />}

        <Grid item lg={4} md={6} xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <TextField
              label={'Username'}
              value={username}
              name={'username'}
              onChange={this.onChange}
              variant="outlined"
              className={classes.margin}
              disabled={user !== undefined && user.id !== undefined}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  focused: classes.focused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                  disabled: classes.disabled,
                },
              }}
            />
          </Fade>
        </Grid>

        <Hidden smDown>
          <Grid item xs={6}>
            {}
          </Grid>
        </Hidden>

        <Grid item lg={4} md={6} xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <TextField
              label={'E-Mail'}
              value={email}
              name={'email'}
              type={'email'}
              error={emailError}
              onChange={this.onChange}
              variant="outlined"
              className={classes.margin}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  focused: classes.focused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                  disabled: classes.disabled,
                  error: classes.outlinedInputError,
                },
              }}
            />
          </Fade>
        </Grid>

        <Hidden smDown>
          <Grid item xs={6}>
            {}
          </Grid>
        </Hidden>

        <Grid item lg={4} md={6} xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <InlineDatePicker
              className={classes.margin}
              label="Valid-Until"
              autoOk
              disablePast
              value={validUntil === '' ? moment() : moment(validUntil)}
              onChange={this.handleChangeValidUntil}
              variant={'outlined'}
            />
          </Fade>
        </Grid>

        <Hidden smDown>
          <Grid item xs={6}>
            {}
          </Grid>
        </Hidden>

        {passwordEditable && (
          <React.Fragment>
            <Hidden smDown>
              <Grid item xs={12}>
                {}
              </Grid>
            </Hidden>

            <Grid item lg={4} md={6} xs={12}>
              <Fade in={true} timeout={fadeTimeout}>
                <TextField
                  label={'Password'}
                  value={password}
                  name={'password'}
                  type={'password'}
                  onChange={this.onChange}
                  variant="outlined"
                  className={classes.margin}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                      disabled: classes.disabled,
                      error: classes.outlinedInputError,
                    },
                  }}
                />
              </Fade>
            </Grid>

            <Hidden smDown>
              <Grid item xs={6}>
                {}
              </Grid>
            </Hidden>

            <Grid item lg={4} md={6} xs={12}>
              <Fade in={true} timeout={fadeTimeout}>
                <TextField
                  label={'Password-Confirm'}
                  value={passwordConfirm}
                  name={'passwordConfirm'}
                  type={'password'}
                  error={password !== passwordConfirm}
                  onChange={this.onChange}
                  variant="outlined"
                  className={classes.margin}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                      disabled: classes.disabled,
                      error: classes.outlinedInputError,
                    },
                  }}
                />
              </Fade>
            </Grid>

            <Hidden smDown>
              <Grid item xs={6}>
                {}
              </Grid>
            </Hidden>
          </React.Fragment>
        )}

        <FormPanelButtons
          cancelHandler={this.onCancel}
          resetHandler={this.resetState}
          saveHandler={this.onSave}
          deleteHandler={this.onDelete}
          isResetDisabled={!changed}
          isSaveDisabled={!changed || !allFilled}
          isDeleteDisabled={action !== PanelAction.EDIT || !user}
          isDeleteHidden={action !== PanelAction.EDIT || !user}
        />
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<UserPanelProps>(UserPanelComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const UserPanel = StyleWrapped;
