import { RendererResult } from '@ekkogmbh/apisdk';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Error, ExpandLess, ExpandMore, Warning } from '@material-ui/icons';
import React from 'react';

interface TemplatePreviewState {
  extended: boolean;
}

interface TemplatePreviewProps {
  rendererResult: RendererResult;
  extendOnMount?: boolean;
}

class TemplatePreviewComponent extends React.Component<TemplatePreviewProps, TemplatePreviewState> {
  public state: TemplatePreviewState = {
    extended: false,
  };

  public toggleExtended = (): void => {
    this.setState({ extended: !this.state.extended });
  };

  public tryAndExtractJsonFromErrorMessage = (message: string): string => {
    const openBracketIndex = message.indexOf('{');
    const closeBracketIndex = message.lastIndexOf('}');

    if (openBracketIndex < 0 || closeBracketIndex < 0) {
      return message;
    }

    const candidate = message.substring(openBracketIndex, closeBracketIndex + 1);
    try {
      JSON.parse(candidate);
      return candidate;
    } catch (e) {}

    return message;
  };

  public formatJsonRendererErrorMessages = (message: string): string[] => {
    try {
      const document = JSON.parse(message);
      if (document.events && Array.isArray(document.events)) {
        const messages: string[] = [];
        for (const event of document.events) {
          if (event.message && typeof event.message === 'string') {
            messages.push(event.message);
          }
        }
        // Only return collected messages if there were some. Otherwise we will
        // return the original message string in the end
        if (messages.length > 0) {
          return messages;
        }
      }

      // If our renderer error structure isn't matched simply return the
      // original message.
      return [message];
    } catch (error) {
      // If parsing is impossible, simply return the original
      return [message];
    }
  };

  public componentDidMount(): void {
    if (this.props.extendOnMount === true) {
      this.setState({ extended: true });
    }
  }

  public render(): JSX.Element {
    const { rendererResult } = this.props;
    const { extended } = this.state;
    const { image, errors } = rendererResult;

    const formattedErrorMessages = [];
    for (const error of errors) {
      const trimmedMessage = this.tryAndExtractJsonFromErrorMessage(error);
      const formattedMessages = this.formatJsonRendererErrorMessages(trimmedMessage);
      for (const formattedMessage of formattedMessages) {
        formattedErrorMessages.push(formattedMessage);
      }
    }

    return (
      <Card elevation={3}>
        {image && <CardMedia src={`data:image/png;base64,${image}`} component={'img'} />}
        {!image && errors && (
          <>
            <CardContent style={{ textAlign: 'center' }}>
              <Error />
              <Typography>preview not available</Typography>
            </CardContent>
            <Collapse in={extended} timeout={'auto'} unmountOnExit>
              <List>
                {formattedErrorMessages.map((message: string, index: number) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <Warning />
                    </ListItemIcon>
                    <ListItemText primary={message} primaryTypographyProps={{ paragraph: true }} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <CardActions style={{ justifyContent: 'center' }}>
              <Button
                size="small"
                onClick={this.toggleExtended}
                startIcon={extended ? <ExpandLess /> : <ExpandMore />}
                endIcon={extended ? <ExpandLess /> : <ExpandMore />}
              >
                Errors
              </Button>
            </CardActions>
          </>
        )}
      </Card>
    );
  }
}

export const TemplatePreview = TemplatePreviewComponent;
