import { Theme, WithStyles, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import * as classNames from 'classnames';
import React from 'react';

const styles = (theme: Theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(),
  } as CSSProperties,
  rootSticky: {
    marginLeft: 0,
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
});

export interface TablePaginationActionsPropsOverride extends WithStyles<typeof styles>, TablePaginationActionsProps {
  sticky: boolean;
}

class TablePaginationActionsComponent extends React.Component<TablePaginationActionsPropsOverride> {
  public handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { onPageChange, page } = this.props;

    onPageChange(event, page - 1);
  };

  public handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { onPageChange, page } = this.props;

    onPageChange(event, page + 1);
  };

  public render() {
    const { backIconButtonProps, classes, count, nextIconButtonProps, page, rowsPerPage, sticky } = this.props;

    return (
      <div className={classNames(classes.root, sticky && classes.rootSticky)}>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          color="inherit"
          className={classes.button}
          {...backIconButtonProps}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          color="inherit"
          className={classes.button}
          {...nextIconButtonProps}
        >
          <KeyboardArrowRight />
        </IconButton>
      </div>
    );
  }
}

export const TablePaginationActions = withStyles(styles)(TablePaginationActionsComponent);
