import { Grid, Hidden } from '@material-ui/core';
import { GridSize } from '@material-ui/core/Grid';
import { GridProps } from '@material-ui/core/Grid/Grid';
import { HiddenProps } from '@material-ui/core/Hidden';
import React from 'react';

export const spacer = (xs?: GridSize, gridProps?: GridProps): JSX.Element => (
  <Hidden mdDown>
    <Grid item xs={xs} {...gridProps}>
      {}
    </Grid>
  </Hidden>
);

export const spacerCustom = (hiddenProps: HiddenProps, gridProps: { [key: string]: GridSize }): JSX.Element => (
  <Hidden {...hiddenProps}>
    <Grid item {...gridProps}>
      {}
    </Grid>
  </Hidden>
);
