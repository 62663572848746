import { PickingColor } from '@ekkogmbh/apisdk';
import { green, orange, red } from '@material-ui/core/colors';

const saturation = 400;

export const PickingColorsCSS: Record<PickingColor, string> = {
  GREEN: green[saturation],
  RED: red[saturation],
  YELLOW: orange[saturation],
};

export const PickingColors: PickingColor[] = ['GREEN', 'RED', 'YELLOW'];
