import { EslManagerPrivateRoute, HttpMethod, Technology, Template } from '@ekkogmbh/apisdk';
import {
  Accordion,
  AccordionActions,
  AccordionSummary,
  Button,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { Autorenew, Delete, ExpandMore } from '@material-ui/icons';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { ClipboardEvent } from 'react';
import { Component } from 'react';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { LabelLinkStore } from '../Stores/LabelLinkStore';
import { LabelLinkTemplateMapper } from './LabelLinkTemplateMapper';

const styles = FormStyles;

interface LabelLinkManagerState {
  templateGroupName: string;
  labelId: string;
  technology: string;
  addGroupOpen: boolean;
  activeTab?: number;
  templates: Template[];
  technologies: string[];
  loading: boolean;
}

interface LabelLinkManagerProps extends WithStyles<typeof styles>, InjectedNotistackProps {
  type: 'profile' | 'linking';
  coordinate: string;
  initialTemplateGroups?: Record<string, Record<string, string>>;
  selectedGroup?: string;
  errorHandler?: () => void;
}

interface LabelLinkManagerStores {
  api: ApiStore;
  labelLinkStore: LabelLinkStore;
}

type LabelLinkManagerPropsWithStores = LabelLinkManagerProps & LabelLinkManagerStores;

@inject('api', 'labelLinkStore')
@observer
class LabelLinkManagerComponent extends Component<LabelLinkManagerProps, LabelLinkManagerState> {
  private fetchPromises: CancelableFetchPromises = {};

  public state: LabelLinkManagerState = {
    templateGroupName: '',
    labelId: '',
    technology: '',
    addGroupOpen: false,
    templates: [],
    technologies: [],
    loading: true,
  };

  get stores(): LabelLinkManagerStores {
    return this.props as LabelLinkManagerPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    const { labelLinkStore } = this.stores;
    const { initialTemplateGroups, errorHandler } = this.props;

    try {
      const [templates, technologies] = await Promise.all([this.fetchTemplates(), this.fetchAllTechnologies()]);

      // if there are template groups in the store, use them as initial?
      labelLinkStore.initialTemplateGroups = initialTemplateGroups ?? {};

      const hasTemplateGroups = labelLinkStore.getGroupNames().length > 0;

      this.setState({
        templates,
        technologies,
        loading: false,
        addGroupOpen: !hasTemplateGroups,
        activeTab: hasTemplateGroups ? 0 : undefined,
      });
    } catch (e) {
      if (errorHandler !== undefined) {
        errorHandler();
      }
    }
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchTemplates = async (): Promise<Template[]> => {
    const { api } = this.stores;
    const { enqueueSnackbar, coordinate } = this.props;

    return await request<Template[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getAvailableTemplates(coordinate),
      EslManagerPrivateRoute.AVAILABLE_TEMPLATES,
      HttpMethod.GET,
    );
  };

  public fetchAllTechnologies = async (): Promise<string[]> => {
    const { api } = this.stores;
    const { enqueueSnackbar } = this.props;

    const technologies = await request<Technology[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getTechnologies(),
      EslManagerPrivateRoute.TECHNOLOGIES,
      HttpMethod.GET,
    );

    return technologies.map((technology) => technology.name);
  };

  public onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.persist();
    const target = event.target as HTMLInputElement;
    setTimeout(() => this.handleChange(target.name, target.value), 500);
  };

  public onReset = () => {
    const { selectedGroup } = this.props;
    const { labelLinkStore } = this.stores;

    labelLinkStore.resetTemplateGroups();
    this.resetState();

    const groupNames = labelLinkStore.getGroupNames();
    if (groupNames.length > 0) {
      this.switchTab(selectedGroup ?? groupNames[0]);
    }
  };

  public onRemove = () => {
    const { labelLinkStore } = this.stores;
    const { activeTab } = this.state;

    if (activeTab !== undefined) {
      const groupNames = labelLinkStore.getGroupNames();
      labelLinkStore.deleteTemplateGroup(groupNames[activeTab]);

      if (groupNames.length === 1) {
        this.setState({ activeTab: undefined });
      } else {
        if (activeTab === 0) {
          this.switchTab(groupNames[activeTab + 1]);
        } else {
          this.setState({ activeTab: activeTab - 1 });
        }
      }
    }
  };

  public resetState = () => {
    const { technologies } = this.state;
    const technology = technologies.length > 0 ? technologies[0] : '';

    this.setState({
      technology,
      labelId: '',
      templateGroupName: '',
      activeTab: undefined,
      addGroupOpen: false,
    });
  };

  public switchTab = (groupName: string) => {
    const { labelLinkStore } = this.stores;

    const activeTab = labelLinkStore.getGroupNames().indexOf(groupName);

    this.setState({ activeTab });
  };

  public handleChange = (name: string, value: string) => {
    const valueTrimmed = value.trim();

    switch (name) {
      case 'groupName':
        this.setState({ templateGroupName: valueTrimmed });

        break;
      case 'labelId':
        const { technology } = this.state;

        this.setState({
          labelId: valueTrimmed,
          templateGroupName: valueTrimmed + '::' + technology,
        });

        break;
      case 'technology':
        const { labelId } = this.state;

        this.setState({
          technology: valueTrimmed,
          templateGroupName: labelId + '::' + valueTrimmed,
        });

        break;
    }
  };

  public renderAddGroupForm = (): JSX.Element => {
    const { type } = this.props;
    const { labelId, technology } = this.state;

    if (type === 'linking') {
      const { technologies } = this.state;

      const technologyOptions = technologies.map((technologyName: string, index: number) => (
        <option key={index} value={technologyName}>
          {technologyName}
        </option>
      ));

      return (
        <Grid container spacing={2} alignItems={'stretch'} justifyContent={'center'}>
          <Grid item xs={6}>
            <TextField
              label={'LabelId'}
              value={labelId}
              name={'labelId'}
              variant={'outlined'}
              onChange={(e) => this.handleChange('labelId', e.target.value)}
              onPaste={this.onPaste}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant={'outlined'}>
              <InputLabel htmlFor={'outlined-technology-simple'}>Technology</InputLabel>
              <Select
                native
                value={technology}
                onChange={(e) => this.handleChange('technology', e.target.value as string)}
                input={<OutlinedInput name={'technology'} labelWidth={84} id={'outlined-technology-simple'} />}
              >
                {technologyOptions}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      );
    } else {
      const { templateGroupName } = this.state;

      return (
        <TextField
          label={'GroupName'}
          value={templateGroupName}
          name={'groupName'}
          variant={'outlined'}
          onChange={(e) => this.handleChange('groupName', e.target.value)}
          onPaste={this.onPaste}
        />
      );
    }
  };

  public renderTemplateGroupEditor = (): JSX.Element => {
    const { labelLinkStore } = this.stores;
    const { type } = this.props;
    const { addGroupOpen, activeTab, templates } = this.state;

    const templateGroupNames = labelLinkStore.getGroupNames();
    const hasTemplateGroups = templateGroupNames.length > 0;

    return (
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item xs={12}>
          <Accordion
            color={'secondary'}
            expanded={addGroupOpen}
            onChange={() => this.setState({ addGroupOpen: !addGroupOpen })}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>{type === 'linking' ? 'Labels' : 'Template Groups'}</Typography>
            </AccordionSummary>
            <AccordionActions>
              {this.renderAddGroupForm()}
              <Button
                variant={'outlined'}
                onClick={() => {
                  const { templateGroupName } = this.state;
                  labelLinkStore.setTemplateGroup(templateGroupName, {});
                  this.resetState();
                  this.switchTab(templateGroupName);
                }}
              >
                add
              </Button>
            </AccordionActions>
          </Accordion>
        </Grid>
        {hasTemplateGroups && activeTab !== undefined && (
          <Grid item xs={12}>
            <Tabs
              value={activeTab}
              variant={'scrollable'}
              scrollButtons={'auto'}
              onChange={(_, newTab) => this.setState({ activeTab: newTab })}
            >
              {templateGroupNames.map((tabName: string) => (
                <Tab key={`tGroup-${tabName}`} label={tabName} />
              ))}
            </Tabs>
            <Divider />
          </Grid>
        )}
        {hasTemplateGroups && activeTab !== undefined && (
          <Grid item xs={12}>
            <LabelLinkTemplateMapper
              templateGroupName={templateGroupNames[activeTab]}
              availableTemplates={templates}
              allowAddingPages={true}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  public render(): JSX.Element {
    const { labelLinkStore } = this.stores;
    const { activeTab, addGroupOpen } = this.state;

    const groupNames = labelLinkStore.getGroupNames();
    const currentGroup = activeTab !== undefined ? groupNames[activeTab] : '';

    return (
      <>
        {this.renderTemplateGroupEditor()}
        <div style={{ position: 'relative', left: '78%', marginTop: 5 }} hidden={addGroupOpen}>
          <Tooltip title={'Reset'} placement={'left'}>
            <>
              <Fab
                disabled={!labelLinkStore.hasChanged()}
                color={'secondary'}
                size={'small'}
                onClick={() => this.onReset()}
                style={{ marginRight: 5 }}
              >
                <Autorenew />
              </Fab>
            </>
          </Tooltip>
          <Tooltip title={`Remove ${currentGroup}`} placement={'right'}>
            <>
              <Fab color={'secondary'} size={'small'} onClick={() => this.onRemove()} disabled={groupNames.length < 1}>
                <Delete />
              </Fab>
            </>
          </Tooltip>
        </div>
      </>
    );
  }
}

const SnackbarWrapped = withSnackbar<LabelLinkManagerProps>(LabelLinkManagerComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const LabelLinkManager = StyleWrapped;
