import React from 'react';

export const CustomBodyNullRender = (): JSX.Element => <div>{/**/}</div>;

export const TableBodyFiller = {
  name: '',
  field: '',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: CustomBodyNullRender,
  },
};
