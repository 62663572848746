import { Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const borderRemove = {
  '&:before': {
    border: 'none !important',
  },
  '&:after': {
    border: 'none !important',
    borderBottom: 'none !important',
  },
  '&:hover:before': {
    border: 'none !important',
  },
  '&:hover:after': {
    border: 'none !important',
    borderBottom: 'none !important',
  },
};

export const SearchBarStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  searchBar: {
    flexBasis: undefined,
    borderRadius: 48,
    backgroundColor: theme.palette.secondary.light,
    height: 48,
    minHeight: 48,
  },
  searchBarDisabled: {
    width: 150,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.secondary.main,
  },
  searchBarInputRoot: {
    height: 48,
    borderRadius: 48,
    paddingRight: 0,
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    ...borderRemove,
  },
  searchBarInputUnderline: {
    ...borderRemove,
  },
  searchBarInput: {
    color: theme.palette.common.white,
    width: 200,
    '&:focus': {
      width: 400,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  searchBarLabel: {
    top: -4,
  },
  searchBarMargin: {
    margin: theme.spacing(),
  },
  searchBarAdornment: {
    height: 48,
    width: 48,
    marginLeft: 12,
  },
  searchBarAdornmentButton: {
    marginLeft: -24,
  },
  searchBarSticky: {
    margin: undefined,
    backgroundColor: 'transparent',
  },
  searchBarStickyInputRoot: {
    width: 48,
    height: 48,
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    ...borderRemove,
  },
  searchBarStickyLabel: {
    display: 'none',
  },
  searchBarIcon: {
    color: theme.palette.primary.main,
  },
  searchBarIconDisabled: {
    color: theme.palette.secondary.main,
  },
  searchRoot: {
    width: 0,
    overflow: 'none',
  },
  searchInput: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '&:before': {
      border: 'none',
    },
    '&:hover:before': {
      border: 'none !important',
      borderBottom: 'none !important',
    },
  },
});
