import { createTheme } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';

const ekkoCI = {
  primary: {
    light: 'rgba(135, 186, 218, 1)',
    main: 'rgba(255, 51, 56, 1)',
    dark: 'rgba(48, 183, 231, 1)',
    contrastText: '#111',
  },
  secondary: {
    light: 'rgba(106, 106, 106, 1)',
    main: 'rgba(46, 46, 46, 1)',
    dark: 'rgba(67, 67, 67, 1)',
    contrastText: '#fff',
  },
};

const ekkoPalette = {
  common: {
    black: '#000',
    white: '#fff',
  },
  background: {
    paper: 'rgba(247, 247, 247, 1)',
    default: 'rgba(244, 244, 244, 1)',
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  text: {
    primary: 'rgba(46, 46, 46, 0.87)',
    secondary: 'rgba(67, 67, 67, 0.54)',
    disabled: 'rgba(106, 106, 106, 0.38)',
    hint: 'rgba(171, 171, 171, 0.38)',
  },
};

const bmwCI = {
  primary: {
    dark: 'rgba(183, 183, 183, 1)',
    main: 'rgba(209, 205, 194, 1)',
    light: 'rgba(232, 230, 224, 1)',
    contrastText: 'rgba(0, 0, 0, 0.6)',
  },
  secondary: {
    light: 'rgba(102, 102, 102, 1)',
    main: 'rgba(68, 68, 68, 1)',
    dark: 'rgba(42, 42, 42, 1)',
    contrastText: '#fff',
  },
};

const bmwPalette = {
  common: {
    black: '#000',
    white: '#fff',
  },
  background: {
    default: 'rgba(239, 238, 234, 1)',
    paper: 'rgba(255, 255, 255, 1)',
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  text: {
    primary: 'rgba(0, 0, 0, 1)',
    secondary: 'rgba(153, 153, 153, 1)',
    disabled: 'rgba(153, 153, 153, 0.38)',
    hint: 'rgba(102, 102, 102, 1)',
  },
};

const palette = {
  ...ekkoPalette,
  ...ekkoCI,
  ...bmwPalette,
  ...bmwCI,
};

const overrides = {
  MaterialDatatableToolbar: {
    root: {
      display: 'none',
    },
  },
  MuiTable: {
    root: {
      outline: 0,
    },
  },
  MuiTableRow: {
    head: {
      height: 56,
    },
  },
} as Overrides;

export const Theme = createTheme({
  palette,
  overrides,
});
