import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { TitleProps } from '../Routes';
import { PickingAreasContent } from './Components/PickingAreasContent';
import { PickingAreaStore } from './Stores/PickingAreaStore';

const pickingAreaStore = new PickingAreaStore();

const stores = {
  pickingAreaStore,
};

export const PickingAreas = ({ title }: TitleProps): JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <PickingAreasContent />
    </Page>
  </Provider>
);
