import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { Loading } from './Common/Components/Loading';
import PrivateRoute from './Common/Components/PrivateRoute';
import { OverrideMomentUtils } from './Common/Helper/MomentUtils/OverrideMomentUtils';
import { ApiStore } from './Common/Stores/ApiStore';
import { Routes, RouteShortDefinition } from './Routes';
import { Login } from './Welcome/Components/Login';

interface EslManagerRouterStores {
  api: ApiStore;
}

interface EslManagerRouterProps {}

const stores = ['api'];

@inject(...stores)
@observer
class EslManagerRouter extends React.Component<EslManagerRouterProps> {
  get stores(): EslManagerRouterStores {
    return this.props as EslManagerRouterProps & EslManagerRouterStores;
  }

  public render(): JSX.Element {
    const { api } = this.stores;
    const authenticated: boolean = api.isAuthenticated;

    return api.isRestoring ? (
      <Loading />
    ) : (
      <MuiPickersUtilsProvider utils={OverrideMomentUtils}>
        <BrowserRouter>
          <Switch>
            <Route path="/login" exact component={Login} />
            {Routes.map((route: RouteShortDefinition, index: number) => {
              const RouteComponent = route.component;
              // eslint-disable-next-line @typescript-eslint/ban-types
              const render = (props: object) => <RouteComponent {...props} title={route.title} />;

              const hasPermission = route.permission === undefined || api!.userHasPermissionOnAnyNode(route.permission);

              return (
                <PrivateRoute
                  authed={authenticated}
                  hasPermission={hasPermission}
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={render}
                />
              );
            })}
            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    );
  }
}

export const EslManager = EslManagerRouter;
