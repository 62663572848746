import { Operation } from '@ekkogmbh/apisdk';
import { Description } from '@material-ui/icons';
import { MaterialDatatableColumnDef } from 'material-datatable';
import React from 'react';
import { DatatableColumnDefinitionFn, TableMeta } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { DataTableRecord } from '../../Common/Stores/DataTableStore';

import {
  OperationIdentifier,
  OperationPayloadColumn,
  OperationType,
} from '../../OperationGroups/Components/OperationDatatableColumnDefinitions';
import {
  CompartmentDetailsContentActionHandlers,
  CompartmentDetailsContentPropsWithStores,
  CompartmentDetailsContentState,
} from './CompartmentDetailsContent';

class CompartmentOperationDataTableActions extends DataTableActionsComponent<DataTableRecord<Operation>> {}

const CompartmentOperationActions = (
  __: CompartmentDetailsContentState,
  _: CompartmentDetailsContentPropsWithStores,
  actions: CompartmentDetailsContentActionHandlers,
): MaterialDatatableColumnDef => ({
  name: 'Trigger',
  field: 'triggers',
  options: {
    width: 48,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: Operation, tableMeta: TableMeta): React.ReactNode => {
      const actionButtons: ActionButton<Operation>[] = [
        {
          title: 'Details',
          onClick: actions.details,
          icon: Description,
        },
      ];
      return (
        <CompartmentOperationDataTableActions
          dataset={value}
          width={tableMeta.columnData.width}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  CompartmentDetailsContentPropsWithStores,
  CompartmentDetailsContentState,
  CompartmentDetailsContentActionHandlers
>> = [OperationIdentifier, OperationType, OperationPayloadColumn, CompartmentOperationActions];
