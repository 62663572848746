import { OperationGroup } from '@ekkogmbh/apisdk';
import { Delete, Description } from '@material-ui/icons';
import { MaterialDatatableColumnDef } from 'material-datatable';
import React from 'react';
import { nodeSeparator } from '../../AreaManagement/Components/AreaManagementContent';
import { DatatableColumnDefinitionFn, TableMeta } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { Permissions } from '../../Common/Stores/ApiStore';
import { DataTableRecord } from '../../Common/Stores/DataTableStore';
import {
  OperationGroupsContentActionHandlers,
  OperationGroupsContentPropsWithStores,
  OperationGroupsContentState,
} from './OperationGroupsContent';

class OperationGroupDataTableActions extends DataTableActionsComponent<DataTableRecord<OperationGroup>> {}

const OperationGroupName = (): MaterialDatatableColumnDef => ({
  name: 'Name',
  field: 'name',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: OperationGroup): React.ReactNode => <div>{value.name}</div>,
  },
});

const OperationGroupNode = (): MaterialDatatableColumnDef => ({
  name: 'Area',
  field: 'nodeParts',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: OperationGroup): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{value.nodeParts.join(nodeSeparator)}</div>
    ),
  },
});

const OperationGroupDescription = (): MaterialDatatableColumnDef => ({
  name: 'Description',
  field: 'description',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: OperationGroup): React.ReactNode => <div>{value.description}</div>,
  },
});

const OperationGroupState = (): MaterialDatatableColumnDef => ({
  name: 'State',
  field: 'state',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: OperationGroup): React.ReactNode => <div style={{ fontWeight: 700 }}>{value.state}</div>,
  },
});

const OperationGroupCreatedAt = (): MaterialDatatableColumnDef => ({
  name: 'Created-At',
  field: 'createdAt',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: OperationGroup): React.ReactNode => {
      const dateString = new Date(value.createdAt).toLocaleDateString();
      const timeString = new Date(value.createdAt).toLocaleTimeString();
      return (
        <div>
          {dateString} - {timeString}
        </div>
      );
    },
  },
});

const OperationGroupActions = (
  _: OperationGroupsContentState,
  propsWithStores: OperationGroupsContentPropsWithStores,
  actions: OperationGroupsContentActionHandlers,
): MaterialDatatableColumnDef => ({
  name: '',
  field: '',
  options: {
    width: 48 * 3,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (operationGroup: OperationGroup, tableMeta: TableMeta): React.ReactNode => {
      const hasWritePermission = propsWithStores.api.userHasPermissionOnAnyNode(Permissions.OPERATION_GROUPS_WRITE);

      const actionButtons: ActionButton<OperationGroup>[] = [
        {
          title: 'Details',
          onClick: actions.details,
          icon: Description,
          disabled: !hasWritePermission,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return (
        <OperationGroupDataTableActions
          dataset={operationGroup}
          width={tableMeta.columnData.width}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  OperationGroupsContentPropsWithStores,
  OperationGroupsContentState,
  OperationGroupsContentActionHandlers
>> = [
  OperationGroupName,
  OperationGroupNode,
  OperationGroupDescription,
  OperationGroupState,
  OperationGroupCreatedAt,
  OperationGroupActions,
];
