import { Tab, Tabs, withStyles, WithStyles } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { CSVImportStepper, ImportHandler } from 'src/Common/Components/CSVImportStepper';
import { FormStyles } from 'src/Common/Styles/FormStyles';

const styles = FormStyles;

interface ImportPanelState {
  currentTab: number;
  loading: boolean;
  disableDiffModalOverride: boolean;
}

export interface ImportPanelTabDefinition {
  description: string;
  icon: React.ComponentType<SvgIconProps>;
  importHandler: ImportHandler;
  disableDiffModalOverride?: boolean;
}

export interface ImportPanelProps extends WithStyles<typeof styles>, InjectedNotistackProps {
  tabs: ImportPanelTabDefinition[];
  closeHandler: () => void;
  importFinishedHandler: () => void;
}

class ImportPanelComponent extends Component<ImportPanelProps, ImportPanelState> {
  public state: ImportPanelState = {
    loading: false,
    currentTab: 0,
    disableDiffModalOverride: false,
  };

  public async componentDidMount(): Promise<void> {
    this.setState({ loading: false });
  }

  public handleTabChange = (_: React.ChangeEvent<Record<string, unknown>>, value: number): void => {
    const { tabs } = this.props;
    const disableDiffModalOverride = tabs[value].disableDiffModalOverride;
    this.setState({
      currentTab: value,
      disableDiffModalOverride: disableDiffModalOverride === undefined ? false : disableDiffModalOverride,
    });
  };

  public closeHandler = () => {
    this.setState({ currentTab: 0 });

    if (this.props.closeHandler !== undefined) {
      this.props.closeHandler();
    }
  };

  public render() {
    const { currentTab, disableDiffModalOverride } = this.state;
    const { tabs, importFinishedHandler } = this.props;

    const currentImportHandler = tabs[currentTab].importHandler;

    const importSteps = [
      {
        title: 'File-Selection',
        content: 'Drop or select an import file.',
      },
      {
        title: 'Upload/Import',
        content: 'Press Import to upload the selected file.',
      },
      {
        title: 'Tasks-Processing',
        content:
          'Tasks processing, feel free to close the importer now. You will get a notification when processing is done.',
      },
    ];

    return (
      <React.Fragment>
        <Tabs
          value={currentTab}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="secondary"
          centered
        >
          {tabs.map((tabDef: ImportPanelTabDefinition, index: number) => {
            const { description } = tabDef;

            return (
              <Tab
                key={index}
                label={
                  <div
                    style={{
                      display: 'inline-flex',
                      justifyContent: 'center',
                      lineHeight: '42px',
                    }}
                  >
                    <tabDef.icon style={{ margin: 8 }} />
                    {description}
                  </div>
                }
              />
            );
          })}
        </Tabs>
        {
          <CSVImportStepper
            steps={importSteps}
            importHandler={currentImportHandler}
            closeHandler={this.closeHandler}
            importFinishedHandler={importFinishedHandler}
            disableDiffModalOverride={disableDiffModalOverride}
          />
        }
      </React.Fragment>
    );
  }
}

const SnackbarWrapped = withSnackbar<ImportPanelProps>(ImportPanelComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const ImportPanel = StyleWrapped;
