import { Color, Grow, WithStyles, withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Component } from 'react';
import * as reactCountUp from 'react-countup';
import { Cell, Pie, PieChart } from 'recharts';
import {
  chartHeight,
  chartInnerRadius,
  chartOuterRadius,
  chartWidth,
  countUpTime,
  fadeTimeout,
  saturation,
  SystemStatusStyles,
} from '../Styles/SystemStatusStyles';

const CountUp = reactCountUp.default;

interface SystemStatusBoxCardProps extends WithStyles<typeof SystemStatusStyles> {
  title: string;
  data: Array<{ name: string; value: number; color: Color }>;
}

class SystemStatusBoxCardComponent extends Component<SystemStatusBoxCardProps> {
  public render() {
    const { classes, data, title } = this.props;

    const pieData = data.map((d) => {
      const { name, value } = d;
      return {
        name,
        value,
      };
    });

    return (
      <div>
        <Grow in={true} timeout={fadeTimeout}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardTitle} color="textPrimary" gutterBottom>
                {title}
              </Typography>

              <PieChart width={chartWidth} height={chartHeight} className={classes.chart}>
                <Pie
                  data={pieData}
                  innerRadius={chartInnerRadius}
                  outerRadius={chartOuterRadius}
                  paddingAngle={0}
                  dataKey={'value'}
                >
                  {data.map(({ color }, index: number) => (
                    <Cell key={index} fill={color[saturation]} />
                  ))}
                </Pie>
              </PieChart>

              <Typography component={'div'} className={classes.cardText} color="textPrimary">
                {data.map(({ color, value }, index: number) => (
                  <React.Fragment key={index}>
                    <span style={{ color: color[saturation] }}>
                      <CountUp start={0} end={value} duration={countUpTime / 1000} delay={fadeTimeout / 1000} />
                    </span>
                    {index === data.length - 1 ? '' : <span>&nbsp;/&nbsp;</span>}
                  </React.Fragment>
                ))}
              </Typography>
              <Typography className={classes.cardCaption} color="textPrimary">
                {data.map(({ name }, index: number) => (
                  <React.Fragment key={index}>
                    <span>{name}</span>
                    {index === data.length - 1 ? '' : <span>&nbsp;/&nbsp;</span>}
                  </React.Fragment>
                ))}
              </Typography>
            </CardContent>
          </Card>
        </Grow>
      </div>
    );
  }
}

const StyleWrapped = withStyles(SystemStatusStyles)(SystemStatusBoxCardComponent);

export const SystemStatusBoxCard = StyleWrapped;
