import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { TitleProps } from '../Routes';
import { UserManagementContent } from '../UserManagement/Components/UserManagementContent';

export const RoleUsers = ({ title }: TitleProps): JSX.Element => (
  <Provider>
    <Page title={title} isSearchable={true}>
      <UserManagementContent />
    </Page>
  </Provider>
);
