import {
  ButtonPressedTriggerPayload,
  OperationCreate,
  OperationGroup,
  OperationPayload,
  OperationPayloadSwitchPage,
  OperationType,
  OperationTypeSwitchPage,
  TimeBasedTriggerPayload,
  TriggerCreate,
  TriggerPayload,
  TriggerType,
  TriggerTypeButtonPressed,
  TriggerTypeTimeBased,
} from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';
import { ReactSelectMultiValue } from '../../Common/Components/ReactSelectMulti';
import { CREATION_TYPE_COMPARTMENT_UPDATE, OperationGroupCreationType } from '../Components/OperationGroupPanel';

export interface OperationGroupState {
  name: string;
  description: string;
  allFilled: boolean;
  allFilledTriggers: boolean;
  allFilledOperations: boolean;
  nodeSelectValue: ReactSelectMultiValue[];
  nodeValue: string;
  needRefresh: boolean;
  creationType: OperationGroupCreationType;
  triggers: TriggerCreate[];
  type: string;
  operations: OperationCreate[];
}

export class OperationGroupStore {
  @observable
  public editableOperationGroup?: OperationGroup;

  @observable
  public state: OperationGroupState = {
    allFilled: false,
    allFilledTriggers: false,
    allFilledOperations: false,
    needRefresh: false,
    nodeSelectValue: [],
    nodeValue: '',
    name: '',
    description: '',
    creationType: CREATION_TYPE_COMPARTMENT_UPDATE,
    triggers: [
      {
        payload: {
          datetime: '',
        },
        type: TriggerTypeTimeBased,
      },
    ],
    type: '',
    operations: [],
  };

  @observable
  public loading: boolean = false;

  @observable
  public changed: boolean = false;

  @action
  public setState = (newState: Partial<OperationGroupState>, setAllFilled: boolean = true): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    if (setAllFilled) {
      const allFilledTriggers = this.state.triggers.reduce((acc, curr) => {
        if (!acc) {
          return false;
        }

        return curr.type !== undefined && this.isTriggerPayloadFilled(curr.type, curr.payload);
      }, true);

      const allFilledOperations = this.state.operations.reduce((acc, curr) => {
        if (!acc) {
          return false;
        }

        return curr.type !== undefined && this.isOperationPayloadFilled(curr.type, curr.payload);
      }, true);

      const allFilled =
        this.state.description !== '' &&
        this.state.name !== '' &&
        (this.state.nodeSelectValue.length !== 0 || this.state.nodeValue !== '');

      this.state = {
        ...this.state,
        allFilled,
        allFilledTriggers,
        allFilledOperations,
      };
    }
  };

  @action
  public resetState = (): void => {
    this.state = {
      allFilled: false,
      allFilledTriggers: false,
      allFilledOperations: false,
      needRefresh: false,
      nodeSelectValue: [],
      nodeValue: '',
      name: '',
      description: '',
      creationType: CREATION_TYPE_COMPARTMENT_UPDATE,
      triggers: [
        {
          payload: {
            datetime: '',
          },
          type: TriggerTypeTimeBased,
        },
      ],
      type: '',
      operations: [],
    };

    this.changed = false;
    this.loading = false;
  };

  @action
  public resetStore = (): void => {
    this.editableOperationGroup = undefined;
    this.loading = false;
    this.changed = false;
    this.resetState();
  };

  private isTriggerPayloadFilled(triggerType: TriggerType, triggerPayload: TriggerPayload): boolean {
    switch (triggerType) {
      case TriggerTypeTimeBased:
        const timeBasedPayload = triggerPayload as TimeBasedTriggerPayload;
        return timeBasedPayload.datetime !== '' && timeBasedPayload.datetime !== undefined;
      case TriggerTypeButtonPressed:
        const buttonPressedPayload = triggerPayload as ButtonPressedTriggerPayload;
        return (
          buttonPressedPayload.labelId !== undefined &&
          buttonPressedPayload.labelId !== '' &&
          buttonPressedPayload.technology !== undefined &&
          buttonPressedPayload.technology !== '' &&
          buttonPressedPayload.buttonIndex !== undefined &&
          buttonPressedPayload.buttonIndex !== ''
        );

      default:
        return false;
    }
  }

  private isOperationPayloadFilled(operationType: OperationType, operationPayload: OperationPayload): boolean {
    switch (operationType) {
      case OperationTypeSwitchPage:
        const switchPagePayload = operationPayload as OperationPayloadSwitchPage;
        const { pageNumber } = switchPagePayload;

        return pageNumber !== undefined && pageNumber !== null;
      default:
        return false;
    }
  }
}
