import { Node, UserPermission } from '@ekkogmbh/apisdk';

export const NodeSeparator = '/';

export const getOverrideRootNodesFromUserPermissions = (userPermissions: UserPermission[]): Node[] => {
  const mapper = (userPermission: UserPermission): Node => {
    const definitionValues = userPermission.nodeDefinition.nodeValuesDefinition.values;
    const definitionTypes = userPermission.nodeDefinition.nodeTypesDefinition.types;
    const value = definitionValues[definitionValues.length - 1];
    const type = definitionTypes[definitionTypes.length - 1];
    const id = userPermission.nodeId;

    return {
      id,
      value,
      type,
      identifier: definitionValues,
      hasChildren: true, // doesnt matter
      parentId: null, // must be null even though its no a usecase
    };
  };

  const isNodeAncestorOfNode = (leftNode: Node, rightNode: Node): boolean => {
    let index = 0;

    while (leftNode.identifier[index] !== undefined && rightNode.identifier[index] !== undefined) {
      if (leftNode.identifier[index] !== rightNode.identifier[index]) {
        return false;
      }

      index++;
    }

    return leftNode.identifier[index] === undefined && rightNode.identifier[index] !== undefined;
  };

  const filter = (filterNode: Node) => (currNode: Node): boolean => isNodeAncestorOfNode(currNode, filterNode);

  const reducer = (accNodes: Node[], currNode: Node, _: number, nodes: Node[]): Node[] => {
    const filtered = nodes.filter(filter(currNode));

    if (filtered.length === 0) {
      accNodes.push(currNode);
    }

    return accNodes;
  };

  const allPermissionNodes = userPermissions.map(mapper);

  return allPermissionNodes.reduce(reducer, []);
};
