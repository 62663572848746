import {
  Compartment,
  EslManagerPrivateRoute,
  HttpMethod,
  LabelId,
  Technology,
  Template,
  LinkSavePayload,
  LinkView,
  CompartmentView,
  EslManagerPublicRouteV1,
} from '@ekkogmbh/apisdk';
import {
  Fade,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  OutlinedInput,
  Select,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField/TextField';
import { inject } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { ChangeEvent, ClipboardEvent, Component } from 'react';
import { ConfirmationDialog } from '../../Common/Components/ConfirmationDialog';
import { FormPanelButtons, PanelAction } from '../../Common/Components/FormPanelButtons';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises, noop } from '../../Common/Helper/PromiseHelper';
import { TaskCollectionProgressCallback } from '../../Common/Stores/TaskCollectionStore';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { FormStyles } from '../../Common/Styles/FormStyles';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['api'];

type LinkPanelPropsWithStores = LinkPanelProps & LinkPanelStores;

interface LinkPanelStores {
  api: ApiStore;
}

interface LinkPanelState {
  action: PanelAction;
  coordinate: string;
  labelId: LabelId;
  template: Template | undefined;
  technology: string;
  changed: boolean;
  loading: boolean;
  deleteDialogOpen: boolean;
  allFilled: boolean;
  allTemplates: Template[];
  allTechnologies: string[];
  renderInputElements: boolean;
  pageNumber: number;
}

export interface LinkPanelProps extends WithStyles<typeof styles>, InjectedNotistackProps {
  link?: LinkView;
  compartment?: CompartmentView;
  closeHandler: () => void;
  saveHandler: (link: LinkSavePayload) => Promise<void>;
  deleteHandler: (link: LinkView, progressCallback?: TaskCollectionProgressCallback) => Promise<void>;
}

@inject(...stores)
class LinkPanelComponent extends Component<LinkPanelProps, LinkPanelState> {
  public state: LinkPanelState = {
    action: PanelAction.CREATE,
    coordinate: '',
    labelId: '',
    template: undefined,
    technology: '',
    changed: false,
    loading: false,
    deleteDialogOpen: false,
    allFilled: false,
    allTemplates: [],
    allTechnologies: [],
    renderInputElements: true,
    pageNumber: 1,
  };
  private progressCompletedCallback: () => void;
  private fetchPromises: CancelableFetchPromises = {};

  constructor(props: LinkPanelProps) {
    super(props);

    const { compartment, link, closeHandler } = this.props;

    if (link !== undefined) {
      const action = PanelAction.EDIT;
      const { labelId, template, technology, pageNumber } = link;

      this.state = {
        ...this.state,
        action,
        labelId,
        template,
        technology,
        pageNumber,
      };
    } else {
      const action = PanelAction.CREATE;

      this.state = {
        ...this.state,
        action,
      };
    }

    if (compartment !== undefined) {
      const coordinate = compartment.coordinate;

      this.state = {
        ...this.state,
        coordinate,
      };
    }

    this.progressCompletedCallback = () => {
      this.setState({ loading: false });
      closeHandler();
    };
  }

  get stores(): LinkPanelStores {
    return this.props as LinkPanelPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    const { coordinate } = this.state;

    this.setState(
      {
        loading: true,
      },
      async () => {
        const [userCompartmentTemplates, allTechnologies] = await Promise.all([
          this.fetchTemplates(coordinate),
          this.fetchAllTechnologies(),
        ]);

        this.setState({
          allTemplates: userCompartmentTemplates,
          allTechnologies,
          loading: false,
        });
      },
    );
  }

  // @TODO snapshot?
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public componentDidUpdate(prevProps: Readonly<LinkPanelProps>, s: Readonly<LinkPanelState>, __?: any): void {
    const { link, compartment } = this.props;
    const prevLink = prevProps.link;
    const prevCompartment = prevProps.compartment;

    const l1 = JSON.stringify(link);
    const l2 = JSON.stringify(prevLink);
    const c1 = JSON.stringify(compartment);
    const c2 = JSON.stringify(prevCompartment);

    const aT1 = JSON.stringify(s.allTechnologies);
    const aTpl1 = JSON.stringify(s.allTemplates);
    const aT2 = JSON.stringify(this.state.allTechnologies);
    const aTpl2 = JSON.stringify(this.state.allTemplates);

    if (c1 !== c2 || l1 !== l2 || aT1 !== aT2 || aTpl1 !== aTpl2) {
      this.setState(
        {
          renderInputElements: false,
          loading: true,
        },
        async () => this.updateState(link, compartment, c1 !== c2 || l1 !== l2),
      );
    }
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
    this.progressCompletedCallback = noop;
  }

  public fetchTemplates = async (coordinate: CompartmentView['coordinate']): Promise<Template[]> => {
    const { api } = this.stores;
    const { enqueueSnackbar } = this.props;

    return await request<Template[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getCompartmentTemplates(coordinate),
      EslManagerPublicRouteV1.COMPARTMENT_TEMPLATES,
      HttpMethod.GET,
    );
  };

  public fetchAllTechnologies = async (): Promise<string[]> => {
    const { api } = this.stores;
    const { enqueueSnackbar } = this.props;

    const technologies = await request<Technology[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getTechnologies(),
      EslManagerPrivateRoute.TECHNOLOGIES,
      HttpMethod.GET,
    );

    return technologies.map((technology) => technology.name);
  };

  public resetState = () => {
    const { link, compartment } = this.props;
    this.updateState(link, compartment);
  };

  public onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.persist();
    const target = event.target as HTMLInputElement;
    setTimeout(() => this.handleChange(target.name, target.value), 500);
  };

  public onChangeTemplateSelect = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = event.target;
    const currentState: LinkPanelState = { ...this.state };

    currentState.changed = true;
    currentState.template = this.state.allTemplates.find(
      (template: Template): boolean => template.id === parseInt(value as string),
    );

    this.setState({ ...currentState, allFilled: this.isAllFilled(currentState) });
  };

  public onChangeTechnologySelect = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    this.handleChange(name as string, value as string);
  };

  public onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    this.handleChange(name, value);
  };

  public handleChange = (name: string, value: string) => {
    const currentState: LinkPanelState = { ...this.state };

    currentState.changed = true;
    currentState[name] = value;

    this.setState({
      ...currentState,
      allFilled: this.isAllFilled(currentState),
    });
  };

  public isStateAllFilled = (): boolean => {
    const { coordinate, technology, labelId, template, pageNumber } = this.state;

    return !!coordinate && !!technology && !!labelId && !!template && !!pageNumber;
  };

  public isAllFilled = ({
    coordinate,
    technology,
    labelId,
    template,
    pageNumber,
  }: Pick<LinkPanelState, 'coordinate' | 'technology' | 'labelId' | 'template' | 'pageNumber'>): boolean =>
    !!coordinate && !!technology && !!labelId && !!template && !!pageNumber;

  public onSave = async () => {
    const { saveHandler, enqueueSnackbar } = this.props;
    const { technology, labelId, template, coordinate, pageNumber } = this.state;

    if (template === undefined) {
      throw Error(`Save called with empty template.`);
    }

    const link: LinkSavePayload = {
      labelId,
      template,
      technology,
      pageNumber,
      coordinate,
    };

    // TODO: task collection should be handled (but possibly not here)
    // const progressCallback = (_: string, __: string, progress?: number) => {
    //   if (progress === 100) {
    //     this.progressCompletedCallback();
    //   }
    // };

    this.setState({ loading: true });

    try {
      await saveHandler(link);
      this.progressCompletedCallback();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
      this.setState({ loading: false });
    }
  };

  public onCancel = () => {
    const { closeHandler } = this.props;
    this.resetState();
    closeHandler();
  };

  public onDelete = () => {
    this.setState({ deleteDialogOpen: true });
  };

  public onDeleteDismiss = () => {
    this.setState({ deleteDialogOpen: false });
  };

  public onDeleteOk = () => {
    const { link, deleteHandler } = this.props;

    this.setState({
      loading: true,
      deleteDialogOpen: false,
    });

    const progressCallback = (_: string, __: string, progress?: number) => {
      if (progress === 100) {
        this.progressCompletedCallback();
      }
    };

    deleteHandler(link!, progressCallback);
  };

  public render() {
    const { classes, compartment } = this.props;
    const {
      action,
      coordinate,
      labelId,
      technology,
      template,
      changed,
      loading,
      deleteDialogOpen,
      allFilled,
      allTemplates,
      allTechnologies,
      renderInputElements,
      pageNumber,
    } = this.state;

    const deleteDialogText =
      coordinate && labelId ? (
        <React.Fragment>
          Coordinate: <span style={{ fontWeight: 'bold' }}>{coordinate}</span>
          <br />
          LabelId: <span style={{ fontWeight: 'bold' }}>{labelId}</span>
          <br />
          Template: <span style={{ fontWeight: 'bold' }}>{template?.name}</span>
          <br />
          Page: <span style={{ fontWeight: 'bold' }}>{pageNumber}</span>
        </React.Fragment>
      ) : (
        ''
      );

    const technologyOptions = allTechnologies.map((technologyName: string, index: number) => (
      <option key={index} value={technologyName}>
        {technologyName}
      </option>
    ));

    const templateOptions = allTemplates.map((template) => (
      <option key={template.id} value={template.id}>
        {template.name}
      </option>
    ));

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {action === PanelAction.EDIT && deleteDialogOpen && (
          <ConfirmationDialog
            open={deleteDialogOpen}
            title={'Delete Link'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
            timedOkButton={true}
          />
        )}
        {loading && <LoadingMask />}

        <Grid item lg={4} md={6} xs={12}>
          {renderInputElements && (
            <React.Fragment>
              <Fade in={true} timeout={fadeTimeout}>
                <TextField
                  label={'Coordinate'}
                  value={coordinate}
                  name={'identifier'}
                  disabled={!(action === PanelAction.CREATE && compartment === undefined)}
                  onChange={this.onChange}
                  variant="outlined"
                  className={classes.margin}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                      disabled: classes.disabled,
                    },
                  }}
                />
              </Fade>

              <Fade in={true} timeout={fadeTimeout}>
                <FormControl variant="outlined" className={classes.margin}>
                  <InputLabel htmlFor="outlined-technology-simple">Technology</InputLabel>
                  <Select
                    native
                    value={technology}
                    onChange={this.onChangeTechnologySelect}
                    disabled={action !== PanelAction.CREATE}
                    input={<OutlinedInput name="technology" labelWidth={84} id="outlined-technology-simple" />}
                  >
                    <option value="" />
                    {technologyOptions}
                  </Select>
                </FormControl>
              </Fade>

              <Fade in={true} timeout={fadeTimeout}>
                <TextField
                  label={'LabelId'}
                  value={labelId}
                  name={'labelId'}
                  disabled={action !== PanelAction.CREATE}
                  onChange={this.onChange}
                  onPaste={this.onPaste}
                  variant="outlined"
                  className={classes.margin}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                      disabled: classes.disabled,
                    },
                  }}
                />
              </Fade>

              <Fade in={true} timeout={fadeTimeout}>
                <FormControl variant="outlined" className={classes.margin}>
                  <InputLabel htmlFor="outlined-template-simple">
                    {templateOptions.length === 0 ? 'No templates found' : 'Template-Name'}
                  </InputLabel>
                  <Select
                    native
                    disabled={templateOptions.length === 0}
                    value={template ? template.id : ''}
                    onChange={this.onChangeTemplateSelect}
                    input={<OutlinedInput name="templateId" labelWidth={114} id="outlined-template-simple" />}
                  >
                    <option value="" />
                    {templateOptions}
                  </Select>
                </FormControl>
              </Fade>

              <Fade in={true} timeout={fadeTimeout}>
                <TextField
                  label={'Page'}
                  value={pageNumber}
                  name={'pageNumber'}
                  disabled={action !== PanelAction.CREATE}
                  type={'number'}
                  onChange={this.onChange}
                  onPaste={this.onPaste}
                  variant="outlined"
                  className={classes.margin}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                      disabled: classes.disabled,
                    },
                    inputProps: {
                      min: 1,
                      max: 9,
                    },
                  }}
                />
              </Fade>
            </React.Fragment>
          )}
        </Grid>

        <Hidden smDown>
          <Grid item xs={6}>
            {}
          </Grid>
        </Hidden>

        <FormPanelButtons
          cancelHandler={this.onCancel}
          resetHandler={this.resetState}
          saveHandler={this.onSave}
          deleteHandler={this.onDelete}
          isResetDisabled={!changed}
          isSaveDisabled={!changed || !allFilled}
          isDeleteDisabled={action !== PanelAction.EDIT}
        />
      </Grid>
    );
  }

  protected updateState = (
    link: Pick<LinkView, 'labelId' | 'technology' | 'pageNumber'> & Partial<Pick<LinkView, 'template'>> = {
      labelId: '',
      template: undefined,
      technology: '',
      pageNumber: 1,
    },
    compartment: Pick<Compartment, 'coordinate'> = {
      coordinate: '',
    },
    fetch: boolean = true,
  ): void => {
    const { labelId, template, technology, pageNumber } = link;
    const { coordinate } = compartment;

    this.setState(
      {
        action: labelId !== '' ? PanelAction.EDIT : PanelAction.CREATE,
        allFilled: labelId !== '',
        changed: false,
        renderInputElements: true,
        coordinate,
        labelId,
        template,
        technology,
        pageNumber,
        loading: fetch,
      },
      async () => {
        if (!fetch) {
          return;
        }

        const [userCompartmentTemplates, allTechnologies] = await Promise.all([
          this.fetchTemplates(coordinate),
          this.fetchAllTechnologies(),
        ]);

        this.setState({
          allTemplates: userCompartmentTemplates,
          allTechnologies,
          loading: false,
        });
      },
    );
  };
}

const SnackbarWrapped = withSnackbar<LinkPanelProps>(LinkPanelComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const LinkPanel = StyleWrapped;
