import { Permission, Role } from '@ekkogmbh/apisdk';
import { Checkbox, FormControl, Grid, MenuItem, Select, WithStyles } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import { SelectProps } from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import React, { ChangeEvent } from 'react';
import { ConfirmationDialog } from '../../Common/Components/ConfirmationDialog';
import { isArrayValue } from '../../Common/Helper/FormHelper';
import { RoleManagementStyles } from '../Styles/RoleManagementStyles';

interface RolePermissionSelectProps extends WithStyles<typeof RoleManagementStyles> {
  role: Role | Omit<Role, 'id' | 'name'>;
  allPermissions: Permission[];
  saveHandler: (role: Role, permissions: Permission[]) => void;
  input?: JSX.Element;
  inputLabel?: JSX.Element;
  noDialog?: boolean;
}

class RolePermissionSelectComponent extends React.PureComponent<RolePermissionSelectProps> {
  public state: {
    permissions: Permission[];
    open: boolean;
    dialog: boolean;
  };
  private sortedAllPermissions: Permission[] = [];

  constructor(props: RolePermissionSelectProps) {
    super(props);

    const { permissions } = props.role;

    this.state = {
      permissions: [...permissions],
      open: false,
      dialog: false,
    };

    this.sortAllPermissions();
  }

  // @TODO deprecated, maybe refactor to getDerivedStateFromProps
  // eslint-disable-next-line react/no-deprecated
  public componentWillReceiveProps(nextProps: Readonly<RolePermissionSelectProps>): void {
    const { role } = nextProps;
    const { permissions } = role;

    const currentRole = this.props.role;

    const nextRole = JSON.stringify(role);
    const currRole = JSON.stringify(currentRole);

    this.setState(
      {
        permissions: [...permissions],
        dialog: false,
      },
      () => {
        if (nextRole !== currRole) {
          this.sortAllPermissions(nextProps.allPermissions);
        }
      },
    );
  }

  public sortAllPermissions = (allPermissions?: Permission[]) => {
    const permissions = allPermissions ? allPermissions : this.props.allPermissions;

    this.sortedAllPermissions = [...permissions].sort(this.allPermissionsSorter);
  };

  public permissionNamesMapper = (permission: Permission) => permission.name;

  public onDialogDismiss = () => {
    const { permissions } = this.props.role;

    this.setState({
      permissions,
      dialog: false,
    });
  };

  public isPersistedRole = (role: Role | Omit<Role, 'id' | 'name'>): role is Role =>
    (role as Role).id !== undefined && (role as Role).name !== undefined;

  public onDialogConfirm = () => {
    const { role, saveHandler } = this.props;
    const { permissions } = this.state;

    if (this.isPersistedRole(role)) {
      saveHandler(role, permissions);
    }

    this.setState({ dialog: false });
  };

  public handleClose = () => {
    const { noDialog } = this.props;

    if (noDialog) {
      const { role, saveHandler } = this.props;
      const { permissions } = this.state;

      if (this.didPermissionsChange()) {
        saveHandler(role as Role, permissions);
      }

      this.setState({
        open: false,
      });
    } else {
      this.setState({
        open: false,
        dialog: this.didPermissionsChange(),
      });
    }
  };

  public handleOpen = () => {
    this.setState({ open: true });
  };

  public handleChange = ({ target: { value } }: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { allPermissions } = this.props;
    const { permissionNamesMapper } = this;

    const permissionStringToObjectMapper = (permissionName: string) => {
      const permissionIndex = allPermissions.map(permissionNamesMapper).indexOf(permissionName);

      return {
        id: allPermissions[permissionIndex].id,
        name: allPermissions[permissionIndex].name,
      } as Permission;
    };

    const formValue = value as string | string[];

    this.setState({ permissions: isArrayValue(formValue) ? formValue.map(permissionStringToObjectMapper) : formValue });
  };

  public didPermissionsChange = (): boolean =>
    this.props.allPermissions.filter(({ name }: Permission) => this.didPermissionChangeByName(name)).length > 0;

  public didPermissionChangeByName = (name: string): boolean => {
    const previousPermissionNames = this.props.role.permissions.map(this.permissionNamesMapper);
    const currentPermissionNames = this.state.permissions.map(this.permissionNamesMapper);

    return (
      (previousPermissionNames.indexOf(name) === -1 && currentPermissionNames.indexOf(name) > -1) ||
      (previousPermissionNames.indexOf(name) > -1 && currentPermissionNames.indexOf(name) === -1)
    );
  };

  public renderPreviousPermissions = () => {
    const previousPermissionNames = this.props.role.permissions.map(this.permissionNamesMapper);

    return previousPermissionNames.map((name: string) => <div key={name}>{name}</div>);
  };

  public renderUpdatedPermissions = () => {
    const currentPermissionNames = this.state.permissions.map(this.permissionNamesMapper);

    return currentPermissionNames.map((name: string) => <div key={name}>{name}</div>);
  };

  public renderDialogText = () => {
    const privPermissions = this.renderPreviousPermissions();
    const currPermissions = this.renderUpdatedPermissions();

    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="button" gutterBottom>
            Before
          </Typography>
          {privPermissions}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="button" gutterBottom>
            After
          </Typography>
          {currPermissions}
        </Grid>
      </Grid>
    );
  };

  public allPermissionsSorter = (permissionLeft: Permission, permissionRight: Permission) => {
    const selectedPermissionNames = this.state.permissions.map(this.permissionNamesMapper);

    const isSelectedPermissionLeft = selectedPermissionNames.indexOf(permissionLeft.name) > -1;
    const isSelectedPermissionRight = selectedPermissionNames.indexOf(permissionRight.name) > -1;

    if (
      (isSelectedPermissionLeft && isSelectedPermissionRight) ||
      (!isSelectedPermissionLeft && !isSelectedPermissionRight)
    ) {
      return permissionLeft.name.localeCompare(permissionRight.name);
    }

    return isSelectedPermissionLeft ? -1 : 1;
  };

  public render() {
    const { classes, input, inputLabel, role } = this.props;

    const { dialog, open, permissions } = this.state;

    const { permissionNamesMapper } = this;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
        },
      },
    };

    const currPermissionNames = permissions.map(permissionNamesMapper);

    const selectedRenderValue = (value: SelectProps['value']) =>
      isArrayValue(value as string[]) ? (value as string[]).join(', ') : (value as string);

    const allPermissionsMapper = ({ name }: Permission) => (
      <MenuItem key={name} value={name}>
        <span className={classes.menuItemUpdated}>{this.didPermissionChangeByName(name) && '*'}</span>
        <Checkbox checked={currPermissionNames.indexOf(name) > -1} />
        <ListItemText primary={name} />
      </MenuItem>
    );

    const selectInput = input ? input : <Input disableUnderline={true} id="select-multiple-permission-checkbox" />;

    return (
      <React.Fragment>
        <ConfirmationDialog
          open={dialog}
          title={
            <React.Fragment>
              {'Update Permissions of Role: '}
              <span className={classes.dialogTitleSpan}>{(role as Role).name}</span>
            </React.Fragment>
          }
          text={this.renderDialogText()}
          onClose={this.onDialogDismiss}
          onConfirm={this.onDialogConfirm}
        />
        <FormControl
          style={{
            width: '100%',
            maxWidth: 800,
            margin: 8,
          }}
          variant="outlined"
        >
          {inputLabel}
          <Select
            multiple
            style={{ marginTop: 0 }}
            value={currPermissionNames}
            onChange={this.handleChange}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            open={open}
            input={selectInput}
            renderValue={selectedRenderValue}
            MenuProps={MenuProps}
          >
            {this.sortedAllPermissions.map(allPermissionsMapper)}
          </Select>
        </FormControl>
      </React.Fragment>
    );
  }
}

const StyleWrapped = withStyles(RoleManagementStyles)(RolePermissionSelectComponent);

export const RolePermissionSelect = StyleWrapped;
