import { Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ModulesBaseStyles } from '../../Common/Styles/ModulesBaseStyles';

export const YourProfileStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  ...ModulesBaseStyles(theme),
  root: {
    width: '100%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing() * 2,
    marginBottom: theme.spacing() * 4,
    paddingBottom: theme.spacing() * 4,
    position: 'relative',
  } as CSSProperties,
  profileText: {
    fontSize: 16,
  },
  title: {
    fontWeight: 700,
    fontSize: 19,
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: 18,
  },
  iconText: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    lineHeight: 'normal',
  },
  card: {
    maxWidth: 400,
    minWidth: 300,
  },
});
