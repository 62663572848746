import { Fade, FormControl, Grid, InputLabel, OutlinedInput, Select, WithStyles, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React from 'react';
import { ChangeEvent } from 'react';
import { CsvDropzoneStore } from 'src/Common/Stores/CsvDropzoneStore';
import { AreaSelect } from '../../Common/Components/AreaSelect';
import { spacer, spacerCustom } from '../../Common/Components/Forms/Spacer';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { ReactSelectMultiValue } from '../../Common/Components/ReactSelectMulti';
import { getOverrideRootNodesFromUserPermissions } from '../../Common/Helper/Nodes';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { OperationGroupStore } from '../Stores/OperationGroupStore';
import { CREATION_TYPE_COMPARTMENT_UPDATE, CREATION_TYPE_SWITCH_PAGE } from './OperationGroupPanel';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['api', 'operationGroupStore', 'csvDropzoneStore'];

interface OperationGroupFormStores {
  api: ApiStore;
  operationGroupStore: OperationGroupStore;
  csvDropzoneStore: CsvDropzoneStore;
}

interface OperationGroupFormState {
  loading: boolean;
}

interface OperationGroupFormProps extends WithStyles<typeof styles>, InjectedNotistackProps {
  hasAreasReadPermission?: boolean;
  updateSortableItems?: () => Promise<void>;
}

@inject(...stores)
@observer
class OperationGroupFormComponent extends React.Component<OperationGroupFormProps, OperationGroupFormState> {
  public state: OperationGroupFormState = {
    loading: false,
  };

  get stores(): OperationGroupFormStores {
    return this.props as OperationGroupFormProps & OperationGroupFormStores;
  }

  public handleChangeArea = async (nodeSelectValue: ReactSelectMultiValue[]) => {
    const { operationGroupStore } = this.stores;

    operationGroupStore.changed = true;
    operationGroupStore.setState(
      {
        nodeSelectValue,
      },
      true,
    );
  };

  public handleChange = (fieldName: string) => async ({
    target: { value },
  }: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { operationGroupStore, csvDropzoneStore } = this.stores;

    const formValue = value as string;

    let { description, name, nodeValue, creationType } = operationGroupStore.state;

    switch (fieldName) {
      case 'description':
        description = formValue;
        break;

      case 'name':
        name = formValue;
        break;

      case 'type':
        if (formValue === CREATION_TYPE_COMPARTMENT_UPDATE) {
          creationType = CREATION_TYPE_COMPARTMENT_UPDATE;
        } else {
          creationType = CREATION_TYPE_SWITCH_PAGE;
        }
        csvDropzoneStore.reset();
        break;

      case 'node':
        nodeValue = formValue;
        break;
    }

    operationGroupStore.changed = true;
    operationGroupStore.setState(
      {
        description,
        name,
        nodeValue,
        creationType,
        type: creationType,
      },
      true,
    );
  };

  public render() {
    const { api, operationGroupStore } = this.stores;
    const { classes, hasAreasReadPermission } = this.props;
    const { loading } = this.state;
    const { description, name, nodeSelectValue, nodeValue, creationType } = operationGroupStore.state;

    const inputLabelProps = {
      classes: {
        root: classes.label,
        focused: classes.focused,
      },
    };

    const inputProps = {
      classes: {
        root: classes.outlinedInput,
        focused: classes.focused,
        notchedOutline: classes.notchedOutline,
        disabled: classes.disabled,
      },
    };

    const allOperationGroupsReadUserPermissions = api.getUserPermissionsByPermissionName(
      Permissions.OPERATION_GROUPS_READ,
    );
    const overrideRootNodes = getOverrideRootNodesFromUserPermissions(allOperationGroupsReadUserPermissions);

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {loading && <LoadingMask />}

        <Grid item xl={4} lg={6} md={8} xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <TextField
              label={'Name'}
              value={name}
              name={'name'}
              onChange={this.handleChange('name')}
              variant="outlined"
              className={classes.margin}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Fade>
        </Grid>

        {spacerCustom(
          {
            smDown: true,
          },
          {
            xl: 8,
            lg: 6,
            md: 4,
          },
        )}

        <Grid item xl={4} lg={6} md={8} xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <TextField
              label={'Description'}
              value={description}
              name={'description'}
              onChange={this.handleChange('description')}
              variant="outlined"
              className={classes.margin}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Fade>
        </Grid>

        {spacerCustom(
          {
            smDown: true,
          },
          {
            xl: 8,
            lg: 6,
            md: 4,
          },
        )}

        {hasAreasReadPermission && (
          <Grid item xl={4} lg={6} md={8} xs={12}>
            <Fade in={true} timeout={fadeTimeout}>
              <FormControl variant="outlined" className={classes.margin}>
                <AreaSelect
                  handleValueChange={this.handleChangeArea}
                  value={nodeSelectValue}
                  overrideRootNodes={overrideRootNodes}
                />
              </FormControl>
            </Fade>
          </Grid>
        )}

        {!hasAreasReadPermission && (
          <Grid item xl={4} lg={6} md={8} xs={12}>
            <Fade in={true} timeout={fadeTimeout}>
              <TextField
                label={'Area'}
                value={nodeValue}
                name={'area'}
                onChange={this.handleChange('node')}
                variant="outlined"
                className={classes.margin}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
              />
            </Fade>
          </Grid>
        )}

        {/*<Grid item xl={4} lg={6} md={8} xs={12}>*/}
        {/*  <Fade in={true} timeout={fadeTimeout}>*/}
        {/*    <FormControl variant="outlined" className={classes.margin}>*/}
        {/*      <AreaSelect*/}
        {/*        handleValueChange={this.handleChangeArea}*/}
        {/*        value={nodeSelectValue}*/}
        {/*      />*/}
        {/*    </FormControl>*/}
        {/*  </Fade>*/}
        {/*</Grid>*/}

        {spacerCustom(
          {
            smDown: true,
          },
          {
            xl: 8,
            lg: 6,
            md: 4,
          },
        )}

        {spacer(12, { style: { height: 52 } })}

        <Grid item xl={4} lg={6} md={8} xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <FormControl variant="outlined" className={classes.margin}>
              <InputLabel htmlFor="outlined-Type-native-simple">Type</InputLabel>
              <Select
                native
                value={creationType}
                onChange={this.handleChange('type')}
                input={<OutlinedInput name="Type" labelWidth={34} id="outlined-Type-native-simple" />}
              >
                <option key={0} value={CREATION_TYPE_COMPARTMENT_UPDATE}>
                  Compartment-Update
                </option>
                <option key={0} value={CREATION_TYPE_SWITCH_PAGE}>
                  Switch-Page
                </option>
              </Select>
            </FormControl>
          </Fade>
        </Grid>
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<OperationGroupFormProps>(OperationGroupFormComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const OperationGroupForm = StyleWrapped;
