import { Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const FormStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  root: {
    alignItems: 'stretch',
  },
  button: {
    flexGrow: 1,
    margin: theme.spacing(),
  },
  buttonGrid: {
    position: 'absolute',
    right: 24,
    marginTop: -36,
  },
  margin: {
    margin: theme.spacing(),
    width: '100%',
  },
  label: {
    '&$focused': {
      color: theme.palette.secondary.main,
    },
  },
  focused: {},
  outlinedInput: {
    width: '100%',
    '&$focused $notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
  },
  outlinedInputError: {
    width: '100%',
    '&$focused $notchedOutline': {
      borderColor: theme.palette.error.main,
    },
  },
  notchedOutline: {},
  instructions: {
    margin: theme.spacing(),
  },
  disabled: {
    color: theme.palette.grey['500'],
  },
  formPanelButtonsGrid: {
    marginTop: 24,
    display: 'inline-flex',
  },
  formPanelButtonsDelete: {
    marginLeft: 160,
    marginRight: -8,
  },
  formControlSelectAutoWidth: {
    margin: theme.spacing(),
    width: '100%',
  },
  AccordionSummaryContent: {
    margin: '16px 0 8px 0 !important',
  },
  AccordionExpandIcon: {
    marginTop: 4,
  },
  adornedStart: {
    whiteSpace: 'nowrap',
  },
});
