import {
  SortedSide,
  SortedSideCreatePayload,
  SortedSideSortingStrategy,
  SortedSideUpdatePayload,
  SpatialEntity,
  UserPermission,
  Template,
  EslManagerPrivateRoute,
  HttpMethod,
} from '@ekkogmbh/apisdk';
import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React from 'react';
import { FormPanelButtons } from '../../Common/Components/FormPanelButtons';
import { request } from '../../Common/Helper/FetchHandler';
import { NodeSeparator } from '../../Common/Helper/Nodes';
import { CancelableFetchPromises } from '../../Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { PickingSideStore, SortableSpatialEntity } from '../Stores/PickingSideStore';
import { SortedSidePanelForm } from './SortedSidePanelForm';
import { SortedSidePanelSorting } from './SortedSidePanelSorting';

const stores = ['api', 'pickingSideStore'];

interface SortedSidePanelStores {
  api: ApiStore;
  pickingSideStore: PickingSideStore;
}

export interface SortedSidePanelState {
  loading: boolean;
  availableTemplates: Template[];
}

export interface SortedSidePanelProps extends InjectedNotistackProps {
  sortedSide?: SortedSide;
  closeHandler: () => void;
  saveHandler: (sortedSide: SortedSideCreatePayload) => Promise<SortedSide>;
  updateHandler: (sortedSide: SortedSideUpdatePayload) => Promise<SortedSide>;
  fetchSortableItems: (
    nodeValue: string,
    key: string,
    templates: Array<Pick<Template, 'id'>>,
    sortingStrategy?: string,
  ) => Promise<SpatialEntity[]>;
  fetchSortableItemsBySortedSide: (sortedSide: SortedSide | Pick<SortedSide, 'id'>) => Promise<SpatialEntity[]>;
  closeCallback?: () => void;
}

@inject(...stores)
@observer
class SortedSidePanelComponent extends React.Component<SortedSidePanelProps, SortedSidePanelState> {
  public state: SortedSidePanelState = {
    loading: false,
    availableTemplates: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): SortedSidePanelStores {
    return this.props as SortedSidePanelProps & SortedSidePanelStores;
  }

  public fetchAvailableTemplates = async (): Promise<Template[]> => {
    const { api } = this.stores;
    const { enqueueSnackbar } = this.props;

    try {
      return await request<Template[]>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.getAllTemplates(),
        EslManagerPrivateRoute.TEMPLATES,
        HttpMethod.GET,
      );
    } catch (e) {
      enqueueSnackbar('Could not fetch Templates.', { variant: 'error' });
    }

    return undefined as never;
  };

  public static createSortableItems = (spatialEntities: SpatialEntity[], key: string): SortableSpatialEntity[] =>
    spatialEntities.map((value: SpatialEntity) => ({
      id: value.id,
      identifierValue: value.coordinate,
      keyValue: value.fields[key],
      key,
    }));

  public static isEditableSortedSideChanged = (
    sortedSide: SortedSide,
    key: string,
    templates: Template[],
    sortingStrategy: SortedSideSortingStrategy,
  ): boolean =>
    !(
      String(sortedSide.key) === String(key) &&
      String(sortedSide.sortingStrategy) === String(sortingStrategy) &&
      JSON.stringify(sortedSide.templates.sort((a, b) => b.id - a.id)) ===
        JSON.stringify(templates.sort((a, b) => b.id - a.id))
    );

  public async componentDidMount(): Promise<void> {
    this.setState(
      {
        loading: true,
        availableTemplates: await this.fetchAvailableTemplates(),
      },
      async () => {
        this.setState({ loading: false });
      },
    );
    await this.refreshStores();
  }

  public async componentDidUpdate(prevProps: Readonly<SortedSidePanelProps>): Promise<void> {
    await this.refreshStores(prevProps);
  }

  public handleReset = async () => {
    const { pickingSideStore } = this.stores;
    pickingSideStore.resetState();
    await this.updateSortableItems();
  };

  public refreshStores = async (prevProps?: Readonly<SortedSidePanelProps>): Promise<void> => {
    const { pickingSideStore } = this.stores;

    const isInitialSortedSide: boolean = this.props.sortedSide !== undefined && prevProps === undefined;

    const isSortedSideChanged: boolean =
      prevProps !== undefined &&
      prevProps.sortedSide !== undefined &&
      this.props.sortedSide !== undefined &&
      prevProps.sortedSide !== this.props.sortedSide;

    const isNewSortedSide: boolean =
      prevProps !== undefined && prevProps.sortedSide === undefined && this.props.sortedSide !== undefined;

    const isSortedSideGone: boolean =
      prevProps !== undefined && prevProps.sortedSide !== undefined && this.props.sortedSide === undefined;

    if (isInitialSortedSide || isSortedSideChanged || isNewSortedSide) {
      const sortedSide = this.props.sortedSide as SortedSide;

      pickingSideStore.sortingStrategy = sortedSide.sortingStrategy;
      pickingSideStore.editableSortedSide = sortedSide;
      pickingSideStore.resetState();
      await this.updateSortableItems();
    } else if (isSortedSideGone) {
      pickingSideStore.resetStore();
    }
  };

  public updateSortableItems = async (): Promise<void> => {
    const { pickingSideStore } = this.stores;
    const { editableSortedSide, sortingStrategy } = pickingSideStore;
    const { nodeValue, nodeSelectValue, key, templates } = pickingSideStore.state;

    let items;
    let promise;

    const requestableSortingStrategy = sortingStrategy === 'manually' ? 'alphanumeric-identifier' : sortingStrategy;

    const stopLoading = () => {
      pickingSideStore.loading = false;
    };

    switch (true) {
      case editableSortedSide !== undefined &&
        SortedSidePanelComponent.isEditableSortedSideChanged(editableSortedSide, key, templates, sortingStrategy):
        pickingSideStore.loading = true;
        promise = this.props.fetchSortableItems(
          editableSortedSide!.node.parts.join(NodeSeparator),
          key,
          templates,
          requestableSortingStrategy,
        );

        promise.then(stopLoading).catch(stopLoading);

        items = await promise;
        break;

      case editableSortedSide !== undefined:
        pickingSideStore.loading = true;
        promise = this.props.fetchSortableItemsBySortedSide(editableSortedSide as SortedSide);

        promise.then(stopLoading).catch(stopLoading);

        items = await promise;
        break;

      case (nodeValue === '' && nodeSelectValue.length === 0) || templates.length === 0 || key === '':
        return;

      default:
        pickingSideStore.loading = true;

        const nodeParts =
          nodeValue !== ''
            ? nodeValue.split(/[/_.]/)
            : (nodeSelectValue[nodeSelectValue.length - 1].fullValue as string[]);

        const node = nodeParts.map((part: string) => part.trim()).join(NodeSeparator);

        promise = this.props.fetchSortableItems(node, key, templates, requestableSortingStrategy);

        promise.then(stopLoading).catch(stopLoading);

        items = await promise;
    }

    pickingSideStore.loading = false;
    pickingSideStore.sortableItems = SortedSidePanelComponent.createSortableItems(items, key);
  };

  public handleSave = async () => {
    const { pickingSideStore } = this.stores;
    const { saveHandler, updateHandler } = this.props;
    const { loading } = this.state;
    const { allFilled, name, nodeSelectValue, nodeValue, key, templates } = pickingSideStore.state;

    const { editableSortedSide, sortableItems, sortingStrategy } = pickingSideStore;

    if (!allFilled || loading) {
      return;
    }

    this.setState({ loading: true });

    const sortableItemsMapper = (item: SortableSpatialEntity) => String(item.id);
    const manuallySortedSpatialEntityIds =
      sortingStrategy === 'manually' ? sortableItems.map(sortableItemsMapper) : null;
    const templateIds = templates.map((t) => t.id);

    if (editableSortedSide === undefined) {
      const node: string =
        nodeValue !== ''
          ? nodeValue
          : (nodeSelectValue[nodeSelectValue.length - 1].fullValue as string[]).join(NodeSeparator);
      const sortedSide: SortedSideCreatePayload = {
        name: name === '' ? undefined : name,
        node,
        key,
        templates: templateIds,
        sortingStrategy,
        manuallySortedSpatialEntityIds,
      };

      await saveHandler(sortedSide);
    } else {
      const sortedSide: SortedSideUpdatePayload = {
        id: editableSortedSide.id,
        name,
        key,
        templates: templateIds,
        sortingStrategy,
        manuallySortedSpatialEntityIds,
      };

      await updateHandler(sortedSide);
    }

    this.setState({ loading: false });
    this.closePanel();
  };

  public closePanel = () => {
    const { closeHandler, closeCallback } = this.props;

    closeHandler();
    if (closeCallback !== undefined) {
      closeCallback();
    }
  };

  public render() {
    const { api, pickingSideStore } = this.stores;
    const { changed, loading } = pickingSideStore;
    const { allFilled } = pickingSideStore.state;
    const { availableTemplates } = this.state;

    const pickingAreasReadPermissions = api.getUserPermissionsByPermissionName(Permissions.PICKING_AREAS_READ);

    const hasAllMatching = pickingAreasReadPermissions.reduce(
      (granted: boolean | null, currPermission: UserPermission) => {
        if (granted === false) {
          return false;
        }

        return api.userHasPermissionForNode(
          Permissions.AREAS_READ,
          currPermission.nodeDefinition.nodeValuesDefinition.values,
        );
      },
      null,
    );

    const hasAreasReadPermission = hasAllMatching === true;

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item lg={6} xs={12}>
          <SortedSidePanelForm
            hasAreasReadPermission={hasAreasReadPermission}
            availableTemplates={availableTemplates}
            updateSortableItems={this.updateSortableItems}
          />
        </Grid>

        <Grid item lg={6} xs={12}>
          <SortedSidePanelSorting loading={loading} sortableItems={pickingSideStore.sortableItems} />
        </Grid>

        <FormPanelButtons
          cancelHandler={this.closePanel}
          resetHandler={this.handleReset}
          saveHandler={this.handleSave}
          deleteHandler={undefined}
          isResetDisabled={!changed}
          isSaveDisabled={!changed || !allFilled}
          isDeleteDisabled={true}
          isDeleteHidden={true}
        />
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<SortedSidePanelProps>(SortedSidePanelComponent);

export const SortedSidePanel = SnackbarWrapped;
