import { Omit, Theme, withStyles, WithStyles } from '@material-ui/core';
import { inject } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React from 'react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { Routes } from '../../Routes';

const styles = (_: Theme) => ({
  root: {},
});

interface NoPermissionsContentStores {
  api: ApiStore;
}

const stores = ['api'];

interface NoPermissionsContentProps extends WithStyles<typeof styles>, RouteComponentProps, InjectedNotistackProps {}

@inject(...stores)
class NoPermissionsContentComponent extends Component<NoPermissionsContentProps> {
  get stores(): NoPermissionsContentStores {
    return this.props as NoPermissionsContentProps & NoPermissionsContentStores;
  }

  public componentDidMount(): void {
    const { api } = this.stores;

    const readableRoutePaths = api.getUserReadPermissionRoutePathsFromMenuLink(Routes);

    if (readableRoutePaths.length > 0) {
      this.props.history.push(readableRoutePaths[0]);
    }
  }

  public render() {
    return <div>{/**/}</div>;
  }
}

const RouterWrapped = withRouter<NoPermissionsContentProps, typeof NoPermissionsContentComponent>(
  NoPermissionsContentComponent,
);
const SnackbarWrapped = withSnackbar<Omit<NoPermissionsContentProps, keyof RouteComponentProps>>(RouterWrapped);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const NoPermissionsContent = StyleWrapped;
