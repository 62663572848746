import { ApiSdkEvents, Endpoint, EslManagerPrivateRoute, HttpMethod, Node, PaginationResponse } from '@ekkogmbh/apisdk';
import { Accordion, AccordionDetails, Grid, Omit, Paper, WithStyles, withStyles } from '@material-ui/core';
import * as classNames from 'classnames';
import { MaterialDatatableColumnDef } from 'material-datatable';
import { inject } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ConfirmationDialog } from '../../Common/Components/ConfirmationDialog';
import { ContentActions } from '../../Common/Components/ContentActions';
import { DataTable, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { request } from '../../Common/Helper/FetchHandler';
import { NodeSeparator } from '../../Common/Helper/Nodes';
import { injectFakePagination } from '../../Common/Helper/Pagination';
import { CancelableFetchPromises, makePromiseCancelable, noop, noopAsync } from '../../Common/Helper/PromiseHelper';
import { SuccessHandlerStatusMessages } from '../../Common/Helper/ResponseHandler';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { PaginationStore } from '../../Common/Stores/PaginationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { AreaManagementStyles } from '../Styles/AreaManagementStyles';
import { materialDatatableColumnDefinitions } from './AreaDatatableColumnDefinitions';
import { AreaPanel } from './AreaPanel';

export const nodeSeparator = ' ' + NodeSeparator + ' ';

enum ExpandedPanel {
  ADD = 'add',
  NONE = '',
}

enum NodeEndpoint {
  NODE = 'node',
  NODES = 'nodes',
  NODECHILDREN = 'nodechildren',
}

const promiseKeys = {
  ...NodeEndpoint,
  general: 'general',
  rootNodes: 'rootNodes',
};

export interface AreaManagementContentActionHandlers {
  users: (area: Node) => void;
  delete: (area: Node) => void;
}

export interface AreaManagementContentState {
  loading: boolean;
  rootNodes: Node[];
  id?: string;
  editableNode?: Node | Partial<Node> | null;
  expandedPanel: string;
  currentChildnodes: Node[];
  deleteDialogOpen: boolean;
  deleteableNode?: Node;
}

interface AreaManagementContentStores {
  api: ApiStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

const stores = ['api', 'paginationStore', 'searchContentStore', 'navigationStore'];

interface AreaManagementContentParams {
  id?: string;
}

interface AreaManagementContentProps
  extends WithStyles<typeof AreaManagementStyles>,
    RouteComponentProps<AreaManagementContentParams>,
    InjectedNotistackProps {}

export type AreaManagementContentPropsWithStores = AreaManagementContentProps & AreaManagementContentStores;

@inject(...stores)
class AreaManagementContentComponent extends Component<AreaManagementContentProps, AreaManagementContentState> {
  private currentNode: Node | null = null;
  private readonly filterFields: string[];
  private readonly sortFieldMap: DataTableSortFieldMap<Node>;

  // private readonly actions: AreaManagementContentActions = {};
  private fetchPromises: CancelableFetchPromises = {};
  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    200: 'Area exists.',
    201: 'Area created.',
    204: 'Area deleted.',
  };

  constructor(props: AreaManagementContentProps) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      loading: false,
      rootNodes: [],
      id,
      editableNode: undefined,
      expandedPanel: ExpandedPanel.NONE,
      currentChildnodes: [],
      deleteDialogOpen: false,
    };

    this.filterFields = ['value'];
    this.sortFieldMap = { value: 'value' };
  }

  get stores(): AreaManagementContentStores {
    return this.props as AreaManagementContentProps & AreaManagementContentStores;
  }

  public componentDidMount(): void {
    const id = this.props.match.params.id;

    this.setCurrentNodeId(id);
  }

  // @TODO deprecated, maybe refactor to getDerivedStateFromProps
  // eslint-disable-next-line react/no-deprecated,@typescript-eslint/no-explicit-any
  public componentWillReceiveProps(nextProps: Readonly<AreaManagementContentProps>, _: any): void {
    const { match } = this.props;

    if (match.params.id !== nextProps.match.params.id) {
      const id = nextProps.match.params.id;

      this.setCurrentNodeId(id);
    }
  }

  public setCurrentNodeId(nodeId: string | undefined): void {
    const { searchContentStore } = this.stores;
    const { id } = this.state;

    const stateCallback = () => {
      searchContentStore.emitRefresh();
    };

    if (nodeId !== id) {
      this.setState({ id: nodeId }, stateCallback);
    }
  }

  public componentWillUnmount(): void {
    this.cancelFetchPromises();
  }

  public handleError = (status: number, response: Response, json: { message: string }): void => {
    if (status !== 409 && status > 400 && status <= 500) {
      const { enqueueSnackbar } = this.props;
      enqueueSnackbar(response.statusText + ': ' + json.message, { variant: 'error' });
    }

    if (status === 409) {
      const { enqueueSnackbar } = this.props;
      enqueueSnackbar('Conflict: Can not delete area with linked objects.', { variant: 'error' });
    }
  };

  public handleSuccess = ({ status }: Response): void => {
    if (status === 201) {
      const { enqueueSnackbar } = this.props;
      enqueueSnackbar('Area created.');
    }

    if (status === 200) {
      const { enqueueSnackbar } = this.props;
      enqueueSnackbar('Area already exists.');
    }

    if (status === 204) {
      const { enqueueSnackbar } = this.props;
      enqueueSnackbar('Area deleted.');
    }
  };

  public fetch = async (endpoint: NodeEndpoint, id?: number): Promise<Node | PaginationResponse<Node>> => {
    switch (endpoint) {
      case NodeEndpoint.NODE:
        if (id === undefined) {
          throw new Error('no id given.');
        }

        return await this.fetchNode(id);

      case NodeEndpoint.NODECHILDREN:
        if (id === undefined) {
          throw new Error('no id given.');
        }

        return await this.fetchNodeChildren(id);

      case NodeEndpoint.NODES:
      default:
        return await this.fetchNodes();
    }
  };

  public fetchNode = async (id: number): Promise<Node> => {
    const { api } = this.stores;

    const endpoint: Endpoint = {
      path: EslManagerPrivateRoute.NODE,
      params: { id },
    };
    const endpointEvent = api.endpointEventType('request:error' as ApiSdkEvents, endpoint);

    api.once(endpointEvent, this.handleError);

    const promise = api.getNode(id);

    promise
      .then(() => {
        api.off(endpointEvent, this.handleError);
      })
      .catch(noop);

    return await promise;
  };

  public fetchNodes = async (): Promise<PaginationResponse<Node>> => {
    const { api } = this.stores;

    const endpoint: Endpoint = { path: EslManagerPrivateRoute.NODES };
    const endpointEvent = api.endpointEventType('request:error' as ApiSdkEvents, endpoint);

    api.once(endpointEvent, this.handleError);

    this.fetchPromises[promiseKeys.rootNodes] = makePromiseCancelable(api.getNodes());

    this.fetchPromises[promiseKeys.rootNodes].promise
      .then(() => {
        api.off(endpointEvent, this.handleError);
      })
      .catch(noop);

    const data = await this.fetchPromises[promiseKeys.rootNodes].promise;

    const rootNodeIds = data.map((node: Node) => node.id);
    const stateRootNodeIds = this.state.rootNodes.map((node: Node) => node.id);

    if (stateRootNodeIds !== rootNodeIds) {
      this.setState({ rootNodes: data });
    }

    return injectFakePagination<Node>(data);
  };

  public fetchNodeChildren = async (id: number): Promise<PaginationResponse<Node>> => {
    const { api } = this.stores;

    const promise = api.getNodeChildren(id);
    const data = await promise;

    this.setState({ currentChildnodes: data });

    return injectFakePagination<Node>(data);
  };

  public cancelFetchPromises = (): void => {
    Object.keys(this.fetchPromises).forEach((key: string) => {
      if (this.fetchPromises[key] && !this.fetchPromises[key].isResolved()) {
        this.fetchPromises[key].cancel();
      }
    });
  };

  public fetchItems = async (): Promise<Node | PaginationResponse<Node>> => {
    this.cancelFetchPromises();

    this.currentNode = null;

    const { id } = this.state;

    if (id !== undefined && id !== null) {
      const idInt = parseInt(id, 10);
      this.fetchPromises[promiseKeys.NODE] = makePromiseCancelable(this.fetch(NodeEndpoint.NODE, idInt));
      this.currentNode = await this.fetchPromises[promiseKeys.NODE].promise;

      const editableNode = this.currentNode;

      if (editableNode !== this.state.editableNode) {
        this.setState({ editableNode });
      }

      if (this.state.rootNodes.length === 0) {
        this.fetchPromises[promiseKeys.NODES] = makePromiseCancelable(this.fetch(NodeEndpoint.NODES));
        await this.fetchPromises[promiseKeys.NODES].promise;
      }

      this.fetchPromises[promiseKeys.general] = makePromiseCancelable(this.fetch(NodeEndpoint.NODECHILDREN, idInt));
    } else {
      this.fetchPromises[promiseKeys.general] = makePromiseCancelable(this.fetch(NodeEndpoint.NODES));
      this.setState({ editableNode: undefined });
    }

    this.fetchPromises[promiseKeys.general].promise.catch((reason) => {
      if (reason.isCanceled) {
        return;
      }

      throw reason;
    });

    const data = await this.fetchPromises[promiseKeys.general].promise;

    if (this.currentNode) {
      data.items.unshift(this.currentNode);
    }

    return data;
  };

  public actionHandlerUsers = async (node: Node) => {
    const { history } = this.props;

    history.push('/areas/' + node.id + '/users/');
  };

  public actionHandlerDeleteDialog = async (node: Node) => {
    this.setState({
      deleteDialogOpen: true,
      deleteableNode: node,
    });
  };

  public onAddItemClick = () => {
    const { expandedPanel } = this.state;

    this.setState({
      expandedPanel: expandedPanel === ExpandedPanel.ADD ? ExpandedPanel.NONE : ExpandedPanel.ADD,
    });
  };

  public closePanel = () => {
    const editableNode = null;
    const expandedPanel = ExpandedPanel.NONE;

    this.setState({
      editableNode,
      expandedPanel,
    });
  };

  public saveNodeHandler = async (value: string, parentNode: Node): Promise<Node> => {
    const { api, searchContentStore } = this.stores;
    const { enqueueSnackbar } = this.props;

    const node = await request<Node>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.addNodeChild(value, parentNode),
      EslManagerPrivateRoute.NODECHILDREN,
      HttpMethod.POST,
      this.successStatusCodes,
    );

    if (node) {
      searchContentStore.emitRefresh();
    }

    return node;
  };

  public deleteNodeHandler = async (node: Node): Promise<void> => {
    const { api, searchContentStore } = this.stores;
    const { enqueueSnackbar } = this.props;

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteNode(node),
      EslManagerPrivateRoute.NODE,
      HttpMethod.DELETE,
      this.successStatusCodes,
    );

    searchContentStore.emitRefresh();
  };

  public onDeleteOk = async () => {
    const { deleteableNode } = this.state;

    this.setState({
      deleteableNode: undefined,
      deleteDialogOpen: false,
    });

    if (deleteableNode && deleteableNode.id !== undefined) {
      await this.deleteNodeHandler(deleteableNode as Node);
    }
  };

  public onDeleteDismiss = () => {
    this.setState({
      deleteableNode: undefined,
      deleteDialogOpen: false,
    });
  };

  public render() {
    const { currentChildnodes, editableNode, expandedPanel, rootNodes, deleteDialogOpen, deleteableNode } = this.state;
    const { api } = this.stores;
    const { classes } = this.props;

    const hasWritePermission = api.userHasPermissionOnAnyNode(Permissions.AREAS_WRITE);

    const expansionPaperStyle =
      expandedPanel === ExpandedPanel.NONE
        ? {
            margin: 0,
            minHeight: 0,
            height: 0,
          }
        : {
            marginBottom: 48,
          };

    const isExistingNode = deleteableNode !== undefined && deleteableNode.id !== undefined;
    const deleteDialogText = isExistingNode ? `Delete Area ${deleteableNode!.value}?` : '';

    const columnDefinitions: MaterialDatatableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as AreaManagementContentPropsWithStores, {
        users: this.actionHandlerUsers,
        delete: this.actionHandlerDeleteDialog,
      }),
    );

    return (
      <Grid item xs={12}>
        {hasWritePermission && <ContentActions onClick={this.onAddItemClick} />}

        {deleteDialogOpen && isExistingNode && (
          <ConfirmationDialog
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={deleteDialogOpen && isExistingNode}
            title={'Delete Area'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}

        <Paper className={classes.root} style={expansionPaperStyle}>
          <Accordion
            expanded={expandedPanel === ExpandedPanel.ADD}
            className={classNames(classes.expansion, expandedPanel === ExpandedPanel.ADD && classes.expansionExpanded)}
          >
            <AccordionDetails>
              {expandedPanel === ExpandedPanel.ADD && (
                <AreaPanel
                  node={editableNode as Node}
                  userRootNodes={rootNodes}
                  childnodes={currentChildnodes}
                  closeHandler={this.closePanel}
                  saveHandler={this.saveNodeHandler}
                  deleteHandler={noopAsync}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Paper>

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            fetchItems={this.fetchItems as () => Promise<PaginationResponse<Node>>}
            columns={columnDefinitions}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            disableFooter={true}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<AreaManagementContentProps, typeof AreaManagementContentComponent>(
  AreaManagementContentComponent,
);
const SnackbarWrapped = withSnackbar<Omit<AreaManagementContentProps, keyof RouteComponentProps>>(RouterWrapped);
const StyleWrapped = withStyles(AreaManagementStyles)(SnackbarWrapped);

export const AreaManagementContent = StyleWrapped;
