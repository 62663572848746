export const createBlobDownload = (blob: Blob, filename: string): void => {
  // IE & Edge
  if (window.navigator.msSaveOrOpenBlob !== undefined) {
    // msSaveBlob only available for IE & Edge
    window.navigator.msSaveBlob(blob, filename);
  }
  // Chrome & FF
  else {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = url;
    a.download = filename;

    // we need to append the element to the dom -> otherwise it will not work in firefox
    document.body.appendChild(a);
    a.click();

    // afterwards we remove the element again
    a.remove();
  }
};
