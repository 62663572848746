import React from 'react';
import { Loading } from './Loading';

interface LoadingProps {
  width?: number;
  height?: number;
  topPercent?: number;
  leftPercent?: number;
  isLoading?: boolean;
}

class LoadingMaskComponent extends React.Component<LoadingProps> {
  public render(): JSX.Element {
    const { isLoading } = this.props;
    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: 'rgba(255,255,255,0.6)',
          zIndex: 998,
          display: isLoading !== undefined && !isLoading ? 'none' : 'block',
        }}
      >
        <Loading {...this.props} />
      </div>
    );
  }
}

export const LoadingMask = LoadingMaskComponent;
