import { Theme } from '@material-ui/core';
import { Duration } from '@material-ui/core/styles/transitions';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const DrawerWidth = 280;

const toolbarIconLogoWidth = 56;
const toolbarIconLogoMarginLeft = 26;

const drawerPaperCloseDurationKey: keyof Duration = 'leavingScreen';
const iconLogoCloseDurationKey: keyof Duration = 'leavingScreen';

export const DrawerPaperStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  drawerButton: {
    position: 'fixed',
    bottom: theme.spacing() * -12,
    left: DrawerWidth - theme.spacing() * 8,
    marginLeft: 0,
    zIndex: 1250,
    transition: theme.transitions.create(['bottom', 'left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration[iconLogoCloseDurationKey],
      delay:
        theme.transitions.duration[drawerPaperCloseDurationKey] -
          theme.transitions.duration[iconLogoCloseDurationKey] || 0,
    }),
  },
  drawerButtonSticky: {
    bottom: theme.spacing() * 3,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DrawerWidth,
    color: theme.palette.primary.contrastText,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: theme.shadows[7],
    overflowX: 'hidden',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration[drawerPaperCloseDurationKey],
    }),
    width: theme.spacing() * 9, // 7
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing() * 9,
    },
  },
  drawerButtonDrawerClosed: {
    left: theme.spacing() * 9 - theme.spacing() * 8 + theme.spacing() / 2,
  },
  toolbarIconLogo: {
    position: 'absolute',
    width: toolbarIconLogoWidth,
    height: 20,
    marginLeft: toolbarIconLogoMarginLeft,
    left: 0,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration[iconLogoCloseDurationKey],
      delay:
        theme.transitions.duration[drawerPaperCloseDurationKey] -
          theme.transitions.duration[iconLogoCloseDurationKey] || 0,
    }),
  },
  toolbarIconLogoHidden: {
    left: (toolbarIconLogoWidth + toolbarIconLogoMarginLeft) * -1,
  },
  toolbarIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerTooltipTitle: {
    fontSize: 16,
    fontWeight: 700,
  },
});
