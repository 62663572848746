import { Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ModulesBaseStyles } from '../../Common/Styles/ModulesBaseStyles';

export const UserManagementStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  ...ModulesBaseStyles(theme),
  // module specific css here
  listItem: {
    paddingLeft: theme.spacing() / 2,
  },
});
