import { User, UserRoleNodeMapping } from '@ekkogmbh/apisdk';
import { IconButton, Input, InputAdornment, List, ListItem } from '@material-ui/core';
import { orange, red } from '@material-ui/core/colors';
import { Delete, Edit, SupervisedUserCircle, Update } from '@material-ui/icons';
import classNames from 'classnames';
import { MaterialDatatableColumnDef } from 'material-datatable';
import moment from 'moment';
import React, { Fragment } from 'react';
import { DatatableColumnDefinitionFn, TableMeta } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { TableBodyFiller } from '../../Common/Components/TableBodyFiller';
import { Permissions } from '../../Common/Stores/ApiStore';
import {
  UserManagementContentActionHandlers,
  UserManagementContentHelpers,
  UserManagementContentPropsWithStores,
  UserManagementContentState,
} from './UserManagementContent';

class UserDataTableActions extends DataTableActionsComponent<User> {}

const UserActions = (
  _: UserManagementContentState,
  propsWithStores: UserManagementContentPropsWithStores,
  actions: UserManagementContentActionHandlers,
): MaterialDatatableColumnDef => ({
  name: '',
  field: '',
  options: {
    width: 48 * 3,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (user: User, tableMeta: TableMeta): React.ReactNode => {
      const { api } = propsWithStores;

      const hasMappingsWritePermission = api.userHasPermissionOnAnyNode(Permissions.MAPPINGS_READ);

      const actionButtons: ActionButton<User>[] = [
        {
          title: 'Roles',
          onClick: actions.roles,
          icon: SupervisedUserCircle,
          disabled: !hasMappingsWritePermission,
        },
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return (
        <UserDataTableActions
          dataset={user}
          width={tableMeta.columnData.width}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

const UserBodyRenderUsername = (
  propsWithStores: UserManagementContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (value: User, tableMeta: TableMeta) => {
  if (!value[tableMeta.columnData.field]) {
    return;
  }

  const { classes } = propsWithStores;
  const actualValue = value[tableMeta.columnData.field];

  return (
    <Fragment>
      <span className={classes.boldFont}>{actualValue}</span>
    </Fragment>
  );
};

const UserUsername = (
  _: UserManagementContentState,
  propsWithStores: UserManagementContentPropsWithStores,
): MaterialDatatableColumnDef => ({
  name: 'Username',
  field: 'username',
  options: {
    width: 360,
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: UserBodyRenderUsername(propsWithStores),
  },
});

const UserEmail = (): MaterialDatatableColumnDef => ({
  name: 'E-Mail',
  field: 'email',
  options: {
    width: 360,
    sort: true,
    filter: false,
    headerNoWrap: true,
  },
});

const UserBodyRenderValidUntil = (
  helpers: UserManagementContentHelpers,
  // eslint-disable-next-line react/display-name
) => (user: User) => (
  <Input
    type={'text'}
    inputProps={{ style: { textAlign: 'center' } }}
    value={new Date(user.validUntil).toLocaleDateString()}
    readOnly={true}
    onClick={() => helpers.onClickValidUntil(user)}
    endAdornment={
      <InputAdornment position="end">
        <IconButton aria-label="Edit" onClick={() => helpers.onClickValidUntil(user)}>
          <Update fontSize={'small'} />
        </IconButton>
      </InputAdornment>
    }
  />
);

const UserValidUntil = (
  _: UserManagementContentState,
  __: UserManagementContentPropsWithStores,
  ___: UserManagementContentActionHandlers,
  helpers?: UserManagementContentHelpers,
): MaterialDatatableColumnDef => ({
  name: 'Valid-Until',
  field: 'validUntil',
  options: {
    width: 160,
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: UserBodyRenderValidUntil(helpers!),
  },
});

const UserBodyRenderExpiring = () =>
  // eslint-disable-next-line react/display-name
  ({ validUntil }: User) => {
    const momentDate = moment(validUntil);

    const hoursDiff = momentDate.diff(moment(), 'hours');

    let style = {};

    const saturation = 'A400';

    switch (true) {
      case hoursDiff <= 0:
        style = {
          color: red[saturation],
          fontWeight: 700,
        };
        break;

      case hoursDiff < 48:
        style = {
          color: orange[saturation],
          fontWeight: 700,
        };
        break;
    }

    return (
      <Fragment>
        <span style={style}>
          {hoursDiff <= 0 && 'expired '}
          {momentDate.fromNow()}
        </span>
      </Fragment>
    );
  };

const UserExpiring = (): MaterialDatatableColumnDef => ({
  name: 'Expiring',
  field: 'validUntil',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: UserBodyRenderExpiring(),
  },
});

const UserBodyRenderMappings = (
  propsWithStores: UserManagementContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (user: User) => {
  if (user.userRoleNodeMappings === undefined) {
    return;
  }

  const { userRoleNodeMappings } = user;
  const { classes } = propsWithStores;

  return (
    <List dense disablePadding>
      {userRoleNodeMappings.map((userRoleNodeMapping: UserRoleNodeMapping, index: number) => (
        <ListItem dense key={index} className={classNames(classes.boldFont, classes.listItem)}>
          {userRoleNodeMapping.role.name}
        </ListItem>
      ))}
    </List>
  );
};

const UserMappings = (
  state: UserManagementContentState,
  propsWithStores: UserManagementContentPropsWithStores,
): MaterialDatatableColumnDef => {
  if (state.nodeId !== undefined) {
    return {
      name: 'Roles',
      field: 'userRoleNodeMappings',
      options: {
        sort: false,
        filter: false,
        headerNoWrap: true,
        customBodyRender: UserBodyRenderMappings(propsWithStores),
      },
    };
  }

  return TableBodyFiller;
};

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  UserManagementContentPropsWithStores,
  UserManagementContentState,
  UserManagementContentActionHandlers,
  UserManagementContentHelpers
>> = [UserUsername, UserEmail, UserValidUntil, UserExpiring, UserMappings, UserActions];

export { UserUsername, UserEmail, UserValidUntil, UserExpiring, UserMappings };
