import { SortedSide, Template } from '@ekkogmbh/apisdk';
import { Delete, Edit, Highlight } from '@material-ui/icons';
import { MaterialDatatableColumnDef } from 'material-datatable';
import React from 'react';
import { DatatableColumnDefinitionFn, TableMeta } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { NodeSeparator } from '../../Common/Helper/Nodes';
import { Permissions } from '../../Common/Stores/ApiStore';
import {
  PickingSidesContentActionHandlers,
  PickingSidesContentPropsWithStores,
  PickingSidesContentState,
} from './PickingSidesContent';

class PickingAreaDataTableActions extends DataTableActionsComponent<SortedSide> {}

const SortedSideName = (): MaterialDatatableColumnDef => ({
  name: 'Name',
  field: 'name',
  options: {
    width: 240,
    sort: true,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: SortedSide): React.ReactNode => <div style={{ fontWeight: 700 }}>{value.name}</div>,
  },
});

const SortedSideKey = (): MaterialDatatableColumnDef => ({
  name: 'Key',
  field: 'key',
  options: {
    width: 240,
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: SortedSide): React.ReactNode => value.key,
  },
});

const SortedSideTemplates = (): MaterialDatatableColumnDef => ({
  name: 'Templates',
  field: 'templates',
  options: {
    width: 240,
    sort: true,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: SortedSide): React.ReactNode => {
      const mapper = (template: Template, index: number): React.ReactNode => <li key={index}>{template.name}</li>;

      return <ul style={{ paddingInlineStart: 16 }}>{value.templates && value.templates.map(mapper)}</ul>;
    },
  },
});

const SortedSideNode = (): MaterialDatatableColumnDef => ({
  name: 'Area',
  field: 'node',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: SortedSide): React.ReactNode =>
      value.node ? value.node.parts.join(' ' + NodeSeparator + ' ') : null,
  },
});

const SortedSideSortingStrategy = (): MaterialDatatableColumnDef => ({
  name: 'Sorting-Strategy',
  field: 'sortingStrategy',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: SortedSide): React.ReactNode => value.sortingStrategy,
  },
});

const SortedSideActions = (
  _: PickingSidesContentState,
  propsWithStores: PickingSidesContentPropsWithStores,
  actions: PickingSidesContentActionHandlers,
): MaterialDatatableColumnDef => ({
  name: '',
  field: '',
  options: {
    width: 48 * 3,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (sortedSide: SortedSide, tableMeta: TableMeta): React.ReactNode => {
      const hasWritePermission = propsWithStores.api.userHasPermissionOnAnyNode(Permissions.PICKING_AREAS_WRITE);

      const actionButtons: ActionButton<SortedSide>[] = [
        {
          title: 'Blink',
          onClick: actions.blink,
          icon: Highlight,
          disabled: !hasWritePermission,
        },
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
          disabled: !hasWritePermission,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return (
        <PickingAreaDataTableActions
          dataset={sortedSide}
          width={tableMeta.columnData.width}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  PickingSidesContentPropsWithStores,
  PickingSidesContentState,
  PickingSidesContentActionHandlers
>> = [SortedSideName, SortedSideKey, SortedSideTemplates, SortedSideNode, SortedSideSortingStrategy, SortedSideActions];
