import { Label } from '@ekkogmbh/apisdk';
import { Chip, Typography } from '@material-ui/core';
import { green, orange, red } from '@material-ui/core/colors';
import { Highlight } from '@material-ui/icons';
import { MaterialDatatableColumnDef } from 'material-datatable';
import React, { CSSProperties } from 'react';
import Highlighter from 'react-highlight-words';
import { DatatableColumnDefinitionFn, TableMeta } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import {
  LabelManagementContentActionHandlers,
  LabelManagementContentPropsWithStores,
  LabelManagementContentState,
} from './LabelManagementContent';

class LabelDataTableActions extends DataTableActionsComponent<Label> {}

const LabelActions = (
  _: LabelManagementContentState,
  __: LabelManagementContentPropsWithStores,
  actions: LabelManagementContentActionHandlers,
): MaterialDatatableColumnDef => ({
  name: '',
  field: '',
  options: {
    width: 48 * 3,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (label: Label, tableMeta: TableMeta): React.ReactNode => {
      const actionButtons: ActionButton<Label>[] = [
        {
          title: 'Blink',
          onClick: actions.blink,
          icon: Highlight,
        },
      ];

      return (
        <LabelDataTableActions
          dataset={label}
          width={tableMeta.columnData.width}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

const LabelBodyRenderHighlighter = (
  propsWithStores: LabelManagementContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (value: Label, tableMeta: TableMeta) => {
  if (!value[tableMeta.columnData.field]) {
    return;
  }

  const { searchText } = propsWithStores.searchContentStore;
  const actualValue = value[tableMeta.columnData.field];
  const unhighlightStyle: CSSProperties = {};
  const highlightStyle: CSSProperties = {
    ...unhighlightStyle,
    backgroundColor: '#BBB',
  };

  return (
    <Highlighter
      searchWords={[searchText.replace(/\\/g, '\\\\')]}
      textToHighlight={actualValue}
      highlightStyle={highlightStyle}
      unhighlightStyle={unhighlightStyle}
    />
  );
};

const LabelId = (
  _: LabelManagementContentState,
  propsWithStores: LabelManagementContentPropsWithStores,
): MaterialDatatableColumnDef => ({
  name: 'Id',
  field: 'id',
  options: {
    headerNoWrap: true,
    customBodyRender: LabelBodyRenderHighlighter(propsWithStores),
  },
});

const LabelTechnology = (
  _: LabelManagementContentState,
  propsWithStores: LabelManagementContentPropsWithStores,
): MaterialDatatableColumnDef => ({
  name: 'Technology',
  field: 'technology',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: LabelBodyRenderHighlighter(propsWithStores),
  },
});

const renderIdentifier = (value: string, propsWithStores: LabelManagementContentPropsWithStores) => {
  const {
    classes,
    searchContentStore: { searchText },
  } = propsWithStores;

  const unhighlightStyle: CSSProperties = {};
  const highlightStyle: CSSProperties = {
    ...unhighlightStyle,
    backgroundColor: '#BBB',
  };

  return (
    <span style={{ position: 'relative' }}>
      <Typography variant={'overline'} align={'center'} color={'textPrimary'} className={classes.identifier}>
        <Highlighter
          searchWords={[searchText.replace(/\\/g, '\\\\')]}
          textToHighlight={value}
          highlightStyle={highlightStyle}
          unhighlightStyle={unhighlightStyle}
        />
      </Typography>
    </span>
  );
};

const LabelBodyRenderIdentifier = (
  propsWithStores: LabelManagementContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (value: Label, tableMeta: TableMeta) => {
  if (!value[tableMeta.columnData.field]) {
    return;
  }

  const actualValue = value[tableMeta.columnData.field];

  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflowY: 'hidden',
        overflowX: 'auto',
        width: tableMeta.columnData.width,
      }}
    >
      <Chip
        variant={'outlined'}
        label={renderIdentifier(actualValue, propsWithStores)}
        style={{
          position: 'relative',
          margin: 8,
          overflow: 'hidden',
        }}
      />
    </div>
  );
};

const LabelIdentifier = (
  _: LabelManagementContentState,
  propsWithStores: LabelManagementContentPropsWithStores,
): MaterialDatatableColumnDef => ({
  name: 'Coordinate',
  field: 'identifierValue',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    width: 380,
    customBodyRender: LabelBodyRenderIdentifier(propsWithStores),
  },
});

const saturation = 400;

const LabelBodyRenderStatus = () =>
  // eslint-disable-next-line react/display-name
  (value: Label, tableMeta: TableMeta) => {
    if (!value[tableMeta.columnData.field]) {
      return;
    }

    const actualValue = value[tableMeta.columnData.field];

    let backgroundColor: string;

    switch (actualValue) {
      case 'ONLINE':
        backgroundColor = green[saturation];
        break;

      case 'OFFLINE':
      case 'TIMEOUT':
      case 'UNASSIGNED':
        backgroundColor = red[saturation];
        break;

      default:
        backgroundColor = orange[saturation];
        break;
    }

    return (
      <div>
        <Chip
          label={actualValue}
          style={{
            backgroundColor,
            color: 'white',
          }}
        />
      </div>
    );
  };

const LabelStatus = (): MaterialDatatableColumnDef => ({
  name: 'Status',
  field: 'status',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: LabelBodyRenderStatus(),
  },
});

const LabelBodyRenderBatteryStatus = () =>
  // eslint-disable-next-line react/display-name
  (value: Label, tableMeta: TableMeta) => {
    if (!value[tableMeta.columnData.field]) {
      return;
    }

    const actualValue = value[tableMeta.columnData.field];

    let backgroundColor: string;

    switch (actualValue) {
      case 'GOOD':
        backgroundColor = green[saturation];
        break;

      case 'BAD':
        backgroundColor = red[saturation];
        break;

      default:
        backgroundColor = orange[saturation];
        break;
    }

    return (
      <div>
        <Chip
          label={actualValue}
          style={{
            backgroundColor,
            color: 'white',
          }}
        />
      </div>
    );
  };

const LabelBatteryStatus = (): MaterialDatatableColumnDef => ({
  name: 'Battery-Status',
  field: 'batteryStatus',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: LabelBodyRenderBatteryStatus(),
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  LabelManagementContentPropsWithStores,
  LabelManagementContentState,
  LabelManagementContentActionHandlers,
  null
>> = [LabelId, LabelIdentifier, LabelTechnology, LabelStatus, LabelBatteryStatus, LabelActions];

export { LabelId, LabelIdentifier, LabelTechnology, LabelStatus, LabelBatteryStatus };
